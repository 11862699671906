import * as React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@mui/material/Tooltip';

const Tooltip = (props) => {
	return <MuiTooltip title={props.title}>{props.children}</MuiTooltip>;
};

Tooltip.propTypes = {
	title: PropTypes.string,
	children: PropTypes.element,
};

export default Tooltip;
