import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Copyright } from './utils/extra';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';

export function Login({ title }) {
	const [loading, setLoading] = useState(false);
	const [btnColor, setBtnColor] = useState('black');
	const [errMsg, setErrMsg] = useState();
	const [err, setErr] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setState();
		const data = new FormData(event.currentTarget);
		try {
			await Auth.signIn(data.get('username'), data.get('password'));
			event.target.reset();
		} catch (error) {
			switch (error.code) {
				case 'UserNotFoundException':
					setErrMsg('找不到此帳號');
					break;
				case 'NotAuthorizedException':
					setErrMsg('帳號或密碼有錯誤');
					break;
				default:
					setErrMsg(error.message);
					break;
			}
			setErr(true);
		}
		setLoading(false);
		setBtnColor('black');
	};

	function setState() {
		setErr(false);
		setErrMsg();
		setLoading(true);
		setBtnColor('hsl(7, 88%, 48%)');
	}

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Box>
					<Avatar sx={{ m: 1, bgcolor: 'black' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography fontWeight='bold'>{title}</Typography>
				</Box>
				<Box component='form' onSubmit={handleSubmit} mt={1} noValidate>
					<TextField error={err} margin='normal' required fullWidth id='username' label='帳號' name='username' autoFocus />
					<TextField error={err} margin='normal' required fullWidth name='password' label='密碼' type='password' id='password' autoComplete='off' />
					<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, bgcolor: btnColor, '&:hover': { bgcolor: btnColor }, '&:press': { bgcolor: btnColor } }}>
						<Typography sx={{ color: 'white', display: !loading ? undefined : 'none' }}>登入</Typography>
						<CircularProgress size={24} sx={{ color: 'white', display: loading ? undefined : 'none' }} />
					</Button>
					<Typography color='error' fontSize={14} fontWeight={'bold'}>
						{errMsg}
					</Typography>
					{/* <Grid container>
						<Grid item xs>
							<Link href="#" variant="body2">
								忘記密碼？
							</Link>
						</Grid>
					</Grid> */}
				</Box>
				<Box>
					<Copyright mt={8} mb={4} />
				</Box>
			</Box>
		</Container>
	);
}
