import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export const filteredItems = (filterList, searchItem, dashboard) =>
	filterList.filter((item) => {
		let ignore = new RegExp(searchItem, 'i'); //ignore case-sensitive
		let ignoreDInfo = '';
		let DInfo = '';
		if (!dashboard) {
			ignoreDInfo = ignore.test(item.deliveryInfo.map((m) => m.uuid.substr(m.uuid.length - 5)));
			DInfo = item.deliveryInfo.map((d) => d.manifest_reference.split('_')[0]).some((i) => i.includes(searchItem));
		}
		return ignore.test(item.id) || ignoreDInfo || item.dropoff_name.includes(searchItem) || item.pickup_name.includes(searchItem) || item.id.split('-')[2].includes(searchItem) || item.dropoff_number.includes(searchItem) || DInfo;
	});

export const SearchBox = ({ searchItem, handleSearchChange, boxStyle }) => (
	<TextField
		variant='standard'
		sx={boxStyle}
		size='small'
		value={searchItem}
		onChange={handleSearchChange}
		placeholder={'搜尋單號、姓名、電話'}
		InputProps={{
			startAdornment: (
				<InputAdornment position='start'>
					<SearchIcon />
				</InputAdornment>
			),
		}}
	/>
);
