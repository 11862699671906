import Box from '@mui/material/Grid';
import Button from '@mui/material/Button';

export default function Linkbutton({ position, variant, color, icon, sx, link, text }) {
	return (
		<Box textAlign={position}>
			<Button
				variant={variant}
				color={color}
				startIcon={icon}
				sx={sx}
				onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}>
				{text}
			</Button>
		</Box>
	);
}
