import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import deliveryReducer from './deliverySlice';

const rootReducer = combineReducers({
	users: authReducer,
	shipping: deliveryReducer,
});

export default rootReducer;
