import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getStore } from '../utils/requestDelivery';
import { PaySwitch } from './util';
import { createDelivery } from '../utils/lambda';
import Dialog from '../components/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export default function Createorder({ storeid, createDialog, setCreateDialog, allList }) {
	const today = moment().format('YYYYMMDD');
	const dropoff = moment().format('YYYY-MM-DD HH:mm:ss');
	const millisec = moment().format('x');
	const [newOrder, setNewOrder] = useState({});
	const [initial, setInitial] = useState({});
	const [qtyDetail, setQtyDetail] = useState([
		{
			quantity: 1,
			name: '',
			price: 0,
		},
	]);
	const [copyOrder, setCopyOrder] = useState('');

	useEffect(() => {
		getStore(storeid).then((res) => {
			const detail = {
				store_id: storeid,
				order_no: `${today.substring(2)}Z${millisec.substring(8)}`, //test '240125Z00001'
				table_name: `${millisec.substring(11)}`,
				pickup_address: res[0].address,
				pickup_name: res[0].name,
				pickup_phone_number: res[0].phone,
				dropoff_name: '',
				dropoff_address: '',
				dropoff_deadline: dropoff,
				dropoff_phone_number: '',
				manifest_items: [],
				order_total: '',
				order_status: '已接單',
				dropoff_notes: '',
				pay_state: '未支付',
				order_from: '手動輸入',
			};
			setNewOrder(detail);
			setInitial(detail);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hanldeCreateDialog = () => {
		setCreateDialog(!createDialog);
		setQtyDetail([
			{
				quantity: 1,
				name: '',
				price: 0,
			},
		]);
		setNewOrder(initial);
	};

	const addItem = (action, index) => {
		let prev = [...qtyDetail];
		let name = prev.map((p) => p.name);
		if (action === 'add' && !name.includes('')) {
			prev.push({ quantity: 1, name: '', price: 0 });
		} else if (action === 'remove') {
			prev.splice(index, 1);
		}
		setQtyDetail(prev);
	};

	const editItem = (e, type, index) => {
		let prev = [...qtyDetail];
		let val = type === 'name' ? e.target.value : parseInt(e.target.value);
		prev[index][type] = val;
		setQtyDetail(prev);
	};

	const handleOrder = (e, key) => {
		setNewOrder((prev) => {
			let order = { ...prev };
			const val = e.target.value;
			order[key] = val;
			if (key === 'pay_state') {
				order[key] = order[key] === '未支付' ? '已支付' : '未支付';
			}
			return order;
		});
	};

	const handleSubmit = () => {
		let order = { ...newOrder };
		order.manifest_items = qtyDetail;
		order.order_total = parseInt(order.order_total);
		order.table_name = `★送${order.table_name}`;
		createDelivery(order);
		hanldeCreateDialog();
	};

	const copy = (e) => {
		const value = e === 'remove' ? '' : e.target.value;
		setCopyOrder(value);
		setNewOrder((prev) => {
			const remove = e === 'remove';
			const orderId = remove ? null : e.target.value;
			const order = remove ? null : allList.filter((i) => i.id === orderId)[0];
			let old = { ...prev };
			old.dropoff_name = remove ? '' : order.dropoff_name;
			old.dropoff_address = remove ? '' : order.dropoff_address;
			old.dropoff_phone_number = remove ? '' : order.dropoff_number;
			return old;
		});
	};

	const QtyContent = () =>
		qtyDetail && (
			<Box>
				<Grid container>
					{qtyDetail.map((i, index) => (
						<Grid item md={6} key={index + 'item'}>
							<Stack direction={'row'}>
								<TextField
									id={`qty+${i}`}
									type='number'
									variant='standard'
									sx={{ mb: 2, mr: 2 }}
									placeholder='數量'
									onChange={(e) => editItem(e, 'quantity', index)}
									value={i.quantity}
									InputProps={{
										endAdornment: <InputAdornment position='end'>杯/個</InputAdornment>,
									}}></TextField>
								<TextField
									fullWidth
									id={`name+${i}`}
									variant='standard'
									sx={{ pb: 2, pr: 1 }}
									placeholder='品項'
									onChange={(e) => editItem(e, 'name', index)}
									value={i.name}></TextField>
								{index === qtyDetail.length - 1 ? (
									<>
										<IconButton
											aria-label='remove'
											onClick={() => addItem('remove', index)}
											sx={{ mb: 2 }}
											color='error'
											disabled={qtyDetail.length === 1}>
											<CloseIcon fontSize='small' />
										</IconButton>
										<IconButton
											aria-label='add'
											sx={{ mb: 2 }}
											onClick={() => addItem('add', index)}
											color='success'
											disabled={i.name === '' || isNaN(i.quantity)}>
											<AddIcon fontSize='small' />
										</IconButton>
									</>
								) : (
									<IconButton aria-label='remove' onClick={() => addItem('remove', index)} sx={{ mb: 2 }} color='error'>
										<CloseIcon fontSize='small' />
									</IconButton>
								)}
							</Stack>
						</Grid>
					))}
				</Grid>
			</Box>
		);

	const CreateContent = () =>
		newOrder && (
			<Grid container>
				<Grid item xs={12}>
					<Typography fontWeight={'bold'} pb={2}>
						取貨地資訊*
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>訂購人姓名</Typography>
					<TextField
						fullWidth
						id={'pickup_name'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.pickup_name}
						onChange={(e) => handleOrder(e, 'pickup_name')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>訂購人電話</Typography>
					<TextField
						fullWidth
						id={'pickup_phone_number'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.pickup_phone_number}
						onChange={(e) => handleOrder(e, 'pickup_phone_number')}
						disabled
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color={'text.secondary'}>取貨地址</Typography>
					<TextField
						fullWidth
						id={'pickup_address'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.pickup_address}
						onChange={(e) => handleOrder(e, 'pickup_address')}
						disabled
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography fontWeight={'bold'}>目的地資訊*</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography fontSize={12}>複製資訊</Typography>
					<Stack pb={2} direction={'row'}>
						<FormControl fullWidth>
							<Select
								native={false}
								labelId='copy-order-address-label'
								id='copy-order-address'
								value={copyOrder}
								renderValue={() => (newOrder.dropoff_name === '' ? '' : copyOrder)}
								size='small'
								MenuProps={{ sx: { maxHeight: 250 } }}
								onChange={(e) => copy(e)}>
								{allList.map((all) => (
									<MenuItem value={all.id} sx={{ display: 'block' }}>
										<Typography variant='subtitle2'>{all.id}</Typography>
										<Typography
											variant='body2'
											color={'text.secondary'}>{`${all.dropoff_name}-${all.dropoff_address}`}</Typography>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton onClick={(e) => copy('remove')} color='error' disabled={copyOrder === ''}>
							<CloseIcon />
						</IconButton>
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>收件人姓名</Typography>
					<TextField
						fullWidth
						id={'dropoff_name'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.dropoff_name}
						onChange={(e) => handleOrder(e, 'dropoff_name')}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>收件人電話</Typography>
					<TextField
						fullWidth
						id={'dropoff_phone_number'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.dropoff_phone_number}
						onChange={(e) => handleOrder(e, 'dropoff_phone_number')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color={'text.secondary'}>收件人地址</Typography>
					<TextField
						fullWidth
						id={'dropoff_address'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.dropoff_address}
						onChange={(e) => handleOrder(e, 'dropoff_address')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography fontWeight={'bold'} pb={2}>
						訂單資訊*
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>單號</Typography>
					<TextField
						fullWidth
						id={'id'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						onChange={(e) => handleOrder(e, 'table_name')}
						InputProps={{
							startAdornment: <InputAdornment position='start'>{newOrder.order_no}</InputAdornment>,
						}}
						placeholder='單號'
						value={newOrder.table_name}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>總金額</Typography>
					<TextField
						fullWidth
						id={'total'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						type='number'
						value={newOrder.order_total}
						onChange={(e) => handleOrder(e, 'order_total')}
						InputProps={{
							startAdornment: <InputAdornment position='start'>$</InputAdornment>,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>備註(非必填)</Typography>
					<TextField
						fullWidth
						id={'dropoff_notes'}
						variant='standard'
						sx={{ pb: 2, pr: 2 }}
						value={newOrder.dropoff_notes}
						onChange={(e) => handleOrder(e, 'dropoff_notes')}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography color={'text.secondary'}>支付方式</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<PaySwitch
									checked={newOrder.pay_state === '已支付'}
									value={newOrder.pay_state === '未支付' ? '未支付' : '已支付'}
									onChange={(e) => handleOrder(e, 'pay_state')}
								/>
							}
							label={<Typography>{newOrder.pay_state === '未支付' ? '未支付' : '已支付'}</Typography>}
							color=''
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<Typography color={'text.secondary'}>品項及數量:</Typography>
					<QtyContent />
				</Grid>
			</Grid>
		);

	return (
		<Dialog
			dialog={createDialog}
			title={'建立新訂單' + newOrder.order_total}
			content={CreateContent()}
			cancel={'退出'}
			submit={'確定'}
			handleSubmit={handleSubmit}
			handleClose={hanldeCreateDialog}
			showBtn={true}
			disable={
				Object.keys(newOrder)
					.map((k) => k !== 'dropoff_notes' && newOrder[k] === '')
					.includes(true) ||
				qtyDetail.map((q) => q.name).includes('') ||
				qtyDetail.map((q) => isNaN(q.quantity)).includes(true)
			}
			fullWidth={false}
			showSub={true}
		/>
	);
}
