import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '@mui/material/Button';
import './Comp.css';

export default function Signature({ disable, signURL, handleSignatureDone }) {
	const signRef = useRef([]);
	signRef.current = [];
	const [signature, setSignature] = useState(null);

	const handleSignatureEnd = () => setSignature(signRef.current[0].toDataURL());

	const clearSignature = () => {
		if (signRef.current[0]) signRef.current[0].clear();
		setSignature(null);
		handleSignatureDone(null);
	};

	const addToRefs = (el) => {
		if (el && !signRef.current.includes(el)) signRef.current.push(el);
	};

	return (
		<>
			{signURL ? <img src={signURL} alt='signature' className='signImg' /> : <SignatureCanvas penColor='black' canvasProps={{ className: 'signature' }} ref={addToRefs} onEnd={() => handleSignatureEnd()} />}
			<div style={{ marginBottom: 2, display: disable ? 'none' : 'flex' }}>
				<Button variant='outlined' color='inherit' onClick={clearSignature} disabled={signature === null && signURL === null}>
					簽名清除
				</Button>
				<Button variant='contained' sx={{ marginLeft: 2, display: signURL ? 'none' : undefined }} onClick={() => handleSignatureDone(signature)} disabled={signature === null}>
					確認
				</Button>
			</div>
		</>
	);
}
