import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: {},
	storeList: [],
	storeName: '',
	forcePW: false,
	changePW: false,
	userSettings: [],
	zone: '',
	hq: false,
	userName: '',
	staffStores: [],
	userRoutes: [],
	weather: [],
	vaildUser: false,
	mobile: false,
	weatherUpdate: '-',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addUser: (state, action) => {
			state.value = { ...action.payload };
		},
		addStoreList: (state, action) => {
			state.storeList = [...action.payload];
		},
		addStoreName: (state, action) => {
			state.storeName = action.payload;
		},
		addForcePW: (state, action) => {
			state.forcePW = action.payload;
		},
		addChangePW: (state, action) => {
			state.changePW = action.payload;
		},
		addUserSettings: (state, action) => {
			state.userSettings = action.payload;
		},
		addZone: (state, action) => {
			const checkHQ = action.payload !== 'store';
			const userName = state.value.username;
			state.zone = action.payload;
			state.hq = checkHQ;
			state.userName = checkHQ ? userName : userName.toUpperCase();
		},
		addStaffStores: (state, action) => {
			state.staffStores = action.payload;
		},
		addUserRoutes: (state, action) => {
			let route = state.userRoutes.concat(action.payload);
			let unique = new Set(route);
			state.userRoutes = Array.from(unique);
		},
		addWeather: (state, action) => {
			state.weather = action.payload;
		},
		addVaildUser: (state, action) => {
			state.vaildUser = action.payload;
		},
		addMobile: (state, action) => {
			state.mobile = action.payload;
		},
		addWeatherUpdate: (state, action) => {
			state.weatherUpdate = action.payload;
		},
		reset: (state) => {
			state.value = {};
			state.storeList = [];
			state.storeName = '';
			state.forcePW = false;
			state.changePW = false;
			state.userSettings = [];
			state.zone = '';
			state.hq = false;
			state.userName = '';
			state.staffStores = [];
			state.userRoutes = [];
			state.weather = [];
			state.vaildUser = false;
			state.weatherUpdate = '-';
		},
	},
});

export const { addUser, addStoreList, addStoreName, addForcePW, addChangePW, addUserSettings, addZone, addStaffStores, addUserRoutes, addWeather, addVaildUser, addMobile, addWeatherUpdate, reset } = authSlice.actions;

export default authSlice.reducer;
