import React, { useState } from 'react';
import { MainListItems, MobileListItems } from '../listItems';
import AccountMenu from '../components/AccountMenu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const drawerWidth = 180;

export default function TopBar({ open, user, location, storeLocation, pageTitle, dialogOpen, unreadLength, userVal, changePW, loggout }) {
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => setIsClosing(false);

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				<MainListItems handleDrawerClose={handleDrawerClose} />
			</List>
		</div>
	);

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				sx={{
					// width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					zIndex: 1300,
					backgroundColor: '#f8f8f8',
				}}>
				<Toolbar>
					<IconButton aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ display: { sm: 'none' }, color: '#000' }}>
						{mobileOpen ? <NavigateBeforeIcon /> : <MenuIcon />}
					</IconButton>
					<Avatar src={location.pathname.split('/').length > 1 ? '../../fav32.png' : './fav32.png'} alt='麻古茶坊' sx={{ width: 30, height: 30 }} />
					<Typography paddingLeft={1} variant='body' sx={{ color: '#000' }}>
						{storeLocation[0].split(',')[0]}
					</Typography>
					{/* <SelectVariants label={''} list={storeLocation} initialVal={curStore} sx={{ mb: 2, minWidth: 60, display: 'flex' }} disableUnderline={true} /> */}
					<Typography component='h1' variant='h6' noWrap sx={{ flexGrow: 1, color: '#000' }}>
						{pageTitle}
					</Typography>
					<IconButton onClick={dialogOpen}>
						<Badge color='error' badgeContent={unreadLength}>
							<NotificationsNoneIcon />
						</Badge>
					</IconButton>
					<AccountMenu open={open} userVal={userVal} changePW={changePW} loggout={loggout} />
				</Toolbar>
			</AppBar>
			<Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
				<Drawer
					variant='temporary'
					open={mobileOpen}
					onTransitionEnd={handleDrawerTransitionEnd}
					onClose={handleDrawerClose}
					ModalProps={{ keepMounted: true }}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}>
					{drawer}
				</Drawer>
				<Drawer
					variant='permanent'
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box sx={{ display: { xs: 'block', sm: 'none' }, position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }}>
				<MobileListItems curUser={user} />
			</Box>
		</Box>
	);
}
