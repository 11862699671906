import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userStore, userOffice } from '../images/images';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function ActionStep({ imgTitle1, imgTitle2, secondStep, hideReturn, setOpen }) {
	const navigate = useNavigate();

	const images = [
		{
			url: userOffice,
			title: imgTitle1,
			width: '50%',
		},
		{
			url: userStore,
			title: imgTitle2,
			width: '50%',
		},
	];

	const ImageButton = styled(ButtonBase)(({ theme }) => ({
		position: 'relative',
		height: 200,
		[theme.breakpoints.down('sm')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover, &.Mui-focusVisible': {
			zIndex: 1,
			'& .MuiImageBackdrop-root': {
				opacity: 0.15,
			},
			'& .MuiImageMarked-root': {
				opacity: 0,
			},
			'& .MuiTypography-root': {
				border: '4px solid currentColor',
			},
		},
	}));

	const ImageSrc = styled('span')({
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center 40%',
	});

	const Image = styled('span')(({ theme }) => ({
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	}));

	const ImageBackdrop = styled('span')(({ theme }) => ({
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.4,
		transition: theme.transitions.create('opacity'),
	}));

	const ImageMarked = styled('span')(({ theme }) => ({
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	}));

	const toHome = () => {
		navigate('/home');
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Box minWidth={{ md: 500, xs: '100%' }} pt={3} alignContent='center'>
				<Typography pb={2} fontWeight='bold'>
					請選擇設定類型
				</Typography>
				{images.map((image) => (
					<ImageButton
						focusRipple
						key={image.title}
						style={{
							width: image.width,
						}}
						onClick={(e) => secondStep(e)}>
						<ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
						<ImageBackdrop className='MuiImageBackdrop-root' />
						<Image>
							<Typography
								component='span'
								variant='subtitle1'
								color='inherit'
								sx={{
									position: 'relative',
									p: 4,
									pt: 2,
									pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
								}}>
								{image.title}
								<ImageMarked className='MuiImageMarked-root' />
							</Typography>
						</Image>
					</ImageButton>
				))}
			</Box>
			<Box minWidth={{ md: 500, xs: '100%' }} pt={2} mt={1} alignSelf='center' sx={{ display: hideReturn ? 'none' : undefined }}>
				<Button onClick={toHome} variant='outlined' size='large' color='inherit' fullWidth>
					返回
				</Button>
			</Box>
		</React.Fragment>
	);
}
