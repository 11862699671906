import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	mergeDialog: false,
};

const deliverySlice = createSlice({
	name: 'delivery',
	initialState,
	reducers: {
		updateMergeDialog: (state, action) => {
			state.mergeDialog = action.payload;
		},
	},
});

export const { updateMergeDialog } = deliverySlice.actions;

export default deliverySlice.reducer;
