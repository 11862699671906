import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { getAuditContent, allStore } from '../utils/requestDelivery';
import { getStoreIds } from '../utils/extra';
import Dialog from '../components/Dialog';
import Signature from '../components/Signature';
import { storeBySid } from '../graphql/queries';
import { createAuditForm, updateAuditForm } from '../graphql/mutations';
import Loadeffect from '../components/Loadeffect';
import { submitAudit } from '../utils/lambda';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

export default function Audit() {
	const navigate = useNavigate();
	const location = useLocation();
	const disable = location.state.action === 'view' ? true : false;
	const check = location.state.route && JSON.parse(location.state.route);
	const from = location.state.from;
	const to = location.state.to;
	const oldData = [check && check.row];
	const userLogged = useSelector((state) => state.users.value.attributes);
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.zone;
	const hqStaff = userVal.hq;
	const userName = userVal.userName;
	const master = zoneInfo === 'master';
	const [storeName, setStoreName] = useState(hqStaff ? ['總部'] : [userLogged.name]);
	const [content, setContent] = useState([]);
	const [open, setOpen] = useState(true);
	const [allStoreId, updateAllStoreId] = useState([]);
	const [allStoreName, updateAllStoreName] = useState([]);
	// const [weight, setWeight] = useState(0);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [resultVal, setResultVal] = useState(check ? oldData[0].Content.map((c) => c.Result) : []);
	const [dialog, setDialog] = useState(false);
	const [formValue, setFormValue] = useState([
		{
			Date: check ? oldData[0].createdAt : moment().format('L'),
			Store: check ? oldData[0].Store : hqStaff ? '' : storeName[0],
			Model: check ? oldData[0].Model : hqStaff ? '' : '業主自評',
			Type: check ? oldData[0].Type : hqStaff ? '' : '業主自評',
			Content: check ? oldData[0].Content : [],
			Final: check ? oldData[0].Final : 0,
			Suggestion: check ? oldData[0].Suggestion : '',
			Improvement: check ? oldData[0].Improvement : '',
			id: check ? oldData[0].id : '',
			Status: '',
			signature: check ? oldData[0].signature : '',
			staffSign: check ? oldData[0].staffSign : '',
		},
	]);
	const [imageURL, setImageURL] = useState(null);
	const [imageURL2, setImageURL2] = useState(null);
	// const [signID, setSignID] = useState([]);
	// const [staffID, setStaffID] = useState([]);
	const hqView = { display: hqStaff ? undefined : 'none' };
	const category = [
		...new Set(
			content.map((c) => {
				return c.Category;
			})
		),
	];

	const DContent = () => {
		return (
			<Typography>
				請勿關閉視窗 <CircularProgress size={24} sx={{ color: 'red' }} style={{ verticalAlign: 'bottom' }} />
			</Typography>
		);
	};

	useEffect(() => {
		setOpen(true);
		getAuditContent().then((res) => {
			res.sort(function (a, b) {
				if (a.Category === '黃金守則' && b.Category !== '黃金守則') {
					return -1; // '黃金守則' comes before other categories
				} else if (a.Category !== '黃金守則' && b.Category === '黃金守則') {
					return 1; // Other categories come after '黃金守則'
				}
				return parseInt(a.Number) - parseInt(b.Number);
			});
			let updateRes = res;
			let contentVal = [];
			if (check === undefined) {
				updateRes.map((u) => {
					u.Result = '';
					u.Weight = 0;
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				}); //set Result to null;
			} else {
				// let oWeight = 0;
				updateRes.map((u, index) => {
					u.Result = oldData[0].Content[index].Result;
					u.Details = oldData[0].Content[index].Details;
					u['Weight'] = u.Category === '黃金守則' ? (u.Result === 'true' ? 0 : u.Partition) : u.Result === 'true' ? u.Partition : 0;
					// if(u.Category==='黃金守則' && u.Result === 'false'){
					// oWeight += u.Partition
					// }
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				});
				// setWeight(oWeight);
			}
			formValue[0].Content = contentVal;
			setContent(updateRes);
			if (master) {
				allStore().then((list) => {
					updateAllStoreId(list[0]);
					updateAllStoreName(list[1]);
				});
			}
			setOpen(false);
		});
		if (check && check.row.signature && check.row.staffSign) {
			setImageURL(check.row.signature);
			setImageURL2(check.row.staffSign);
		}
		getStoreIds(hqStaff, zoneInfo, userName, '/audit', 'name').then((store) => {
			let sName = hqStaff ? store : storeName;
			setStoreName(sName);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const back = () => navigate('/audit', { state: { name: '自檢表', from: from, to: to } });

	const cellStyle = {
		fontWeight: 'bold',
		backgroundColor: '#B1B4BA',
	};
	const fontSizeStyle = {
		fontSize: 16,
	};
	const fontWeightStyle = {
		fontWeight: 'bold',
	};

	const handleSubmit = async (status) => {
		setDialog(true);
		status === 'submit' ? setSubmitLoading(true) : setSaveLoading(true);
		let newContent = formValue[0].Content.map((c) => JSON.stringify(c));
		let sid;
		let supervisor;
		let result;
		let index = master ? allStoreName.indexOf(formValue[0].Store) : storeName.indexOf(formValue[0].Store);
		sid = master ? allStoreId[index] : userLogged.nickname.split(',')[index];
		try {
			let store = await API.graphql({
				query: storeBySid,
				variables: {
					sid: sid,
				},
			});
			store = store.data.storeBySid.items[0];
			sid = store.sid;
			supervisor = store.supervisor_id;
		} catch (error) {
			console.log('error', error);
		}
		let create = {
			Date: formValue[0].Date,
			year: formValue[0].Date.split('/')[0],
			month: formValue[0].Date.split('/')[1],
			Content: newContent,
			Final: formValue[0].Final,
			Store: formValue[0].Store,
			store_id: sid,
			supervisor_id: supervisor,
			Improvement: formValue[0].Improvement,
			Suggestion: formValue[0].Suggestion,
			Type: formValue[0].Type,
			Model: formValue[0].Model,
			Status: [status + '@' + moment().toISOString()],
			signature: hqStaff ? imageURL : '',
			staffSign: hqStaff ? imageURL2 : '',
			Author: userLogged.given_name,
		};
		if (check) {
			create = Object.assign({ id: check.row.id }, create);
			create._version = check.row._version;
			result = await API.graphql({
				query: updateAuditForm,
				variables: { input: create },
			});
		} else {
			result = await API.graphql({
				query: createAuditForm,
				variables: { input: create },
			});
		}
		// const sign = await DataStore.query(MacuStore,signID);
		// await DataStore.save(MacuStore.copyOf(sign, updated => {updated.signature = imageURL}));
		// if (hqStaff) {
		// 	const staffSign = await DataStore.query(MacuStore,staffID);
		// 	await DataStore.save(MacuStore.copyOf(staffSign, updated => {updated.staffSign = imageURL2}));
		// }
		if (status === 'submit' || status === 'working') {
			submitAudit(formValue[0].Store, sid, formValue[0].Date, supervisor, create.Content, status);
		}
		if (result.data !== undefined) {
			back();
			setDialog(false);
			status === 'submit' ? setSubmitLoading(false) : setSaveLoading(false);
		}
	};

	// const getSignature = async (val) => {
	// 	const sign = await DataStore.query(MacuStore,(c) => c.sid.eq(userLogged.nickname.split(',')[storeName.indexOf(val)]));
	// 	if (!check) setImageURL(sign[0].signature);
	// 	setSignID(sign[0].id);
	// }

	// const getStaffSign = async (val) => {
	// 	const sign = await DataStore.query(MacuStore,(c) => c.sid.eq(userLogged.nickname.split(',')[storeName.indexOf(val)]));
	// 	// if (!check) setImageURL2(sign[0].staffSign);
	// 	setImageURL2(sign[0].staffSign)
	// 	setStaffID(sign[0].id);
	// }

	function getInput(e, id, key) {
		let val = e.target.value;
		let contentVal = [];
		let form = [...formValue];
		switch (key) {
			case 'Store':
				if ((master && !allStoreName.includes(val)) || (!master && !storeName.includes(val))) {
					val = '';
				}
				form[0][key] = val;
				setFormValue(form);
				break;
			case 'Type':
			case 'Model':
				form[0][key] = val;
				setFormValue(form); // in order to get the val instantly to check if required shows
				// if (key === 'Store' && val) getSignature(val);
				break;
			case 'Suggestion':
			case 'Improvement':
				formValue[0][key] = val;
				break;
			case 'Details':
				content.map((c) => {
					if (c.id === id) c[key] = val;
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				formValue[0].Content = contentVal;
				break;
			case 'Result':
				let sum = 0;
				let curWeight = 0;
				let calculate = formValue[0].Final;
				let inputVal = [...content];
				inputVal.map((c) => {
					if (c.id === id) {
						c[key] = val;
					}
					if (c.Category === '黃金守則') {
						c.Weight = c.Result === 'false' ? c.Partition : 0;
					} else {
						c.Weight = c.Result === 'true' ? c.Partition : 0;
						if (c.Result === 'true') {
							sum += c.Partition * 0.1;
						}
					}
					if (c.Category === '黃金守則') {
						curWeight += c.Weight; //count total weight
					}
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				calculate = parseInt(sum - sum * curWeight * 0.01);
				formValue[0].Final = calculate;
				formValue[0].Content = contentVal;
				setContent(inputVal);
				// setWeight(curWeight);
				setResultVal(inputVal.map((c) => c.Result));
				break;
			default:
				break;
		}
	}

	return (
		<Box>
			<Loadeffect state={open} />
			<Button align='left' sx={{ display: 'flex', marginBottom: 2 }} variant='contained' startIcon={<ArrowBackIosIcon />} onClick={back}>
				返回
			</Button>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell colSpan={5}>
								<Grid container>
									<Grid item md={5} xs={6}>
										<Chip variant='outlined' icon={disable ? <RemoveRedEyeIcon /> : <EditIcon />} label={disable ? '檢視模式' : '編輯模式'} color={disable ? 'error' : 'success'} />
									</Grid>
									<Grid item md={7} xs={6} sx={{ fontWeight: 'bold', fontSize: 18 }}>
										麻古門市營運自主檢查表
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow align={'center'} style={{ fontWeight: 'bold' }}>
							<TableCell colSpan={2} align={'center'} style={{ fontWeight: 'bold' }}>
								日期: {formValue[0].Date}
							</TableCell>
							<TableCell align={'center'} style={{ fontWeight: 'bold' }} sx={{ display: check ? 'none' : undefined }}>
								時間: {moment().format('LT')}
							</TableCell>
							<TableCell align={'center'} style={{ fontWeight: 'bold' }} sx={{ display: check ? 'none' : undefined }}>
								填表人: {userLogged.given_name || formValue[0].Store}
							</TableCell>
							<TableCell colSpan={3} align={'right'} sx={{ paddingTop: 2 }}>
								<Grid container>
									<Grid item>
										<Autocomplete freeSolo disabled={disable} id='combo-box-demo' options={master ? allStoreName : storeName} value={formValue[0].Store} onBlur={(e) => getInput(e, null, 'Store')} sx={{ width: 200 }} renderInput={(params) => <TextField {...params} label='請選擇門市' size='small' />} />
									</Grid>
									<Grid item>
										{formValue[0].Store ? null : (
											<Typography color='error' align='center' padding={1} style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ fontWeight: 'bold' }}>
								評核模式:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby='demo-row-radio-buttons-group-label'
										name='row-radio-buttons-group'
										value={formValue[0].Model}
										onChange={(e) => getInput(e, null, 'Model')}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 10,
											},
										}}>
										<FormControlLabel value='通知式' control={<Radio size='small' />} label='通知式' />
										<FormControlLabel value='不通知式: 第1次' control={<Radio size='small' />} label='不通知式: 第1次' />
										<FormControlLabel value='不通知式: 第2次' control={<Radio size='small' />} label='第2次' />
										<FormControlLabel value='不通知式: 第3次' control={<Radio size='small' />} label='第3次' />
										<FormControlLabel value='不通知式: 第4次' control={<Radio size='small' />} label='第4次' />
										<FormControlLabel sx={{ display: disable ? undefined : 'none' }} value='業主自評' control={<Radio size='small' />} label='業主自評' />
										{formValue[0].Model ? null : (
											<Typography color='error' style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ fontWeight: 'bold' }}>
								評核類別:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby='demo-controlled-radio-buttons-group'
										name='controlled-radio-buttons-group'
										value={formValue[0].Type}
										key={'type'}
										onChange={(e) => getInput(e, null, 'Type')}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 10,
											},
										}}>
										<FormControlLabel value='常規評核' control={<Radio size='small' />} label='常規評核' />
										<FormControlLabel value='續約評核' control={<Radio size='small' />} label='續約評核' />
										<FormControlLabel sx={{ display: disable ? undefined : 'none' }} value='業主自評' control={<Radio size='small' />} label='業主自評' />
										{formValue[0].Type ? null : (
											<Typography color='error' style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align={'center'} style={{ width: 20, ...cellStyle }}>
								配分
							</TableCell>
							<TableCell align={'center'} style={{ width: 20, ...cellStyle }}>
								NO.
							</TableCell>
							<TableCell align={'center'} style={{ width: 300, ...cellStyle }}>
								評核內容
							</TableCell>
							<TableCell align={'center'} style={{ width: 180, ...cellStyle }}>
								結果
							</TableCell>
							<TableCell align={'center'} style={{ width: 250, ...cellStyle }}>
								說明
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key={`category_${category[0]}`}>
							<TableCell colSpan={6} align='center' style={{ ...fontWeightStyle, backgroundColor: '#fff2cc' }}>
								{category[0]}
							</TableCell>
						</TableRow>
						{content.map((c, index) => (
							<React.Fragment key={c.Partition + c.Number}>
								{index > 0 && content[index - 1].Category !== c.Category && (
									<TableRow key={`category_${c.Category}`}>
										<TableCell colSpan={6} align='center' style={{ ...fontWeightStyle, backgroundColor: '#CADAEA' }}>
											{c.Category}
										</TableCell>
									</TableRow>
								)}
								<TableRow key={c.Partition + c.Number}>
									<TableCell align='center'>{c.Category === '黃金守則' ? c.Partition + '%' : c.Partition * 0.1}</TableCell>
									<TableCell align='center'>{c.Number}</TableCell>
									<TableCell align='left'>{c.Content}</TableCell>
									<TableCell align='center'>
										<Grid container>
											<Grid item>
												<FormControl size='small' disabled={disable}>
													<RadioGroup
														row
														aria-labelledby='demo-controlled-radio-buttons-group'
														name='controlled-radio-buttons-group'
														value={c.Result}
														key={c.id}
														onChange={(e) => getInput(e, c.id, 'Result')}
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 10,
															},
														}}>
														<FormControlLabel value={true} control={<Radio size='small' />} label='合格' />
														<FormControlLabel value={false} control={<Radio size='small' />} label='不合格' sx={{ backgroundColor: disable && c.Result === 'false' && 'yellow', pr: disable && c.Result === 'false' && '1rem' }} />
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item>
												{c.Result ? null : (
													<Typography color='error' style={{ fontSize: 12 }}>
														*必填
													</Typography>
												)}
											</Grid>
										</Grid>
									</TableCell>
									<TableCell align='center'>
										<TextField name='Details' multiline rows={1} disabled={disable} id={c.id} size='medium' defaultValue={c.Details} onChange={(e) => getInput(e, c.id, 'Details')}></TextField>
										{/* score:{score},weight:{weight}, individual: {c.Weight} final:{formValue[0].Final} */}
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
						<TableRow>
							<TableCell colSpan={6} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								總分: {formValue[0].Final}
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={3} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								本次建議事項
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								待改善事項
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={3} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField multiline fullWidth rows={4} disabled={disable} defaultValue={formValue[0].Suggestion} onChange={(e) => getInput(e, null, 'Suggestion')}></TextField>
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField multiline fullWidth rows={4} disabled={disable} defaultValue={formValue[0].Improvement} onChange={(e) => getInput(e, null, 'Improvement')}></TextField>
							</TableCell>
						</TableRow>
						<TableRow sx={{ display: master ? undefined : 'none' }}>
							<TableCell colSpan={3} align={'center'}>
								<Typography>業主簽名</Typography>
								<Signature disable={disable} signURL={imageURL} handleSignatureDone={(arg) => setImageURL(arg)} />
							</TableCell>
							<TableCell colSpan={3} align={'center'}>
								<Typography>督導簽名</Typography>
								<Signature disable={disable} signURL={imageURL2} handleSignatureDone={(arg) => setImageURL2(arg)} />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={6} align={'center'}>
								<Button value='放棄' color='error' onClick={back} startIcon={<DeleteForeverIcon />} variant='contained' disabled={disable}>
									放棄!
								</Button>
								&nbsp;
								<Button value='儲存' color='inherit' onClick={() => handleSubmit('working')} variant='contained' startIcon={<SaveIcon />} disabled={!formValue[0].Store || disable}>
									<Typography sx={{ display: !saveLoading ? undefined : 'none' }}>儲存</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: saveLoading ? undefined : 'none' }} />
								</Button>
								&nbsp;
								<Button value='提交' color='success' onClick={() => handleSubmit('submit')} variant='contained' startIcon={<CloudUploadIcon />} disabled={!(formValue[0].Model && formValue[0].Type && formValue[0].Store && !resultVal.includes('') && resultVal.length > 0) || disable}>
									<Typography sx={{ display: !submitLoading ? undefined : 'none' }}>提交</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: submitLoading ? undefined : 'none' }} />
								</Button>
								<Typography pt={1} display={!(formValue[0].Model && formValue[0].Type && formValue[0].Store && !resultVal.includes('') && resultVal.length > 0) ? undefined : 'none'}>
									<Alert severity='error' style={{ fontWeight: 'bold', display: 'inline-flex' }}>
										尚有題目未填
									</Alert>
								</Typography>
								<Dialog dialog={dialog} title={'儲存中...'} content={DContent()} showBtn={false} fullWidth={false} />
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
