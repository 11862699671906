import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Hub, API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Login } from './Login';
import ChangePw from './auth/ChangePw';
import Permission from './auth/Permission';
import ResetPw from './auth/ResetPw';
import routes from './route';
import './App.css';
import CreateUser from './auth/CreateUser';
import AppContent from './app/AppContent';
import TopBar from './app/TopBar';
import { checkAuthenticatedUser, changePWClick, loggoutUser, loggout, handleBack, setUserRoute, updateCurRoutes } from './app/util';
import { onCreateAnnouncement, onUpdateAnnouncement } from './graphql/subscriptions';
import { getAnnouncement, updateAnnouncement } from './utils/requestDelivery';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

function App({ children }) {
	const defaultTheme = createTheme();
	const route = '/home';
	const today = moment().format('L');
	const location = useLocation();
	const navigate = useNavigate();
	const { userSettings: userLvl, value: userVal, forcePW, userRoutes: level, vaildUser: user, storeList: storeLocation } = useSelector((state) => state.users);
	const [pageTitle, setPageTitle] = useState();
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [allAnnouncement, setAllAnnouncement] = useState([]);
	const [updateList, setUpdateList] = useState([]);
	const [title, setTitle] = useState('登入');
	const unreadLength = allAnnouncement.filter((f) => !f.Read.includes(userVal.username) && f.StartDate <= today).length;
	const hubListenerCancelToken = Hub.listen('auth', (data) => {});
	const hideToolPages = ['/resetPw', '/permission', '/createUser', '/home'];

	const dialogClose = () => {
		setOpenDialog(false);
		if (updateList.length > 0) {
			syncAnnouncement();
		}
	};

	const dialogOpen = () => {
		setOpenDialog(true);
		listAnnouncement(userVal); //get the latest annoucement version when dialog opens
	};

	function syncAnnouncement() {
		let updates = {};
		allAnnouncement.map(async (a, index) => {
			if (updateList.includes(a.id)) {
				updates['id'] = a.id;
				updates['_version'] = a._version;
				updates['Read'] = [...a.Read];
				updateAnnouncement(updates);
				setUpdateList([]);
			}
		});
	}

	const listAnnouncement = async (userVal) => {
		const hq = userVal.attributes.nickname.split(',').includes('HQ');
		const res = await getAnnouncement(hq, level, userVal.username);
		res.sort((a, b) => b.StartDate.localeCompare(a.StartDate));
		setAllAnnouncement(res);
	};

	const checkOpen = () => {
		if (!forcePW && window.location.pathname !== '/announcement' && unreadLength > 0) {
			setOpenDialog(true);
		}
	};

	const checkNotice = (newVal) => (newVal.Creator === userVal.username || newVal.StartDate <= today) && (level.includes(newVal.Section) || newVal.Section === '全部');

	const getRoutes = (allRoutes, notice) =>
		allRoutes.map((route) => {
			const res = setUserRoute(route, userLvl, userVal);
			if (route.collapse) {
				return getRoutes(route.collapse, notice);
			}
			if (res[0] && !notice) {
				switch (route.key) {
					case 'permission':
						return <Route path='/permission' element={<Permission setOpen={setOpen} />} key='Permission' />;
					case 'createUser':
						return <Route path='/createUser' element={<CreateUser setOpen={setOpen} />} key='CreateUser' />;
					case 'resetPw':
						return <Route path='/resetPw' element={<ResetPw />} key='ResetPw' />;
					default:
						return <Route exact path={res[1]} element={res[2]} key={res[3]} />;
				}
			} else if (res[0] && notice) {
				updateCurRoutes(res[4]);
			}
			return null;
		});

	useEffect(() => {
		routes.filter((i) => i.route === location.pathname).map((i) => setPageTitle(i.name));
	}, [location.pathname]);

	useEffect(() => {
		Hub.listen('auth', (data) => {
			switch (data.payload.event) {
				case 'signIn':
					checkAuthenticatedUser();
					break;
				case 'signOut':
					loggoutUser(setTitle, navigate);
					break;
				case 'signIn_failure':
					console.log('user sign in failed');
					break;
				default:
					console.log('default', data.payload.event);
					break;
			}
		});
		hubListenerCancelToken();
		checkAuthenticatedUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (userLvl.length > 0 && Object.keys(userVal).length > 0 ) {
	// 		getRoutes(routes, true);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [userLvl, userVal]);

	useEffect(() => {
		if (level.length > 0) {
			listAnnouncement(userVal);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [level]);

	useEffect(() => {
		checkOpen();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allAnnouncement]);

	useEffect(() => {
		let updated;
		if (userVal) {
			const subCreate = API.graphql({
				query: onCreateAnnouncement,
			}).subscribe({
				next: ({ value }) => {
					const newVal = value.data.onCreateAnnouncement;
					setAllAnnouncement((prev) => {
						updated = prev;
						if (checkNotice(newVal)) {
							updated = [newVal, ...prev];
						}
						return updated;
					});
				},
				error: (error) => console.warn(error),
			});

			const subUpdate = API.graphql({
				query: onUpdateAnnouncement,
			}).subscribe({
				next: ({ value }) => {
					const newVal = value.data.onUpdateAnnouncement;
					setAllAnnouncement((pre) => {
						const unchanged = pre.filter((p) => p.id !== newVal.id);
						updated = pre;
						if (checkNotice(newVal)) {
							updated = [newVal, ...unchanged];
						}
						return updated;
					});
				},
				error: (error) => console.warn(error),
			});

			return () => {
				subCreate.unsubscribe();
				subUpdate.unsubscribe();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userVal]);

	return (
		<div className='App' style={{ backgroundColor: '#eaeaea', height: '100vh', display: 'flex', flexFlow: 'column', justifyContent: 'space-evenly' }}>
			{user ? (
				<LocalizationProvider dateAdapter={AdapterMoment}>
					{children}
					<ThemeProvider theme={defaultTheme}>
						<Box sx={{ display: 'flex' }}>
							<CssBaseline />
							<TopBar open={open} user={user} location={location} storeLocation={storeLocation} pageTitle={pageTitle} dialogOpen={dialogOpen} unreadLength={unreadLength} userVal={userVal} changePW={changePWClick} loggout={loggout} />
							<AppContent hideToolPages={hideToolPages} location={location} userLvl={userLvl} getRoutes={getRoutes} routes={routes} route={route} openDialog={openDialog} dialogClose={dialogClose} setAllAnnouncement={setAllAnnouncement} allAnnouncement={allAnnouncement} setUpdateList={setUpdateList} updateList={updateList} />
						</Box>
					</ThemeProvider>
				</LocalizationProvider>
			) : forcePW ? (
				<Routes>
					<Route path='/changePw' element={<ChangePw handleBack={() => handleBack(navigate)} loggout={loggout} setTitle={setTitle} title={title} />} />
					<Route path='*' element={<Navigate to='/changePw' replace />} />
				</Routes>
			) : (
				<Login title={title} />
			)}
		</div>
	);
}

export default App;
