import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Storage } from '@aws-amplify/storage';
import { DropzoneDialog } from 'mui-file-dropzone';
import { getFiles } from '../drive/util';
import DataGridTw from '../utils/datagridtw';
import { getStoreDrive, sleep } from '../utils/extra';
import { downloadBlob } from '../utils/file';
import Tooltip from '../components/Tooltip';
import Dialog from '../components/Dialog';
import { Checkmark } from '../components/Circlemark';
import ControlledAccordions from '../components/Accordion';
import { DataGrid, GridActionsCellItem, GridToolbar, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid'; //useGridApiRef, GridToolbarContainer, GridToolbarQuickFilter
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import MuiPagination from '@mui/material/Pagination';
import Home from '@mui/icons-material/Home';
import Folder from '@mui/icons-material/Folder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const uploadMax = 6;

const Pagination = ({ page, onPageChange, className }) => {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return <MuiPagination sx={{ '&.MuiPaginationItem-root': { backgroundColor: '#eaeaea' } }} className={className} count={pageCount} page={page + 1} onChange={(event, newPage) => onPageChange(event, newPage - 1)} />;
};

const CustomPagination = (props) => <GridPagination ActionsComponent={Pagination} {...props} />;

export default function Drive() {
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.value.attributes.zoneinfo;
	const hq = zoneInfo !== 'store';
	const userName = hq ? userVal.value.username : userVal.value.username.toUpperCase();
	const storeIDs = [userName];
	const curStore = useSelector((state) => state.users.storeName);
	const curStorePath = `${curStore.split(',')[1]}_${curStore.split(',')[0]}/`;
	const initialFilter = {
		items: [],
		quickFilterExcludeHiddenColumns: true,
		quickFilterValues: [],
	};
	const [loading, setloading] = useState(true);
	const [allData, setAllData] = useState({});
	const [selectedFile, setSelectedFile] = useState([]);
	const [curFolder, setCurFolder] = useState([{ name: '主頁', key: '主頁' }]);
	const [curPath, setCurPath] = useState('');
	const [rows, setRows] = useState([]);
	const [filterModel, setFilterModel] = useState(initialFilter);
	const [dialog, setDialog] = useState(false);
	const [upDialog, setUpDialog] = useState(false);
	const [proDialog, setProDialog] = useState(false);
	const [fodlerDialog, setFolderDialog] = useState(false);
	const [folderName, setFolderName] = useState('');
	const [delFile, setDelFile] = useState({});
	const [progress, setProgress] = useState(0);
	const [uploadSize, setUploadSize] = useState(0);
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
	const [accessFolders, setAccessFolders] = useState([]); // need to check user zone/area in order to inlcude specific folders
	const [profitStores, setProfitStores] = useState([]);
	const [profitDialog, setProfitDialog] = useState(false);

	const cols = [
		// ...(true ? [] : {field: 'id'}),
		{
			field: 'name',
			headerName: '名稱',
			width: 300,
			renderCell: ({ row }) =>
				row.folderType ? (
					<>
						<Folder sx={{ mr: 0.5 }} fontSize='inherit' />
						{row.name}
					</>
				) : (
					<>
						<DescriptionIcon sx={{ mr: 0.5 }} fontSize='inherit' />
						{row.name}
					</>
				),
		},
		{ field: 'itemType', headerName: '檔案類別', width: 210 },
		{ field: 'lastModified', headerName: '最後修改日期', width: 210 }, //time
		// { field: 'user', headerName: '用戶', width: 100 },
		{ field: 'size', headerName: '大小', width: 180 },
		{
			field: 'actions',
			type: 'actions',
			headerName: '操作',
			width: 120,
			cellClassName: 'actions',
			getActions: ({ id, row }) => {
				const downloadDisplay = { display: row.folderType ? 'none' : undefined };
				const deleteDisplay = { display: (curPath === '' && zoneInfo !== 'master') || accessFolders.includes(id.split('/')[id.split('/').length - 2]) ? 'none' : undefined };
				return [
					<GridActionsCellItem
						icon={
							<Tooltip title={'刪除'}>
								<DeleteForeverIcon />
							</Tooltip>
						}
						label='刪除'
						onClick={() => handleDeleteClick(id, row)}
						color='error'
						sx={deleteDisplay}
					/>,
					<GridActionsCellItem
						icon={
							<Tooltip title={'下載'}>
								<DownloadIcon />
							</Tooltip>
						}
						label='下載'
						onClick={() => handleDownloadClick(row)}
						color='success'
						sx={downloadDisplay}
					/>,
				];
			},
		},
	];

	useEffect(() => {
		listData(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curStore]);

	const handlePaginationModelChange = (newPaginationModel) => setPaginationModel(newPaginationModel);

	const handleFolderBackHome = (val) => listFile(val);

	const handleDownloadClick = async (row) => {
		const result = await Storage.get(row.key, { download: true });
		downloadBlob(result.Body, row.name);
	};

	const handleDialog = () => setDialog(!dialog);

	const handleUpDialog = () => setUpDialog(!upDialog);

	const handleFolderDialog = () => setFolderDialog(!fodlerDialog);

	const handleDeleteClick = (id, row) => {
		setDelFile({ id: id, row: row });
		handleDialog();
	};

	const handleUpReset = () => {
		setUpDialog(false);
		setSelectedFile([]);
		setProgress(0);
		setUploadSize(0);
	};

	const handleDeleteSubmit = async () => {
		try {
			const { key, id } = delFile.row;
			await Storage.remove(key);
			setAllData((prev) => prev.filter((item) => item.id !== id));
			setRows((prev) => prev.filter((item) => item.id !== id));
			setDelFile({});
			handleDialog();
		} catch (error) {
			console.log('Error ', error);
		}
	};

	const handleFolderSubmit = async () => {
		try {
			await Storage.put(`${curPath}/${folderName}/`, ''); //{level: "private"}protected // path public/folder/folder/folder or files...
			await listData(true);
			handleFolderDialog();
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	};

	const uploadFile = async () => {
		try {
			setProDialog(true);
			const newKeys = [];
			selectedFile.map((i) => setUploadSize((prev) => (prev += i.size)));
			for (const s in selectedFile) {
				const { key } = await Storage.put(`${curPath}/${selectedFile[s].name}`, selectedFile[s], {
					progressCallback(p) {
						setProgress((prev) => (prev += p.loaded));
					},
				}); //{level: "private"}protected
				newKeys.push(key);
			}
			await listData(true);
			await sleep(1, 3000);
			setProDialog(false);
			handleUpReset();
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	};

	const listData = async (fromUpload) => {
		let sid = hq ? await getStoreDrive(hq, zoneInfo, userName, '/drive') : storeIDs;
		sid = zoneInfo === 'master' ? [''] : sid; // sid.concat(['HQ_台北辦公室/','HQ_台中辦公室/']) // set public folders accessible by all user
		setAccessFolders(sid);
		setloading(true);
		setFilterModel(initialFilter);
		const path = curStore.includes('總部') ? '' : curStorePath; // maybe should get all folders?
		const prefix = curStore.includes('總部') ? sid : [accessFolders]; //'HQ_台北辦公室/','CD001_台中大里中興店/' // filter what folders user can view
		const { sortedF, sortedUpload } = await getFiles(path, prefix);
		if (fromUpload) {
			setCurPath(curPath);
			setRows(sortedF.filter((r) => r.path.startsWith(`Root/${curPath}`) && r.path.split('/').length === `Root/${curPath}`.split('/').length + 1));
		} else {
			setCurPath(path);
			setRows(sortedF.filter((row) => row.path.startsWith('Root') && row.path.split('/').length === 2));
			setCurFolder([{ name: '主頁', key: '主頁', breadcrumb: true }]);
		}
		setAllData(sortedF);
		setProfitStores(sortedUpload);
		setloading(false);
	};

	const listFile = async (folder) => {
		setFilterModel(initialFilter);
		if (folder.breadcrumb) {
			if (folder.key === '主頁') {
				setRows(allData.filter((row) => row.path.startsWith('Root') && row.path.split('/').length === 2));
				setCurFolder([{ name: '主頁', key: '主頁', breadcrumb: true }]);
				setCurPath('');
			} else {
				const paths = folder.path.split('/');
				const ab = paths.map((p, index) => ({
					name: p === 'Root' ? '主頁' : p,
					path: paths.slice(0, index + 1).join('/'),
					key: paths.slice(0, index + 1).join('/'),
					breadcrumb: true,
				}));
				setRows(allData.filter((r) => r.path.startsWith(folder.path) && r.path.split('/').length === folder.path.split('/').length + 1));
				setCurFolder(ab);
				setCurPath(folder.path === 'Root' ? '' : folder.path.replace('Root/', ''));
			}
		} else {
			const newPath = folder.path;
			setRows(allData.filter((r) => r.path.startsWith(newPath) && r.path.split('/').length === newPath.split('/').length + 1));
			setCurFolder([...curFolder, { name: folder.name, key: folder.key, breadcrumb: true, path: newPath }]);
			setCurPath(newPath.replace('Root/', ''));
		}
	};

	const folderRoute = () =>
		curFolder.map((item) => (
			<Link underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' onClick={() => handleFolderBackHome(item)} key={item.key + 1} style={{ cursor: 'pointer' }}>
				{item.name === '主頁' ? <Home sx={{ mr: 0.5 }} fontSize='inherit' /> : <Folder sx={{ mr: 0.5 }} fontSize='inherit' />}
				{item.name}
			</Link>
		));

	const UploadProgress = () => (
		<Box sx={{ display: 'flex', alignItems: 'center', width: '270px' }}>
			{Math.round(parseInt((progress / uploadSize) * 100)) === 100 ? (
				<Checkmark size='large' />
			) : (
				<>
					<Box sx={{ width: '100%', mr: 1 }}>
						<LinearProgress variant='determinate' value={parseInt((progress / uploadSize) * 100)} />
					</Box>
					<Box sx={{ minWidth: 35 }}>{`${Math.round(parseInt((progress / uploadSize) * 100))}%`}</Box>
				</>
			)}
		</Box>
	);

	const ProfitStatsDialog = (data) => (
		<Grid container>
			{['門店', '最後上傳時間'].map((title, index) => (
				<Grid item key={title + index} xs={6} position='sticky' top={0} fontWeight='bold' sx={{ backgroundColor: '#fff' }}>
					{title}
				</Grid>
			))}
			{data.map((s) =>
				[s.store, s.lastUploaded].map((res, i) => (
					<Grid item key={i + s.store + res} xs={6} pt={1}>
						{res}
					</Grid>
				))
			)}
		</Grid>
	);

	const FolderDialog = () => (
		<div style={{ width: '30vh' }}>
			新增資料夾致：
			<Typography sx={{ verticalAlign: 'middle', display: 'flex', mt: 1 }}>
				<Folder fontSize='small' />
				&nbsp;
				{curPath ? curPath : '主頁'}/{' '}
			</Typography>
			<TextField id='folder_input' fullWidth variant='standard' placeholder='新資料夾名稱...' onChange={(event) => setFolderName(event.target.value)} sx={{ mt: 1 }} />
		</div>
	);

	return (
		<Box>
			<Card sx={{ marginTop: 2 }}>
				<CardContent sx={{ marginBottom: 2 }}>
					<Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
						<Button variant='contained' onClick={handleUpDialog} startIcon={<CloudUploadIcon />} disabled={curPath === '' || (!(zoneInfo === 'master') && !accessFolders.some((i) => curPath.split('/').includes(i)))}>
							上傳檔案
						</Button>
						<Button variant='contained' color='success' onClick={handleFolderDialog} startIcon={<CreateNewFolderIcon />} disabled={curPath === '' || (!(zoneInfo === 'master') && !accessFolders.some((i) => curPath.split('/').includes(i)))}>
							新增資料夾
						</Button>
						{accessFolders.length >= 1 && zoneInfo === 'master' && (
							<Button color='inherit' variant='contained' onClick={() => setProfitDialog(true)} startIcon={<AssessmentIcon />}>
								損益表上傳統計
							</Button>
						)}
					</Stack>
					<Box mt={2} height={600}>
						<Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
							{folderRoute()}
						</Breadcrumbs>
						<DataGrid
							rows={rows}
							columns={cols}
							loading={loading}
							localeText={DataGridTw}
							disableColumnFilter
							disableColumnSelector
							disableDensitySelector
							pagination
							rowSelection={false}
							pageSizeOptions={[10, 50, 100]}
							initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
							slots={{ toolbar: GridToolbar, pagination: CustomPagination }}
							onRowClick={({ row }) => (row.folderType ? listFile(row) : '')}
							filterModel={filterModel}
							onFilterModelChange={(newModel) => setFilterModel(newModel)}
							paginationModel={paginationModel}
							onPaginationModelChange={handlePaginationModelChange}
							slotProps={{
								pagination: { labelRowsPerPage: '每頁顯示資料數' },
								toolbar: {
									showQuickFilter: true,
									printOptions: { disableToolbarButton: true },
									csvOptions: { disableToolbarButton: true },
								},
							}}
							sx={{ marginTop: 1, '.MuiDataGrid-cell:focus': { outline: 'none' }, '& .MuiDataGrid-row:hover': { cursor: 'pointer' }, '& .MuiDataGrid-toolbarContainer': { flexDirection: 'row-reverse' } }}
						/>
					</Box>
				</CardContent>
			</Card>
			<Dialog dialog={dialog} title={'確定要刪除嗎？'} content={`是否要刪除 ${delFile.row ? delFile.row.name : ''}?`} cancel={'取消'} submit={'刪除'} handleClose={handleDialog} handleSubmit={handleDeleteSubmit} showBtn={true} showSub={true} />
			<DropzoneDialog
				open={upDialog}
				fileObjects={selectedFile}
				filesLimit={uploadMax}
				onDrop={(newFileObjs) => setSelectedFile((prev) => prev.concat(newFileObjs))}
				onDelete={(deleteFileObj) => setSelectedFile((prev) => prev.filter((obj) => obj.path !== deleteFileObj.path))}
				onClose={() => handleUpReset()}
				onSave={() => uploadFile()}
				getFileAddedMessage={(fileName) => `已添加：${fileName}`}
				getDropRejectMessage={(rejectedFile) => `錯誤檔案：${rejectedFile}`}
				getFileRemovedMessage={(fileName) => `已移除：${fileName}`}
				getFileLimitExceedMessage={(filesLimit) => `一次最多上傳 ${filesLimit} 個檔案`}
				dialogTitle={`上傳檔案至：${curPath}`}
				dropzoneText='點擊或將檔案拖移至此處'
				previewText='已添加的檔案：'
				cancelButtonText='取消'
				submitButtonText='確認上傳'
				showPreviews={true}
				showFileNamesInPreview={false}
				previewChipProps={{ color: 'success' }}
				useChipsForPreview={true}
			/>
			<Dialog dialog={proDialog} title={Math.round(parseInt((progress / uploadSize) * 100)) === 100 ? '上傳完成' : '上傳中'} content={<UploadProgress />} showBtn={false} showSub={false} />
			<Dialog dialog={profitDialog} title={'損益表上傳統計'} content={<ControlledAccordions storeData={profitStores} dataContent={ProfitStatsDialog} />} cancel='關閉' handleClose={() => setProfitDialog(false)} showBtn={true} />
			<Dialog dialog={fodlerDialog} title={'新增資料夾'} content={FolderDialog()} cancel={'取消'} submit={'新增'} handleClose={handleFolderDialog} handleSubmit={handleFolderSubmit} showBtn={true} showSub={true} />
		</Box>
	);
}
