import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loadeffect = ({ state }) => {
	return (
		<Backdrop sx={{ color: '#ffff', zIndex: 1 }} open={state}>
			<CircularProgress color='inherit' />
			&nbsp;載入中...
		</Backdrop>
	);
};

export default Loadeffect;
