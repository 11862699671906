import React, { useEffect, useState } from 'react';
import Loadeffect from '../components/Loadeffect';
import Dialog from '../components/Dialog';
import { filteredItems, SearchBox } from '../components/ShippingSearch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';

export default function Orders({ userLogged, dList }) {
	const [count, setCount] = useState(0);
	const [deliveryList, updateDeliveryList] = useState([]);
	const [open, setOpen] = useState(true);
	const [searchItem, setsearchItem] = useState('');

	useEffect(() => {
		setOpen(true);
		updateDeliveryList([...dList]);
		setCount([...dList].length);
		setOpen(false);
	}, [dList]);

	const handleSearchChange = (event) => setsearchItem(event.target.value);

	function toggle(id, val) {
		if (val === 'content') {
			deliveryList.map((i) => (i.id === id ? (i.open = !i.open) : ''));
		} else if (val === 'dialog') {
			deliveryList.map((i) => (i.id === id ? (i.dialog = !i.dialog) : ''));
		}
		updateDeliveryList([...deliveryList]);
	}

	function Row(props) {
		const { row } = props;
		let updated = new Date(new Date(row.updated).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace('T', ' ').replace('Z', '').slice(0, -4);
		let res = [];
		let [count, qty, price, oStatus] = [0, 0, 0, ''];
		row.items.map((i) => {
			let val = i.split('@');
			res.push(val);
			count += 1;
			qty += parseInt(val[1]);
			price += parseInt(val[2]);
			return val;
		});
		switch (row.status) {
			case 'pickup':
				oStatus = '前往取件';
				break;
			case 'pickup_complete':
				oStatus = '取件完成';
				break;
			case 'dropoff':
				oStatus = '運送中';
				break;
			case 'delivered':
				oStatus = '運送完成';
				break;
			case 'canceled':
				oStatus = '已取消';
				break;
			case 'returned':
				oStatus = '已退送';
				break;
			default:
				oStatus = '確認中';
				break;
		}
		let proof = JSON.parse(row.deliveryInfo[0].delivered).img_proof;
		return (
			<Card style={{ borderRadius: 15, marginBottom: 20 }}>
				<CardContent>
					{userLogged.split(',')[1] === 'HQ' ? (
						<Stack direction='row'>
							<Chip label={row.pickup_name} style={{ fontWeight: 'bold' }}></Chip>
						</Stack>
					) : null}
					<Grid container style={{ textAlign: 'left' }} paddingTop={2}>
						<Grid item md={2.5} xs={6} paddingLeft={{ md: 2 }}>
							<Typography fontSize={12} color='text.secondary'>
								訂單編號
							</Typography>
							<Typography variant='h5' gutterBottom>
								{row.pay_state === '未支付' ? '[現金單] ' : null}
								{row.id.split('-')[2]}
							</Typography>
							<Typography fontSize={15} gutterBottom>
								{row.deliveryInfo.map((item, index) => '#' + item.uuid.substr(item.uuid.length - 5))}
							</Typography>
						</Grid>
						<Grid item md={2} xs={3}>
							<Typography fontSize={12} color='text.secondary'>
								顧客姓名
							</Typography>
							<Typography variant='h5' gutterBottom>
								{row.dropoff_name}
							</Typography>
							<Typography fontSize={15}>{row.dropoff_number}</Typography>
						</Grid>
						<Grid item md={1.5} xs={3}>
							<Typography fontSize={12} color='text.secondary'>
								運費
							</Typography>
							<Typography variant='h5' gutterBottom>
								${row.fee * 0.01}
							</Typography>
						</Grid>
						<Grid item md={2.5} xs={6}>
							<Typography fontSize={12} color='text.secondary'>
								狀態
							</Typography>
							<Typography variant='h5' gutterBottom>
								{oStatus}
							</Typography>
							<Typography fontSize={15} gutterBottom>
								{updated}
							</Typography>
						</Grid>
						<Grid item md={1.5} xs={6}>
							<Typography fontSize={12} color='text.secondary'>
								顧客簽名
							</Typography>
							<Typography variant='h5' gutterBottom>
								{proof ? (
									<Tooltip title='點擊放大檢視'>
										<img src={proof} alt='顧客簽名' width='100' height='100' loading='lazy' onClick={() => toggle(row.id, 'dialog')} />
									</Tooltip>
								) : (
									<>-</>
								)}
							</Typography>
						</Grid>
						<Grid item md={2} xs={12} padding={{ xs: 1, md: 2.5 }} paddingLeft={{ xs: 12 }}>
							<Button variant='outlined' color='info' size='large' startIcon={<RemoveRedEyeRoundedIcon />} onClick={() => toggle(row.id, 'content')}>
								查看訂單
							</Button>
						</Grid>
					</Grid>
					<Stack>
						<Collapse in={row.open} timeout='auto' unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Stack direction={{ xs: 'column', md: 'row' }} spacing={1} divider={<Divider orientation='vertical' flexItem />}></Stack>
								<Table size='small' aria-label='purchases'>
									<TableHead>
										<TableRow>
											<TableCell>品項 ({count})</TableCell>
											<TableCell>數量 ({qty})</TableCell>
											<TableCell>金額 (${price})</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{res.map((i, index) => (
											<TableRow key={row.id + i + index}>
												<TableCell component='th' scope='row'>
													{i[0]}
												</TableCell>
												<TableCell>{i[1]}</TableCell>
												<TableCell>${i[2]}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</Stack>
				</CardContent>
				<Dialog dialog={row.dialog ? row.dialog : false} title={'顧客簽名'} content={<img src={proof} alt='顧客簽名' width='250' height='250' loading='lazy'></img>} cancel={'退出'} handleClose={() => toggle(row.id, 'dialog')} showBtn={false} />
			</Card>
		);
	}

	return (
		<Box>
			<Loadeffect state={open} />
			<Stack direction='row' flexWrap='wrap' paddingBottom={3}>
				<Chip variant='outlined' icon={<ListAltIcon />} label={`已完成: ${count} 單`} />
				<SearchBox searchItem={searchItem} handleSearchChange={handleSearchChange} boxStyle={{ width: { md: 350, xs: 220 }, pl: 1, pt: { md: 0, xs: 0.5 } }} />
			</Stack>
			{filteredItems(deliveryList, searchItem, false).map((row) => (
				<Row key={row.id} row={row} />
			))}
		</Box>
	);
}
