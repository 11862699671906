import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/zh-tw';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import XLSX from 'sheetjs-style';
import { getStoreIds } from '../utils/extra';
import Tooltip from '../components/Tooltip';
import Loadeffect from '../components/Loadeffect';
import ExportExcel from '../components/Export';
import Dialog from '../components/Dialog';
import { getAllCashLists } from '../utils/requestDelivery';
import DataGridTw from '../utils/datagridtw';
import { compareInvoice, InvoiceTitle, DifInvoice } from './util';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddIcon from '@mui/icons-material/Add';

export default function Cashview() {
	const userLogged = useSelector((state) => state.users.value.attributes);
	// const userStore = userLogged.nickname.split(',').length > 1 ? userLogged.nickname.split(',') : [userLogged.nickname];
	// const hq = userLogged.nickname.split(',').includes('HQ');
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.zone;
	const hq = userVal.hq;
	const userName = userVal.userName;
	const [storeIDs, setStoreIDs] = useState([userName]);
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setloading] = useState(true);
	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState([]);
	const [sortedRows, setSortedRows] = useState([]);
	const [fileName, setFileName] = useState(null);
	const [dataRows, setDataRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	const [dataColumns, setDataColumns] = useState([]);
	const [daysOfStore, setDaysOfStore] = useState({});
	const [storeOptions, setStoreOptions] = useState([]);
	const [storeId, setStoreId] = useState([]);
	const today = moment(new Date()).format('MM/DD/YYYY');
	const dateCheck = moment(location.state.currentMonth) || moment();
	const [date, setDate] = useState(moment(location.state.currentMonth) || moment());
	const [endDate, setEndDate] = useState(dateCheck.startOf('month').add(1, 'M').add(1, 'd').format('MM/DD/YYYY'));
	const [curStore, setCurStore] = useState(null);
	const [newData, setNewData] = useState([]);
	const [usedDate, setUsedDate] = useState([]);
	const [user, setUser] = useState();
	const [dialog, setDialog] = useState(false);
	const [invoice, setInvoice] = useState();

	const openDialog = (params) => {
		setDialog(true);
		setInvoice(params);
	};
	const handleCancel = () => setDialog(false);
	const handleEnter = () => setDialog(!dialog);

	const getSid = async () => {
		const store = await getStoreIds(hq, zoneInfo, userName, '/cash', 'id');
		let sid = hq ? store : storeIDs;
		setStoreIDs(sid);
		setUser(sid);
		getForm(sid, date);
		return store;
	};

	useEffect(() => {
		getSid();
		// setUser(userStore);
		// getForm(userStore, date);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'absolute',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 1,
	});

	function DContent() {
		if (invoice) {
			let pos = invoice.pos_receipt.map((i) => JSON.parse(i));
			let rec = invoice.receipt.map((i) => JSON.parse(i));
			const posUploaded = pos[0].InvoiceNo ? true : false;
			let difference = compareInvoice(pos, rec);
			return (
				<Box>
					<Grid container>
						<Grid item md={5} xs={12} hidden={!posUploaded}>
							<Typography px={2} fontSize={13} fontWeight='bold'>
								POS作廢發票:
							</Typography>
							<InvoiceTitle />
							{pos.map((i, index) => (
								<Grid container px={2} pb={1} key={'POScontainer' + index}>
									<Grid item md={7} xs={5} fontSize={13} className='input_container'>
										{i.InvoiceNo}
									</Grid>
									<Grid item md={5} xs={7} fontSize={13} className='input_container'>
										{i.TotalAmount ? i.TotalAmount : ''}
									</Grid>
								</Grid>
							))}
						</Grid>
						<Grid item md={!posUploaded ? 12 : 7} xs={12}>
							<Typography px={2} fontSize={13} fontWeight='bold'>
								門市作廢發票:
							</Typography>
							<InvoiceTitle />
							{rec.map((i, index) => (
								<Grid container px={1} pb={1} key={'reccontainer' + index}>
									<Grid sx={{ px: 1 }} item md={7} xs={5} fontSize={13} className='input_container'>
										{i['InvoiceNo'] || ''}
									</Grid>
									<Grid sx={{ px: 1 }} item md={5} xs={7} fontSize={13} className='input_container'>
										{i['TotalAmount'] === 0 ? '' : i['TotalAmount']}
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Box px={2} pt={2} hidden={!posUploaded}>
						<Divider></Divider>
						<DifInvoice dif={difference} />
					</Box>
				</Box>
			);
		}
	}

	const cols = [
		{
			field: '操作',
			headerName: '操作',
			width: 90,
			type: 'actions',
			cellClassName: 'actions',
			getActions: (params) => {
				return [
					<>
						<GridActionsCellItem
							icon={
								<Tooltip title='檢視'>
									<RemoveRedEyeIcon color='error' />
								</Tooltip>
							}
							label='view'
							onClick={(e) => navigate('/cash/edit', { state: { name: '修改每日收支', route: JSON.stringify(params), action: 'view', currentMonth: moment(date).format('MM/DD/YYYY') } })}
						/>
						<GridActionsCellItem
							icon={
								<Tooltip title='編輯'>
									<EditIcon color={today >= endDate ? 'disabled' : 'success'} />
								</Tooltip>
							}
							label='Edit'
							onClick={(e) => navigate('/cash/edit', { state: { name: '修改每日收支', route: JSON.stringify(params), action: 'edit', currentMonth: moment(date).format('MM/DD/YYYY') } })}
							disabled={today >= endDate}
						/>
					</>,
				];
			},
		},
		{
			field: '發票',
			headerName: '發票',
			width: 60,
			type: 'actions',
			cellClassName: 'receipt',
			getActions: (params) => {
				let rec = params.row.receipt.map((i) => JSON.parse(i));
				let pos = params.row.pos_receipt.map((i) => JSON.parse(i));
				let diff = compareInvoice(pos, rec);
				return [
					<GridActionsCellItem
						icon={
							<Tooltip title='查看發票'>
								<ReceiptLongIcon />
							</Tooltip>
						}
						label='receipt'
						onClick={(e) => openDialog(params.row)}
						disabled={!rec[0].InvoiceNo && !rec[0].InvoiceNo && !pos[0].InvoiceNo && !pos[0].InvoiceNo}
						color={diff.length > 0 ? 'error' : 'success'}
					/>,
				];
			},
		},
		{ field: 'store_id', headerName: '門店ID', width: 100 },
		{ field: 'store_name', headerName: '門店' },
		{ field: 'date', headerName: '日期' },
		{ field: 'year', headerName: '年份' },
		{ field: 'month', headerName: '月份' },
		{ field: 'cloud_revenue', headerName: '雲端營收', width: 130, hidden: !hq },
		{ field: 'correction', headerName: '更正', valueGetter: (params) => JParse(params.value).total || 0 },
		{ field: '日總收入', headerName: '日總收入', width: 130, hidden: !hq },
		{ field: '差額', headerName: '差額', hidden: !hq },
		{ field: 'daily_total_expense', headerName: '日總支出', width: 130, valueGetter: (params) => JParse(params.value).total || 0 },
		{ field: 'daily_total_sales', headerName: '門市現金', width: 130, valueGetter: (params) => JParse(params.value).total || 0 },
		{ field: 'uber', headerName: 'UBER', width: 110, hidden: !hq },
		{ field: 'uber_cash', headerName: 'UBER現金', width: 110, hidden: !hq },
		{ field: 'foodpanda', headerName: '熊貓', hidden: !hq },
		{ field: 'panda_go', headerName: 'PANDAGO' },
		{ field: 'linepay', headerName: 'LINEPAY', width: 130, hidden: !hq },
		{ field: 'jko', headerName: '街口支付', width: 130, hidden: !hq },
		{ field: 'mace_online', headerName: '麻吉線上金流', width: 155, hidden: !hq },
		{ field: 'ipass', headerName: '一卡通', width: 120, hidden: !hq },
		{ field: 'uu_card', headerName: '悠遊卡', width: 120, hidden: !hq },
		{ field: 'credit_card', headerName: '信用卡', width: 120, hidden: !hq },
		{ field: 'pxpay', headerName: '全支付', width: 120, hidden: !hq },
		// {field:'更新日期',headerName:'更新日期'},//hide
		{ field: '應存入金額', headerName: '應存入金額', width: 140, hidden: !hq },
		// {field:'deposit_date',headerName:'存款日期', width: 130},
		{ field: 'deposit_amount', headerName: '存入金額', width: 130 },
		{ field: '存入差額', headerName: '存入差額', width: 130, hidden: !hq },
		// {field:'createdAt',headerName:'建立日期', width: 130},
		{ field: 'author', headerName: '人員', width: 140, valueGetter: (params) => '早:' + (JParse(params.value)['AM'][JParse(params.value)['AM'].length - 1] || '未填') + '/晚:' + (JParse(params.value)['PM'][JParse(params.value)['PM'].length - 1] || '未填') },
		{ field: 'editor', headerName: '修改人員', width: 120 },
		{ field: 'hours', headerName: '時數' },
		{ field: 'profitability', headerName: '產值', hidden: !hq },
		{ field: 'receipt', headerName: '發票' },
		{ field: 'pos_receipt', headerName: 'POS發票' },
		...(true ? [] : { field: 'id', headerName: 'id' }), //hide
	];

	const JParse = (val) => JSON.parse(val);

	async function getForm(userVal, date) {
		setOpen(true);
		setloading(true);
		let sid = userVal; //(userVal.nickname==='HQ')? null:userVal.nickname;
		let p_correction = '';
		let p_daily_total_expense = '';
		let p_daily_total_sales = '';
		getAllCashLists(sid, date.format('YYYY-MM')).then((res) => {
			let store = [];
			res[0].map((r) => store.push(r.store_name));
			let uniStore = [...new Set(store)];
			setStoreOptions(uniStore);

			let storeId = [];
			res[0].map((r) => storeId.push(r.store_id));
			let uniStoreId = [...new Set(storeId)];
			setStoreId(uniStoreId);
			res[0].sort((a, b) => {
				let store = a['store_id'].localeCompare(b['store_id']);
				return store !== 0 ? store : a['date'].localeCompare(b['date']);
			}); //sorting by store then date

			let useddates = uniStore.map((item) => {
				return { store_name: item, usedDate: [] };
			});

			res[0] = res[0].map((item, index) => {
				p_correction = typeof item.correction === 'string' ? JParse(item.correction).total : 0;
				p_daily_total_expense = typeof item.daily_total_expense === 'string' ? JParse(item.daily_total_expense).total : 0;
				p_daily_total_sales = typeof item.daily_total_sales === 'string' ? JParse(item.daily_total_sales).total : 0;
				Object.keys(item).map((o) => {
					if (item[o] === null) {
						if (o === 'correction' || o === 'daily_total_expense' || o === 'daily_total_sales') {
							item[o] = JSON.stringify({ AM: 0, PM: 0, total: 0 });
						} else if (o === 'author') {
							item[o] = JSON.stringify({ AM: [], PM: [], Edit: [] });
						} else if (o === 'receipt' || o === 'pos_receipt') {
							item[o] = [JSON.stringify({ InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: item.date })];
						} else {
							item[o] = 0;
						}
					}
					return '';
				});
				let checkEditor = JParse(item['author'])['Edit'][JParse(item['author'])['Edit'].length - 1];
				item['year'] = moment(item['date']).format('YYYY');
				item['month'] = moment(item['date']).format('MM');
				item['日總收入'] = p_daily_total_expense + p_daily_total_sales + item['uber'] + item['foodpanda'] + item['linepay'] + item['jko'] + item['mace_online'] + item['ipass'] + item['uu_card'] + item['credit_card'] + item['pxpay'] + item['uber_cash'] + item['panda_go'];
				item['差額'] = -(item['cloud_revenue'] - p_correction - item['日總收入']);
				item['應存入金額'] = item['cloud_revenue'] - p_correction - p_daily_total_expense - item['uber'] - item['foodpanda'] - item['linepay'] - item['jko'] - item['mace_online'] - item['ipass'] - item['uu_card'] - item['credit_card'] - item['pxpay'];
				item['存入差額'] = item['deposit_amount'] - item['應存入金額'];
				item['profitability'] = item['hours'] ? item['日總收入'] / item['hours'] : '時數未填';
				item['editor'] = checkEditor === undefined ? '' : checkEditor;
				item['createdAt'] = moment(item['createdAt']).format('YYYY-MM-DD HH:mm:ss');
				useddates.map((used) => (used.store_name === item['store_name'] ? used.usedDate.push(item['date']) : null));
				return item;
			});

			let data = {};
			let passData = {};
			cols.map((i) => {
				switch (i.field) {
					case 'daily_total_sales':
					case 'daily_total_expense':
					case 'correction':
						data[Object.values(i)[0]] = JSON.stringify({ AM: 0, PM: 0, total: 0 });
						break;
					case 'store_id':
					case 'store_name':
					case 'date':
					case 'year':
					case 'month':
					case 'deposit_date':
					case 'id':
						data[Object.values(i)[0]] = '';
						break;
					case 'author':
						data[Object.values(i)[0]] = JSON.stringify({ AM: [], PM: [], Edit: [] });
						break;
					case 'receipt':
					case 'pos_receipt':
						data[Object.values(i)[0]] = [JSON.stringify({ InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: '' })];
						break;
					default:
						data[Object.values(i)[0]] = 0;
						break;
				}
				return '';
			});
			passData['columns'] = cols;
			passData['row'] = data;
			setNewData(passData); //for create new
			setRows(res[0]);
			setSortedRows(res[0]);
			setUsedDate(useddates);
			setloading(false);
			setOpen(false);
		});
	}

	const handleFile = async (e) => {
		const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data, { type: 'buffer' });
		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet);
		let row = [];
		row = jsonData;
		setDataRows(row);
		setFilteredRows(row);

		let colKey = Object.keys(jsonData[0]);
		colKey.push('id'); //datagrid required id for each row
		colKey = colKey.map((item) => ({ headerName: item, field: item }));
		setDataColumns(colKey);
		const store_days = row.map((i) => i['門店']);
		const counts = {};
		for (const num of store_days) {
			counts[num] = counts[num] ? counts[num] + 1 : 1;
		}
		setDaysOfStore(counts);
		let w = 1;
		let x = 0;
		let [revenue, cash, uber, foodpanda, linepay, jsko, mace, ipass, uucard, cc, pxpay] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		let [revenue_total, cash_total, uber_total, foodpanda_total, linepay_total, jsko_total, mace_total, ipass_total, uucard_total, cc_total, pxpay_total] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		row = row.reduce((list, elem, i) => {
			list.push(elem);
			list[i]['id'] = i;
			return list;
		}, []); //give id by index
		row = row.reduce((list, elem, i) => {
			list.push(elem);
			revenue += elem['雲端營收'];
			cash += elem['日總淨額'];
			uber += elem['UBER'];
			foodpanda += elem['熊貓'];
			linepay += elem['LINEPAY'];
			jsko += elem['街口支付'];
			mace += elem['麻吉線上金流'];
			ipass += elem['一卡通'];
			uucard += elem['悠遊卡'];
			cc += elem['信用卡'];
			pxpay += elem['全支付'];

			revenue_total += elem['雲端營收'];
			cash_total += elem['日總淨額'];
			uber_total += elem['UBER'];
			foodpanda_total += elem['熊貓'];
			linepay_total += elem['LINEPAY'];
			jsko_total += elem['街口支付'];
			mace_total += elem['麻吉線上金流'];
			ipass_total += elem['一卡通'];
			uucard_total += elem['悠遊卡'];
			cc_total += elem['信用卡'];
			pxpay_total += elem['全支付'];

			if ((x + 1) % 7 === 0 && x < counts[elem['門店']] && w < 4) {
				list.push({ id: 'week' + w + elem['門店'], 門店: elem['門店'], 門店ID: elem['門店ID'], 日期: '第' + w + '周總和', 雲端營收: revenue, 日總淨額: cash, UBER: uber, 熊貓: foodpanda, LINEPAY: linepay, 街口支付: jsko, 麻吉線上金流: mace, 一卡通: ipass, 悠遊卡: uucard, 信用卡: cc, 全支付: pxpay });
				w += 1;
				[revenue, cash, uber, foodpanda, linepay, jsko, mace, ipass, uucard, cc, pxpay] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			}
			x += 1;
			if (x === counts[elem['門店']]) {
				list.push({ id: 'week' + w + elem['門店'], 門店: elem['門店'], 門店ID: elem['門店ID'], 日期: '第' + w + '周總和', 雲端營收: revenue, 日總淨額: cash, UBER: uber, 熊貓: foodpanda, LINEPAY: linepay, 街口支付: jsko, 麻吉線上金流: mace, 一卡通: ipass, 悠遊卡: uucard, 信用卡: cc, 全支付: pxpay });
				list.push({ id: 'total' + w + elem['門店'], 門店: elem['門店'], 門店ID: elem['門店ID'], 日期: 'Total', 雲端營收: revenue_total, 日總淨額: cash_total, UBER: uber_total, 熊貓: foodpanda_total, LINEPAY: linepay_total, 街口支付: jsko_total, 麻吉線上金流: mace_total, 一卡通: ipass_total, 悠遊卡: uucard_total, 信用卡: cc_total, 全支付: pxpay_total });
				w = 1;
				x = 0;
				[revenue, cash, uber, foodpanda, linepay, jsko, mace, ipass, uucard, cc, pxpay] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
				[revenue_total, cash_total, uber_total, foodpanda_total, linepay_total, jsko_total, mace_total, ipass_total, uucard_total, cc_total, pxpay_total] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			}
			return list;
		}, []);
		setDataRows(row);
		setFilteredRows(row);
	};

	function CustomToolbar() {
		let show = 'none';
		if (userLogged && (userLogged.preferred_username === 'jc' || userLogged.preferred_username === 'pw')) show = 'block';
		return (
			<GridToolbarContainer>
				<div style={{ display: show }}>
					<Tooltip title='新增資料'>
						<Button
							component='label'
							variant='contained'
							color='inherit'
							startIcon={<AddIcon />}
							onClick={(e) => {
								navigate('/cash/edit', { state: { name: '新增每日收支', route: JSON.stringify(newData), date: JSON.stringify(usedDate) } });
							}}>
							新增
						</Button>
					</Tooltip>
					<ExportExcel excelData={sortedRows} columnTitles={cols} storeId={storeId}></ExportExcel>
					<Tooltip title='上傳資料'>
						<Button component='label' variant='outlined' color='inherit' startIcon={<CloudUploadIcon />}>
							上傳
							<VisuallyHiddenInput type='file' onChange={(e) => handleFile(e)} />
						</Button>
					</Tooltip>
				</div>
				<GridToolbarQuickFilter sx={{ paddingLeft: 2 }} />
			</GridToolbarContainer>
		);
	}

	const goBack = () => setFileName('');

	function filteredStore(o) {
		if (o && o.length > 0) {
			const stores = dataRows.filter((item) => o.includes(item['門店']));
			setFilteredRows(stores);
		} else {
			setFilteredRows(dataRows);
		}
	}

	function sortedStore(option) {
		if (option === null) {
			setCurStore(null);
			setSortedRows(rows);
		} else {
			let stores = rows.filter((item) => item['store_name'] === option);
			stores.sort((a, b) => {
				let store = a['store_id'].localeCompare(b['store_id']);
				return store !== 0 ? store : a['date'].localeCompare(b['date']);
			}); //sorting by store then date
			setSortedRows(stores);
			setCurStore(option);

			let storeId = [];
			stores.map((r) => storeId.push(r.store_id));
			let uniStoreId = [...new Set(storeId)];
			setStoreId(uniStoreId); //update storeId for passing into excel
		}
	}

	function getDate(d) {
		setDate(d);
		let endD = moment(d).startOf('month').add(1, 'M').add(1, 'd').format('MM/DD/YYYY');
		setEndDate(endD);
		setCurStore(null);
		getForm(user, d);

		let storeId = [];
		sortedRows.map((r) => storeId.push(r.store_id));
		let uniStoreId = [...new Set(storeId)];
		setStoreId(uniStoreId); //update storeId for passing into excel
	}

	return (
		<React.Fragment>
			<Loadeffect state={open} />
			{fileName ? (
				<Box>
					<Button color='primary' variant='contained' onClick={goBack}>
						返回
					</Button>
					<Typography>
						{fileName && (
							<Grid container pt={2} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
								<Grid item xs={2}>
									<Autocomplete disablePortal id='combo-box-demo' options={Object.keys(daysOfStore)} onChange={(e, option) => filteredStore(option)} sx={{ width: 200, backgroundColor: 'white' }} renderInput={(params) => <TextField {...params} label='門店' />} />
								</Grid>
								<Grid item xs={2}>
									<Typography>上傳門店數: {Object.keys(daysOfStore).length}</Typography>
								</Grid>
								<Grid item xs={6}>
									{fileName && <Typography>檔案名稱:{fileName}</Typography>}
								</Grid>
								<Grid item xs={2}>
									<Button color='primary' variant='contained' startIcon={<CloudUploadIcon />}>
										確認上傳
									</Button>
								</Grid>
							</Grid>
						)}
						<DataGrid
							rows={filteredRows}
							columns={dataColumns}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 100,
									},
								},
								sorting: {
									sortModel: [{ field: 'date', sort: 'desc' }],
								},
							}}
							localeText={DataGridTw}
							pageSizeOptions={[100]}
							disableColumnFilter
							disableRowSelectionOnClick
						/>
					</Typography>
				</Box>
			) : (
				<>
					<Grid container spacing={2} pb={2} display='flex' alignItems='center'>
						<Grid item xs={12} md={2.5}>
							<DemoContainer components={['DatePicker']}>
								<DatePicker
									disableFuture
									label={'日期'}
									views={['year', 'month']}
									onChange={(d) => getDate(d)}
									format='YYYY MMM'
									value={date}
									dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')}
									localeText={{
										datePickerToolbarTitle: '選擇日期',
										cancelButtonLabel: '取消',
										okButtonLabel: '確認',
									}}
									slotProps={{
										toolbar: { toolbarFormat: 'YYYY MMM', hidden: false },
									}}
									sx={{ backgroundColor: 'white' }}
								/>
							</DemoContainer>
						</Grid>
						<Grid item xs={12} md={2.5} marginTop={1}>
							<Autocomplete disablePortal fullWidth id='combo-box-demo' aria-label='門店' value={curStore} options={storeOptions} onChange={(e, option) => sortedStore(option)} renderInput={(params) => <TextField {...params} label='門店' />} sx={{ backgroundColor: 'white' }} noOptionsText={'沒有選項'} />
						</Grid>
					</Grid>
					<Typography align='left' pb={1} fontSize={14}>
						此區間表單修改期限: <b>{endDate}</b>之前 (請記得填寫作廢發票){' '}
					</Typography>
					<Card>
						<CardContent style={{ height: 600, width: '100%' }}>
							<DataGrid
								rows={sortedRows}
								columns={hq ? cols.slice(0, 31) : cols.slice(0, 31).filter((f) => f.hidden === undefined || f.hidden === false)}
								loading={loading}
								initialState={{
									pagination: {
										paginationModel: {
											pageSize: 32,
										},
									},
									sorting: {
										sortModel: [{ field: 'date', sort: 'desc' }],
									},
								}}
								localeText={DataGridTw}
								slots={{ toolbar: CustomToolbar }}
								pageSizeOptions={[32]}
								disableColumnFilter
								disableRowSelectionOnClick
							/>
						</CardContent>
					</Card>
					<Dialog dialog={dialog} title={'發票明細'} content={DContent()} cancel={'退出'} handleClose={handleCancel} handleSubmit={handleEnter} showBtn={true} disable={true} fullWidth={true} showSub={false} />
				</>
			)}
		</React.Fragment>
	);
}
