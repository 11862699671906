import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getIconColor, iconString } from '../utils/weather';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import StorefrontIcon from '@mui/icons-material/Storefront';
import WebIcon from '@mui/icons-material/Web';
import CallIcon from '@mui/icons-material/Call';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Storecard({ val, hqClick, weather }) {
	const [store, setStore] = useState([]);
	const [count, setCount] = useState([]);
	const userVal = useSelector((state) => state.users);
	const userLogged = userVal.value.attributes.nickname;
	const search = userLogged === 'HQ' || (userLogged.includes('HQ') && userLogged.split(',').length > 2);
	const [allStore, setAllStore] = useState([]);
	const groupBy = (items, key) =>
		items.reduce(
			(result, item) => ({
				...result,
				[item[key]]: [...(result[item[key]] || []), item],
			}),
			{}
		);

	useEffect(() => {
		let groupStore = groupBy(val, 'store_id');
		let storeVal = [];
		let [sCount, oCount, tCount] = [0, 0, 0];
		for (let key in groupStore) {
			let online = 0;
			let tele = 0;
			let name = groupStore[key][0].pickup_name;
			let latest = groupStore[key][0].createdAt;
			let sid = groupStore[key][0].store_id;
			groupStore[key].map((item) => (item.order_from === '電話' ? (tele += 1) : (online += 1)));
			storeVal.push({ name: name, total: online + tele, online: online, tele: tele, latestOrder: latest, sid: sid });
			sCount += 1;
			oCount += online;
			tCount += tele;
		}
		setStore(storeVal);
		setAllStore(storeVal);
		setCount([sCount, oCount, tCount]);
	}, [val]);

	function getStore(e) {
		let value = e.target.value;
		if (value) {
			let selected = store.filter((s) => s.name === value);
			setStore(selected);
		} else {
			setStore(allStore);
		}
	}

	return (
		<>
			<Stack direction='row' spacing={1} useFlexGap flexWrap='wrap' marginLeft={3} marginTop={2}>
				<Chip variant='outlined' icon={<StorefrontIcon />} label={`門市: ${count[0]} 間`} />
				<Chip variant='outlined' icon={<WebIcon />} label={`食在麻吉: ${count[1]} 單`} />
				<Chip variant='outlined' icon={<CallIcon />} label={`電話: ${count[2]} 單`} />
				<Autocomplete style={{ display: search ? undefined : 'none' }} id='combo-box-demo' options={store.map((s) => s.name)} onBlur={(e) => getStore(e)} sx={{ width: 200 }} size='small' renderInput={(params) => <TextField {...params} label='搜尋門市' />} />
			</Stack>
			<Grid container spacing={3} sx={{ marginTop: 1, marginLeft: 0 }}>
				{store.map((item, index) => {
					const storeWeather = weather.filter((i) => i.sid === item.sid);
					return (
						<Grid item xs={12} md={4} key={item.name + index}>
							<Card style={{ borderRadius: 15 }}>
								<CardContent>
									<Grid container style={{ textAlign: 'left' }}>
										<Grid item xs={12} md={12} paddingBottom={2}>
											<Grid container>
												<Grid item md={7.5} xs={7.5}>
													<Typography fontSize={12} color='text.secondary'>
														門市
													</Typography>
													<Typography variant='h6' gutterBottom>
														{item.name}
													</Typography>
												</Grid>
												<Grid item md={2.5} xs={2.5} display={storeWeather[0] === undefined ? 'none' : undefined}>
													<Typography fontSize={14} gutterBottom>
														{storeWeather[0] === undefined ? null : parseInt(storeWeather[0].current.temp)}&deg;C
													</Typography>
													<Stack spacing={0.5} direction='row'>
														<Typography fontSize={14} gutterBottom>
															{storeWeather[0] === undefined ? null : storeWeather[0].current.description}
														</Typography>
													</Stack>
												</Grid>
												<Grid item md={2} xs={2} display={storeWeather[0] === undefined ? 'none' : undefined}>
													<img src={storeWeather[0] === undefined ? null : iconString(storeWeather[0].current.icon)} height='40' width='40' style={{ paddingBottom: 4, backgroundColor: getIconColor(storeWeather[0] !== undefined ? storeWeather[0].current.icon : ''), borderRadius: 20 }} alt='weatherIcon' />
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={4} md={4} paddingBottom={2}>
											<Typography fontSize={12} color='text.secondary'>
												待配送
											</Typography>
											<Typography variant='h5' gutterBottom>
												{item.total}
											</Typography>
										</Grid>
										<Grid item xs={4} md={4} paddingBottom={2}>
											<Typography fontSize={12} color='text.secondary'>
												食在麻吉
											</Typography>
											<Typography variant='h5' gutterBottom>
												{item.online}
											</Typography>
										</Grid>
										<Grid item xs={4} md={4} paddingBottom={2}>
											<Typography fontSize={12} color='text.secondary'>
												電話
											</Typography>
											<Typography variant='h5' gutterBottom>
												{item.tele}
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<Divider />
								<CardActions sx={{ justifyContent: 'space-between' }}>
									<Button size='small' color='primary' onClick={(e) => hqClick(e, item.name, item.sid)}>
										查看
									</Button>
									<Typography fontSize={12} color='text.secondary'>
										最新訂單:{moment(item.latestOrder).format('HH:mm:ss')}
									</Typography>
								</CardActions>
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
}
