/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuditForm = /* GraphQL */ `
  mutation CreateAuditForm(
    $input: CreateAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    createAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditForm = /* GraphQL */ `
  mutation UpdateAuditForm(
    $input: UpdateAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    updateAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditForm = /* GraphQL */ `
  mutation DeleteAuditForm(
    $input: DeleteAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    deleteAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuditContent = /* GraphQL */ `
  mutation CreateAuditContent(
    $input: CreateAuditContentInput!
    $condition: ModelAuditContentConditionInput
  ) {
    createAuditContent(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditContent = /* GraphQL */ `
  mutation UpdateAuditContent(
    $input: UpdateAuditContentInput!
    $condition: ModelAuditContentConditionInput
  ) {
    updateAuditContent(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditContent = /* GraphQL */ `
  mutation DeleteAuditContent(
    $input: DeleteAuditContentInput!
    $condition: ModelAuditContentConditionInput
  ) {
    deleteAuditContent(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCashForm = /* GraphQL */ `
  mutation CreateCashForm(
    $input: CreateCashFormInput!
    $condition: ModelCashFormConditionInput
  ) {
    createCashForm(input: $input, condition: $condition) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCashForm = /* GraphQL */ `
  mutation UpdateCashForm(
    $input: UpdateCashFormInput!
    $condition: ModelCashFormConditionInput
  ) {
    updateCashForm(input: $input, condition: $condition) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCashForm = /* GraphQL */ `
  mutation DeleteCashForm(
    $input: DeleteCashFormInput!
    $condition: ModelCashFormConditionInput
  ) {
    deleteCashForm(input: $input, condition: $condition) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStaffInfo = /* GraphQL */ `
  mutation CreateStaffInfo(
    $input: CreateStaffInfoInput!
    $condition: ModelStaffInfoConditionInput
  ) {
    createStaffInfo(input: $input, condition: $condition) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStaffInfo = /* GraphQL */ `
  mutation UpdateStaffInfo(
    $input: UpdateStaffInfoInput!
    $condition: ModelStaffInfoConditionInput
  ) {
    updateStaffInfo(input: $input, condition: $condition) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStaffInfo = /* GraphQL */ `
  mutation DeleteStaffInfo(
    $input: DeleteStaffInfoInput!
    $condition: ModelStaffInfoConditionInput
  ) {
    deleteStaffInfo(input: $input, condition: $condition) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMacuStore = /* GraphQL */ `
  mutation CreateMacuStore(
    $input: CreateMacuStoreInput!
    $condition: ModelMacuStoreConditionInput
  ) {
    createMacuStore(input: $input, condition: $condition) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMacuStore = /* GraphQL */ `
  mutation UpdateMacuStore(
    $input: UpdateMacuStoreInput!
    $condition: ModelMacuStoreConditionInput
  ) {
    updateMacuStore(input: $input, condition: $condition) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMacuStore = /* GraphQL */ `
  mutation DeleteMacuStore(
    $input: DeleteMacuStoreInput!
    $condition: ModelMacuStoreConditionInput
  ) {
    deleteMacuStore(input: $input, condition: $condition) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDirectToken = /* GraphQL */ `
  mutation CreateDirectToken(
    $input: CreateDirectTokenInput!
    $condition: ModelDirectTokenConditionInput
  ) {
    createDirectToken(input: $input, condition: $condition) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDirectToken = /* GraphQL */ `
  mutation UpdateDirectToken(
    $input: UpdateDirectTokenInput!
    $condition: ModelDirectTokenConditionInput
  ) {
    updateDirectToken(input: $input, condition: $condition) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDirectToken = /* GraphQL */ `
  mutation DeleteDirectToken(
    $input: DeleteDirectTokenInput!
    $condition: ModelDirectTokenConditionInput
  ) {
    deleteDirectToken(input: $input, condition: $condition) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDeliveryData = /* GraphQL */ `
  mutation CreateDeliveryData(
    $input: CreateDeliveryDataInput!
    $condition: ModelDeliveryDataConditionInput
  ) {
    createDeliveryData(input: $input, condition: $condition) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDeliveryData = /* GraphQL */ `
  mutation UpdateDeliveryData(
    $input: UpdateDeliveryDataInput!
    $condition: ModelDeliveryDataConditionInput
  ) {
    updateDeliveryData(input: $input, condition: $condition) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDeliveryData = /* GraphQL */ `
  mutation DeleteDeliveryData(
    $input: DeleteDeliveryDataInput!
    $condition: ModelDeliveryDataConditionInput
  ) {
    deleteDeliveryData(input: $input, condition: $condition) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWeather = /* GraphQL */ `
  mutation CreateWeather(
    $input: CreateWeatherInput!
    $condition: ModelWeatherConditionInput
  ) {
    createWeather(input: $input, condition: $condition) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWeather = /* GraphQL */ `
  mutation UpdateWeather(
    $input: UpdateWeatherInput!
    $condition: ModelWeatherConditionInput
  ) {
    updateWeather(input: $input, condition: $condition) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWeather = /* GraphQL */ `
  mutation DeleteWeather(
    $input: DeleteWeatherInput!
    $condition: ModelWeatherConditionInput
  ) {
    deleteWeather(input: $input, condition: $condition) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
