/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAuditForm = /* GraphQL */ `
  subscription OnCreateAuditForm(
    $filter: ModelSubscriptionAuditFormFilterInput
  ) {
    onCreateAuditForm(filter: $filter) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAuditForm = /* GraphQL */ `
  subscription OnUpdateAuditForm(
    $filter: ModelSubscriptionAuditFormFilterInput
  ) {
    onUpdateAuditForm(filter: $filter) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAuditForm = /* GraphQL */ `
  subscription OnDeleteAuditForm(
    $filter: ModelSubscriptionAuditFormFilterInput
  ) {
    onDeleteAuditForm(filter: $filter) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAuditContent = /* GraphQL */ `
  subscription OnCreateAuditContent(
    $filter: ModelSubscriptionAuditContentFilterInput
  ) {
    onCreateAuditContent(filter: $filter) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAuditContent = /* GraphQL */ `
  subscription OnUpdateAuditContent(
    $filter: ModelSubscriptionAuditContentFilterInput
  ) {
    onUpdateAuditContent(filter: $filter) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAuditContent = /* GraphQL */ `
  subscription OnDeleteAuditContent(
    $filter: ModelSubscriptionAuditContentFilterInput
  ) {
    onDeleteAuditContent(filter: $filter) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCashForm = /* GraphQL */ `
  subscription OnCreateCashForm($filter: ModelSubscriptionCashFormFilterInput) {
    onCreateCashForm(filter: $filter) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCashForm = /* GraphQL */ `
  subscription OnUpdateCashForm($filter: ModelSubscriptionCashFormFilterInput) {
    onUpdateCashForm(filter: $filter) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCashForm = /* GraphQL */ `
  subscription OnDeleteCashForm($filter: ModelSubscriptionCashFormFilterInput) {
    onDeleteCashForm(filter: $filter) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateStaffInfo = /* GraphQL */ `
  subscription OnCreateStaffInfo(
    $filter: ModelSubscriptionStaffInfoFilterInput
  ) {
    onCreateStaffInfo(filter: $filter) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateStaffInfo = /* GraphQL */ `
  subscription OnUpdateStaffInfo(
    $filter: ModelSubscriptionStaffInfoFilterInput
  ) {
    onUpdateStaffInfo(filter: $filter) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteStaffInfo = /* GraphQL */ `
  subscription OnDeleteStaffInfo(
    $filter: ModelSubscriptionStaffInfoFilterInput
  ) {
    onDeleteStaffInfo(filter: $filter) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMacuStore = /* GraphQL */ `
  subscription OnCreateMacuStore(
    $filter: ModelSubscriptionMacuStoreFilterInput
  ) {
    onCreateMacuStore(filter: $filter) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMacuStore = /* GraphQL */ `
  subscription OnUpdateMacuStore(
    $filter: ModelSubscriptionMacuStoreFilterInput
  ) {
    onUpdateMacuStore(filter: $filter) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMacuStore = /* GraphQL */ `
  subscription OnDeleteMacuStore(
    $filter: ModelSubscriptionMacuStoreFilterInput
  ) {
    onDeleteMacuStore(filter: $filter) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDirectToken = /* GraphQL */ `
  subscription OnCreateDirectToken(
    $filter: ModelSubscriptionDirectTokenFilterInput
  ) {
    onCreateDirectToken(filter: $filter) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDirectToken = /* GraphQL */ `
  subscription OnUpdateDirectToken(
    $filter: ModelSubscriptionDirectTokenFilterInput
  ) {
    onUpdateDirectToken(filter: $filter) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDirectToken = /* GraphQL */ `
  subscription OnDeleteDirectToken(
    $filter: ModelSubscriptionDirectTokenFilterInput
  ) {
    onDeleteDirectToken(filter: $filter) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDeliveryData = /* GraphQL */ `
  subscription OnCreateDeliveryData(
    $filter: ModelSubscriptionDeliveryDataFilterInput
  ) {
    onCreateDeliveryData(filter: $filter) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDeliveryData = /* GraphQL */ `
  subscription OnUpdateDeliveryData(
    $filter: ModelSubscriptionDeliveryDataFilterInput
  ) {
    onUpdateDeliveryData(filter: $filter) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDeliveryData = /* GraphQL */ `
  subscription OnDeleteDeliveryData(
    $filter: ModelSubscriptionDeliveryDataFilterInput
  ) {
    onDeleteDeliveryData(filter: $filter) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateWeather = /* GraphQL */ `
  subscription OnCreateWeather($filter: ModelSubscriptionWeatherFilterInput) {
    onCreateWeather(filter: $filter) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateWeather = /* GraphQL */ `
  subscription OnUpdateWeather($filter: ModelSubscriptionWeatherFilterInput) {
    onUpdateWeather(filter: $filter) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteWeather = /* GraphQL */ `
  subscription OnDeleteWeather($filter: ModelSubscriptionWeatherFilterInput) {
    onDeleteWeather(filter: $filter) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onCreateSettings(filter: $filter) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onUpdateSettings(filter: $filter) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onDeleteSettings(filter: $filter) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAnnouncement = /* GraphQL */ `
  subscription OnCreateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onCreateAnnouncement(filter: $filter) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAnnouncement = /* GraphQL */ `
  subscription OnUpdateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onUpdateAnnouncement(filter: $filter) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAnnouncement = /* GraphQL */ `
  subscription OnDeleteAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onDeleteAnnouncement(filter: $filter) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePermission = /* GraphQL */ `
  subscription OnCreatePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onCreatePermission(filter: $filter) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePermission = /* GraphQL */ `
  subscription OnUpdatePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onUpdatePermission(filter: $filter) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePermission = /* GraphQL */ `
  subscription OnDeletePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onDeletePermission(filter: $filter) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
