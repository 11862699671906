import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { manageDelivery, checkDStatus } from '../utils/lambda';
import { sleep } from '../utils/extra';
import { Countdown } from '../utils/Countdown';
import Dialog from '../components/Dialog';
import Loadeffect from '../components/Loadeffect';
import { filteredItems, SearchBox } from '../components/ShippingSearch';
import Linkbutton from '../components/Linkbutton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export default function Orders({ userLogged, dList, info, token, hq }) {
	const { enqueueSnackbar } = useSnackbar();
	const [count, setCount] = useState(0);
	const [deliveryList, updateDeliveryList] = useState([]);
	const [onload, setOnload] = useState(true);
	const [open, setOpen] = useState(true);
	const [delV, setDelV] = useState({});
	const [searchItem, setsearchItem] = useState('');
	const [checkState, updateCheckState] = useState(false);

	const handleCancel = (list) => {
		setDelV(list);
		toggle(list.id, 'dialog');
	};

	const handleSubmitCancel = () => {
		cancelDriver(delV);
		toggle(delV.id, 'dialog');
	};

	useEffect(() => {
		if (onload) {
			setOnload(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLogged]);

	const checkDriver = async () => {
		if (!onload && !hq) {
			updateCheckState(true);
			deliveryList.map((row) =>
				row.deliveryInfo.map(async (item) => {
					item.oStatus = driverStatus(item.status);
					if (item.oStatus === '確認中' || item.oStatus === '前往取件') {
						// || item.d_id.startsWith('ret_')
						await checkDStatus({ id: row.id, d_id: item.d_id }, info, token);
					}
					return item;
				})
			);
			await sleep(1, 3000);
			updateCheckState(false);
		}
	};

	useEffect(() => {
		const today = moment();
		const someday = moment().add(1, 'minute').set('second', 0);
		const diff = someday.diff(today, 'milliseconds');
		const interval = setInterval(() => {
			checkDriver();
		}, diff);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onload, deliveryList]);

	useEffect(() => {
		if (!checkState) {
			setOpen(true);
			updateDeliveryList([...dList]);
			setCount([...dList].length);
			setOpen(false);
		}
	}, [dList, checkState]);

	Countdown(60000);

	function toggle(id, val) {
		if (val === 'content') {
			deliveryList.map((i) => (i.id === id ? (i.open = !i.open) : ''));
		} else if (val === 'dialog') {
			deliveryList.map((i) => (i.id === id ? (i.dialog = !i.dialog) : ''));
		}
		updateDeliveryList([...deliveryList]);
	}

	async function cancelDriver(list) {
		setOpen(true);
		try {
			await list.deliveryInfo.map(async (item) => await manageDelivery({ id: list.id, d_id: item.d_id }, info, token, 'cancel'));
			const newList = deliveryList.filter((item) => item.id !== list.id);
			updateDeliveryList(newList);
			setCount((prev) => prev - 1);
			snackbar('取消成功！');
		} catch (err) {
			console.log('Error', err);
		}
	}

	const snackbar = (msg) => enqueueSnackbar(msg) && setOpen(false);

	const dContent = (d) =>
		d.pay_state !== undefined
			? d.deliveryInfo.map((item) => (
					<Stack spacing={2} direction='column' justifyContent='center' alignItems='center'>
						<Typography>
							Uber單號： <b>#{item.uuid.substr(item.uuid.length - 5)}</b>&nbsp;&nbsp;&nbsp;門市單號： <b>{item.manifest_reference.split('_')[0]}</b>
						</Typography>
						<Typography>
							配送狀態： <b>{item.oStatus}</b>&nbsp;&nbsp;&nbsp;支付狀態： <b>{d.pay_state === '未支付' ? '未支付 [現金單] ' : '已支付'}</b>
						</Typography>
						<Typography fontWeight='bold' color='error'>{`現在取消${item.oStatus === '確認中' || item.oStatus === '前往取件' ? '不會收取運費' : `將收取運費$${d.fee * 0.01}`}`}</Typography>
					</Stack>
			  ))
			: null;

	const driverStatus = (dStatus) => {
		switch (dStatus) {
			case 'pickup':
				return '前往取件';
			case 'pickup_complete':
				return '取件完成';
			case 'dropoff':
				return '運送中';
			case 'delivered':
				return '運送完成';
			case 'canceled':
				return '已取消';
			case 'returned':
				return '已退送';
			default:
				return '確認中';
		}
	};

	const handleSearchChange = (event) => setsearchItem(event.target.value);

	function Row(props) {
		const { row } = props;
		row.deliveryInfo.map((item) => {
			item.oStatus = driverStatus(item.status);
			let arrival = false;
			if (item.status === 'pickup') {
				arrival = moment() > moment(item.pickup_eta);
			}
			if (item.status === 'dropoff') {
				arrival = moment() > moment(item.dropoff_eta);
			}
			let pickup_eta = parseInt(moment(moment(item.pickup_eta).diff(moment())).format('mm'));
			let dropoff_eta = parseInt(moment(moment(item.dropoff_eta).diff(moment())).format('mm'));
			if (item.status === 'pickup' || item.status === 'dropoff') {
				item.remaining = item.status === 'dropoff' ? '大約 ' + dropoff_eta + ' 分鐘送達' : '大約 ' + pickup_eta + '分鐘抵達取件, 大約 ' + dropoff_eta + ' 分鐘送達';
				if (arrival || pickup_eta === 0 || dropoff_eta === 0) {
					item.remaining = '即將抵達';
				}
			}
			if (item.courier) {
				item.courier = typeof item.courier === 'object' ? item.courier : JSON.parse(item.courier);
			}
			return item;
		});
		let returning = row.deliveryInfo.filter((item) => item.d_id.startsWith('ret_')).length > 0;
		let res = [];
		let [count, qty, price] = [0, 0, 0];
		row.items.map((i) => {
			let val = i.split('@');
			res.push(val);
			count += 1;
			qty += parseInt(val[1]);
			price += parseInt(val[2]);
			return val;
		});

		return (
			<Box>
				<Card style={{ borderRadius: 15, marginBottom: 20 }}>
					<CardContent>
						<Box textAlign='left'>
							<Typography fontSize={18} gutterBottom>
								<FiberManualRecordIcon fontSize='16' color={row.pay_state === '未支付' ? 'error' : 'success'} /> {row.pay_state}
								{row.pay_state === '未支付' ? ' [現金單]' : null}
							</Typography>
						</Box>
						<Grid container style={{ textAlign: 'left' }}>
							<Grid item md={3} xs={12}>
								{userLogged.split(',')[1] === 'HQ' ? (
									<>
										<Typography fontSize={12} color='text.secondary'>
											門市
										</Typography>
										<Typography variant='h6' gutterBottom paddingBottom={{ md: 2, xs: 1 }}>
											{row.pickup_name}
										</Typography>
									</>
								) : null}
								<Typography fontSize={12} color='text.secondary'>
									顧客資訊
								</Typography>
								<Typography variant='h6' gutterBottom paddingBottom={{ md: 2, xs: 1 }}>
									{row.dropoff_name} - {row.dropoff_number}
								</Typography>
							</Grid>
							<Grid item md={3.5} xs={12}>
								<Typography fontSize={12} color='text.secondary'>
									訂單編號
								</Typography>
								<Typography variant='h6' gutterBottom paddingBottom={{ md: 2, xs: 1 }}>
									{row.pay_state === '未支付' ? '[現金單]' : '[已支付]'}
									&nbsp;- {row.id.split('-')[2]}
								</Typography>
								<Typography fontSize={12} color='text.secondary'>
									運送地址
								</Typography>
								<Typography variant='h6' gutterBottom>
									{row.dropoff_address}
								</Typography>
								<Divider orientation='horizontal' sx={{ display: { md: 'none', xs: undefined }, paddingBottom: { md: 2, xs: 1 } }} />
							</Grid>
							<Grid item md={0.5} paddingBottom={{ md: 2, xs: 1 }} paddingRight={2} display={{ xs: 'none', md: 'block' }}>
								<Divider orientation='vertical' />
							</Grid>
							<Grid item xs={12} md={5}>
								<Grid container item spacing={2} paddingBottom={2} pt={2}>
									<Grid item xs={6} md={6} height={2}>
										<Typography fontSize={12} color='text.secondary'>
											客服電話
										</Typography>
										<Typography variant='h6' gutterBottom paddingBottom={{ md: 6, xs: 6 }}>
											0255941560
										</Typography>
									</Grid>
									<Grid item xs={6} md={6}>
										<Typography fontSize={12} color='text.secondary'>
											運費
										</Typography>
										<Typography variant='h6' gutterBottom>
											${row.fee * 0.01}
										</Typography>
									</Grid>
									{row.deliveryInfo.map((item, index) =>
										item.courier ? (
											<Grid container item key={index + item.uuid}>
												<Divider orientation='horizontal' style={{ width: '100%' }} />
												<Grid item xs={6} pt={2}>
													<Typography fontSize={12} color='text.secondary'>
														司機資訊{index + 1}
													</Typography>
													<Chip avatar={<Avatar alt={item.courier.name} src={item.courier.img_href} style={{ width: 40, height: 40, margin: 0 }} />} label={item.courier.name} variant='outlined' sx={{ height: 40, fontSize: 15, borderRadius: 20, borderWidth: 2 }} />
													<Typography paddingTop={1} fontSize={15}>
														{item.oStatus} {item.d_id.startsWith('ret_') && '(返回至門市)'}
													</Typography>
													<Typography fontSize={15}>{item.remaining}</Typography>
												</Grid>
												<Grid item xs={6} pt={2}>
													<Typography fontSize={12} color='text.secondary'>
														訂單資訊{index + 1}
													</Typography>
													<Typography fontSize={15}>Uber單號: #{item.uuid.substr(item.uuid.length - 5)}</Typography>
													<Typography fontSize={15}>門市單號: {item.manifest_reference.split('_')[0]}</Typography>
													<Linkbutton position='left' variant='text' color='success' icon={<GpsFixedIcon />} link={row.deliveryInfo[0].tracking_url} text='查看司機動態' />
												</Grid>
											</Grid>
										) : (
											<Grid item xs={6} md={6} key={index + item.uuid}>
												<Typography fontSize={12} color='text.secondary'>
													司機資訊
												</Typography>
												<Typography variant='h5' gutterBottom>
													確認中
												</Typography>
											</Grid>
										)
									)}
								</Grid>
							</Grid>
						</Grid>
						<Box>
							<Grid container justifyContent='center' spacing={{ md: 50, xs: 3 }}>
								<Grid item>
									<Button variant='outlined' color='info' size='large' startIcon={<RemoveRedEyeRoundedIcon />} onClick={() => toggle(row.id, 'content')}>
										查看訂單
									</Button>
								</Grid>
								<Grid item sx={returning ? { display: 'none' } : { display: 'block' }}>
									<Button variant='outlined' color='error' size='large' startIcon={<CancelRoundedIcon />} onClick={(e) => handleCancel(row)}>
										取消訂單
									</Button>
								</Grid>
							</Grid>
						</Box>
						<Stack>
							<Collapse in={row.open} timeout='auto' unmountOnExit>
								<Box sx={{ margin: 1 }}>
									<Stack direction={{ xs: 'column', md: 'row' }} spacing={1} divider={<Divider orientation='vertical' flexItem />}></Stack>
									<Table size='small' aria-label='purchases'>
										<TableHead>
											<TableRow>
												<TableCell>品項 ({count})</TableCell>
												<TableCell>數量 ({qty})</TableCell>
												<TableCell>金額 (${price})</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{res.map((i, index) => (
												<TableRow key={i + index}>
													<TableCell component='th' scope='row'>
														{i[0]}
													</TableCell>
													<TableCell>{i[1]}</TableCell>
													<TableCell>${i[2]}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Box>
							</Collapse>
						</Stack>
					</CardContent>
				</Card>
				<Dialog dialog={row.dialog} title={'即將取消訂單'} content={dContent(delV)} cancel={'退出'} submit={'確認取消'} handleClose={() => handleCancel(delV)} handleSubmit={handleSubmitCancel} showBtn={true} showSub={true} />
			</Box>
		);
	}

	return (
		<Box>
			<Loadeffect state={open} />
			<Stack direction='row' flexWrap='wrap' paddingBottom={3}>
				<Chip variant='outlined' icon={<ListAltIcon />} label={`配送中: ${count} 單`} />
				<SearchBox searchItem={searchItem} handleSearchChange={handleSearchChange} boxStyle={{ width: { md: 350, xs: 220 }, pl: 1, pt: { md: 0, xs: 0.5 } }} />
			</Stack>
			{filteredItems(deliveryList, searchItem, false).map((row) => (
				<Row key={row.id} row={row} />
			))}
		</Box>
	);
}
