import moment from 'moment';

export function processStorageList(results) {
	const filesystem = {};
	const add = (source, target, item) => {
		const elements = source.split('/');
		const element = elements.shift();
		if (!element) return; // blank
		target[element] = target[element] || { __data: item }; // element;
		if (elements.length) {
			target[element] = typeof target[element] === 'object' ? target[element] : {};
			add(elements.join('/'), target[element], item);
		}
	};

	results.forEach((item) => add(item.key, filesystem, item));
	return filesystem;
}

// const rename = (key) => {
// 	let res = key;
// 	switch (key.split('/')[0]) {
// 		case 'profit':
// 			res = key.replace('profit','自檢表');
// 			break;
// 		default:
// 			break;
// 	}
// 	return res;
// }; // check upload folder name as profit not renamae

export function processFolderFileList(response, prefix) {
	let files = [];
	let folders = new Set();
	response.results.forEach((res) => {
		if (res.size && prefix.some((p) => res.key.startsWith(p))) {
			// sometimes files declare a folder with a / within then
			// res.key = rename(res.key);
			let possibleFolder = res.key.split('/').slice(0, -1).join('/');
			// if (possibleFolder.startsWith('profit')) possibleFolder = '自檢表'
			if (possibleFolder) folders.add(possibleFolder);
			res['folder'] = possibleFolder;
			res['file'] = res.key.split('/').pop();
			res['itemType'] = '文件'; //(possibleFolder.split('/').length > 1)? '資料夾':'文件';
			res['folderType'] = false; //(possibleFolder.split('/').length > 1)? true:false;
			files.push(res);
		} else {
			// folders.add(res.key);
			// if (res.key.split('/').length - 1 === 1 && res.key.endsWith('/')) {
			// 	folders.add(res.key);
			// }
		}
	});
	return { files, folders };
}

export function sortFolderFileList(path, tree, item) {
	const parts = path.split('/');
	let currentLevel = tree;
	parts.forEach((part, index) => {
		if (!part) return;
		let existingPart = currentLevel.find((entry) => entry.name === part);

		if (!existingPart) {
			if (index === parts.length - 1 && item.size !== 0) {
				currentLevel.push({ itemType: '文件', folderType: false, name: part, folder: item.key.split('/').slice(0, -1).join('/'), ...item });
			} else {
				existingPart = {
					id: item.key,
					itemType: '資料夾',
					folderType: true,
					name: part,
					files: [],
					breadcrumb: false,
					subindex: currentLevel.length + 1,
					...item,
				};
				currentLevel.push(existingPart);
			}
		}

		if (existingPart && existingPart.files) {
			currentLevel = existingPart.files;
		}
	});
}

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function downloadBlob(blob, filename) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename || 'download';
	const clickHandler = () => {
		setTimeout(() => {
			URL.revokeObjectURL(url);
			a.removeEventListener('click', clickHandler);
		}, 150);
	};
	a.addEventListener('click', clickHandler, false);
	a.click();
	return a;
}

export const flattenTree = (data, parentId = null, parentPath = '') => {
	let result = [];
	data.forEach((item, index) => {
		const id = `${parentId ? parentId + '-' : ''}${index}`;
		const path = `${parentPath ? parentPath + '/' : ''}${item.name}`;
		item.lastModified = moment(item.lastModified).format('YYYY-MM-DD HH:mm:ss');
		item.size = formatBytes(item.size);
		// item.name = rename(item.name);
		result.push({
			id,
			parentId,
			path: path,
			...item,
		});
		if (item.files && item.files.length > 0) {
			result = result.concat(flattenTree(item.files, id, path));
		}
	});
	return result;
};
