import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import XLSX from 'sheetjs-style';
import CircularProgressWithLabel from '../components/CircularProgress';
import { upload } from '../images/images';
import { couponWorker } from '../utils/worker';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function Coupon() {
	const navigate = useNavigate();
	const [fileName, setFileName] = useState('');
	const [outPut, setOutPut] = useState();
	const [progress, setProgress] = useState(0);

	function clear() {
		setFileName('');
		setOutPut();
		setProgress(0);
	}

	async function handleFile(e) {
		setProgress((prevProgress) => (outPut ? 100 : prevProgress + 50));
		const file = e.target.files[0];
		setFileName(file.name);

		couponWorker.postMessage({ file: e.target.files[0] });
		couponWorker.onmessage = function (e) {
			const { jsonData, ws: ogSheet } = e.data;
			let wb = { Sheets: {}, SheetNames: [] };
			wb.Sheets['原始資料'] = ogSheet;
			wb.SheetNames.push('原始資料');
			let stores = [];
			jsonData.forEach((res) => stores.push(res['核銷者']));
			const counts = {};
			for (const num of stores) {
				counts[num] = counts[num] ? counts[num] + 1 : 1;
			}
			const storeCounts = Object.keys(counts).length;
			let times = 0;
			Object.values(counts).map((c) => (times += c));
			const countsArray = Object.entries(counts);
			// prettier-ignore
			const countsData = countsArray.map(([store, count]) => ({ '門店': store, '兌換次數': count }));
			const worksheet = XLSX.utils.json_to_sheet(countsData);

			if (!worksheet['!cols']) worksheet['!cols'] = [];
			worksheet['!cols'][0] = { width: 30 };
			XLSX.utils.sheet_add_aoa(worksheet, [['核銷門店總數']], { origin: 'C1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[storeCounts]], { origin: 'C2' });
			XLSX.utils.sheet_add_aoa(worksheet, [['兌換總次數']], { origin: 'D1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[times]], { origin: 'D2' });

			wb.Sheets['統整'] = worksheet;
			wb.SheetNames.push('統整');
			setOutPut(wb);
			setProgress((prevProgress) => (outPut ? 100 : prevProgress + 50));
		};
	}

	function exportFile() {
		if (outPut) {
			XLSX.writeFile(outPut, fileName.split('_')[2] + '數據.xlsx', { compression: true });
		}
	}

	return (
		<>
			<Grid container>
				<Grid item md={4}>
					<Button align='left' sx={{ display: 'flex', marginBottom: 2 }} variant='contained' startIcon={<ArrowBackIosIcon />} onClick={() => navigate('/others')}>
						返回
					</Button>
				</Grid>
				<Grid item md={8} textAlign={'left'} alignContent={'center'}>
					<Typography variant='h7' fontWeight={'bold'}>
						請先選擇檔案，上傳完成即可下載統整資料
					</Typography>
				</Grid>
			</Grid>
			<Card sx={{ width: '100%', height: 350, border: 'outset', alignContent: 'center' }}>
				<CardContent>
					<img src={upload} alt='upload' width={200} height={100} />
					<Typography py={3}>{fileName}</Typography>
					<Button component='label' variant='outlined' color='inherit' startIcon={<CreateNewFolderIcon />}>
						選擇檔案
						<VisuallyHiddenInput type='file' onChange={(e) => handleFile(e)} />
					</Button>
					&nbsp;
					<Button onClick={() => clear()} component='label' variant='outlined' color='error' startIcon={<ClearIcon />} disabled={!outPut}>
						移除檔案
					</Button>
					<Box>
						<CircularProgressWithLabel value={progress} filename={fileName} />
					</Box>
				</CardContent>
			</Card>
			<Box pt={2}>
				<Button
					color='success'
					variant='contained'
					startIcon={<DownloadIcon />}
					onClick={(e) => {
						exportFile();
					}}
					disabled={!outPut}>
					下載
				</Button>
			</Box>
		</>
	);
}
