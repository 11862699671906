import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export function compareInvoice(pos, rec) {
	let check1 = pos.filter((p) => {
		let result = rec.find((i) => i.InvoiceNo === p.InvoiceNo);
		return !result;
	}); //invoices store are missing
	check1.map((r) => (r['reason'] = '門市未填此筆'));

	let check2 = rec.filter((a) => {
		let result = pos.find((p) => p.InvoiceNo === a.InvoiceNo);
		return (result && result.TotalAmount !== a.TotalAmount) || !result;
	}); //invoices amount don't match or invoice number not included in pos

	check2 = check2.map((r) => {
		let result = pos.find((p) => p.InvoiceNo === r.InvoiceNo);
		return { ...r, reason: result && result.TotalAmount !== r.TotalAmount ? '金額不相符' : 'POS不包含此筆' };
	});

	let diff = check1.concat(check2);
	let difference = diff.filter((d) => d.InvoiceNo && d.TotalAmount !== 0);
	return difference;
}

export function InvoiceTitle() {
	return (
		<Grid container p={2} sx={{ textDecoration: 'underline' }} fontSize={13}>
			<Grid item md={7} xs={5} key={'POSInvoiceNo'}>
				發票號碼
			</Grid>
			<Grid item md={5} xs={7} key={'POSTotalAmount'}>
				發票金額
			</Grid>
		</Grid>
	);
}

export function DifInvoice({ dif }) {
	return (
		<>
			<Typography fontWeight='bold' fontSize={13} color='error' py={2}>
				門市異常發票:
			</Typography>
			<Grid container sx={{ textDecoration: 'underline' }} fontSize={13} pb={1}>
				<Grid item md={6} xs={6}>
					發票號碼
				</Grid>
				<Grid item md={6} xs={6}>
					異常原因
				</Grid>
			</Grid>
			{dif.map((d, index) => (
				<Grid container fontSize={13} pb={0.5} key={'difference' + index}>
					<Grid item md={6} xs={6}>
						{d.InvoiceNo}
					</Grid>
					<Grid item md={6} xs={6}>
						{d.reason}
					</Grid>
				</Grid>
			))}
		</>
	);
}
