/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://5gu5lqmyana6pdmt2lje2pktum.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hsbbze27tjgxtkvcbbtnmd7hva",
    "aws_cognito_identity_pool_id": "ap-southeast-1:9ab7010e-5d44-477f-922c-284577c7a2d7",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_pmcuKSjw8",
    "aws_user_pools_web_client_id": "3vdpbe22t20slip6rcmpcr582s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "ADDRESS",
        "NICKNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "macudirect-storage-main05428-main",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "geo": {
        "amazon_location_service": {
            "region": "ap-southeast-1",
            "search_indices": {
                "items": [
                    "MacuOnePlaceIndex-main"
                ],
                "default": "MacuOnePlaceIndex-main"
            }
        }
    }
};


export default awsmobile;
