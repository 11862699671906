import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';

export default function AccountMenu({ open, userVal, changePW, loggout }) {
	const master = userVal.attributes.zoneinfo === 'master';
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const expand = Boolean(anchorEl);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<IconButton onClick={handleClick} size='small' sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined}>
				<Avatar>
					<PersonIcon />
				</Avatar>
			</IconButton>
			<Menu anchorEl={anchorEl} id='account-menu' open={expand} onClose={handleClose} onClick={handleClose} style={{ top: '48px' }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
				<MenuItem disabled>
					<Typography> 您好, {userVal.username}</Typography>
				</MenuItem>
				<MenuItem onClick={() => navigate('/createUser')} sx={{ display: master ? 'flex' : 'none' }}>
					<PersonAddAltIcon />
					<Typography>&nbsp;建立帳號</Typography>
				</MenuItem>
				<MenuItem onClick={() => navigate('/permission')} sx={{ display: master ? 'flex' : 'none' }}>
					<ManageAccountsOutlinedIcon />
					<Typography>&nbsp;設定權限</Typography>
				</MenuItem>
				<MenuItem onClick={changePW}>
					<LockOutlinedIcon />
					<Typography>&nbsp;變更密碼</Typography>
				</MenuItem>
				<MenuItem onClick={() => navigate('/resetPw')} sx={{ display: master ? 'flex' : 'none' }}>
					<LockResetIcon />
					<Typography>&nbsp;重設密碼</Typography>
				</MenuItem>
				<MenuItem onClick={loggout}>
					<ExitToAppIcon />
					<Typography>&nbsp;登出</Typography>
				</MenuItem>
			</Menu>
		</>
	);
}
