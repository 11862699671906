import { InvokeCommand } from '@aws-sdk/client-lambda';
import lambdaClient from './lambdaClient';
import { structureAddress } from '../shipping/util';

const sendCmd = async (params) => {
	try {
		return await lambdaClient.send(new InvokeCommand(params));
	} catch (err) {
		return err;
	}
};

export const reqDriver = async (list, info, token, valitem, qid, priceitem, maxDelivery, cashpay, i, dType, qfee) => {
	const addressVal = structureAddress(list.dropoff_address);
	const params = {
		FunctionName: 'Create_Delivery',
		Payload: JSON.stringify({
			token: token,
			val: {
				'quote_id': qid,
				'dropoff_payment': cashpay,
				'dropoff_name': list.dropoff_name,
				'dropoff_notes': list.dropoff_notes,
				'dropoff_phone_number': list.dropoff_number.charAt(0) === '0' ? '+886' + list.dropoff_number.slice(1) : '+886' + list.dropoff_number,
				'dropoff_address': addressVal !== null ? addressVal : list.dropoff_address,//list.dropoff_address,
				'deliverable_action': 'deliverable_action_meet_at_door',
				'dropoff_verification': { 'signature': true, 'signature_requirement': { 'enabled': true } },
				'external_store_id': list.pickup_name,
				'external_id': `${list.id}_${(i + 1)}-${new Date().getMilliseconds()}`,
				'manifest_items': valitem,
				'manifest_total_value': priceitem,
				'manifest_reference': list.id.split('-')[2] + '_' + (i + 1),
				'pickup_name': list.pickup_name,
				'pickup_phone_number': '+886' + info.phone.slice(1),
				'pickup_address': list.pickup_address || info.address,
				'pickup_notes': maxDelivery ? '這是一筆大型訂單，將由多位外送夥伴配送，您僅須領取其中一部分的商品' : null,
				'pickup_verification': { 'signature': true, 'signature_requirement': { 'enabled': true } },
				'undeliverable_action': 'return',
				'idempodency_key': `${list.id}_${(i + 1)}-${new Date().getMilliseconds()}`
			},
			id: list.id,
			cID: info.cid,
			drivers: i + 1,
			delType: dType,
			multFee: qfee
		})
	};
	return sendCmd(params);
};

export const reqQuote = async (list, info, token, cashpay, priceitem) => {
	const addressVal = structureAddress(list.dropoff_address);
	const params = {
		FunctionName: 'Quote_Driver',
		Payload: JSON.stringify({
			token: token,
			val: {
				'dropoff_payment': cashpay,
				'dropoff_address': addressVal !== null ? addressVal : list.dropoff_address,//list.dropoff_address || info.address,
				'pickup_address': list.pickup_address,
				'pickup_phone_number': '+886' + info.phone.slice(1),
				'manifest_total_value': priceitem,
				'external_store_id': list.pickup_name
			},
			id: list.id,
			cID: info.cid
		})
	};
	return sendCmd(params);
};

export const saveAttr = async (list) => {
	const params = {
		FunctionName: 'Edit_Order',
		Payload: JSON.stringify({
			val: {
				'dropoff_name': list.dropoff_name,
				'dropoff_number': list.dropoff_number,
				'dropoff_address': list.dropoff_address,
				'dropoff_notes': list.dropoff_notes,
				'pay_state': list.pay_state
			},
			id: list.id
		})
	};
	return sendCmd(params);
};

export const manageDelivery = async (list, info, token, eType) => {
	const params = {
		FunctionName: 'Cancel_Delivery',
		Payload: JSON.stringify({
			token: token,
			id: list.id,
			dID: list.d_id,
			cID: info.cid,
			eType: eType
		})
	};
	return sendCmd(params);
};

export const checkDStatus = async (list, info, token) => {
	const params = {
		FunctionName: 'Driver_Status',
		Payload: JSON.stringify({
			token: token,
			id: list.id,
			dID: list.d_id,
			cID: info.cid
		})
	};
	return sendCmd(params);
};

export const mergeOrder = async (list) => {
	const params = {
		FunctionName: 'Merge_Order',
		Payload: JSON.stringify({
			leadID: list.leadID,
			mergeID: list.mergeID,
			mergeInfo: list.mergeInfo
		})
	};
	return sendCmd(params);
};

export const submitAudit = async (store, sid, date, supervisor, content, wStatus) => {
	const params = {
		FunctionName: 'Audit_Email',
		Payload: JSON.stringify({
			action: 'submit',
			store: store,
			sid: sid,
			date: date,
			supervisor_id: supervisor,
			content: content,
			wStatus: wStatus
		})
	};
	return sendCmd(params);
};

export const updateUserAttr = async (store, userAttr) => {
	const params = {
		FunctionName: 'update_user_attributes',
		Payload: JSON.stringify({
			store: store,
			userAttr: userAttr
		})
	};
	return sendCmd(params);
};

export const creatDirectAcc = async (val) => {
	const params = {
		FunctionName: 'Create_Uber_Direct_Account',
		Payload: JSON.stringify(val)
	};
	return sendCmd(params);
};

export const createDelivery = async (val) => {
	const params = {
		FunctionName: 'Webhooks_MACE',
		Payload: JSON.stringify({
			body: JSON.stringify(val)
		})
	};
	return sendCmd(params);
};
