/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuditForm = /* GraphQL */ `
  query GetAuditForm($id: ID!) {
    getAuditForm(id: $id) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditForms = /* GraphQL */ `
  query ListAuditForms(
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditForms = /* GraphQL */ `
  query SyncAuditForms(
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const auditByMonth = /* GraphQL */ `
  query AuditByMonth(
    $month: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditByMonth(
      month: $month
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditContent = /* GraphQL */ `
  query GetAuditContent($id: ID!) {
    getAuditContent(id: $id) {
      id
      Category
      Partition
      Number
      Content
      Result
      Details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditContents = /* GraphQL */ `
  query ListAuditContents(
    $filter: ModelAuditContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Category
        Partition
        Number
        Content
        Result
        Details
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditContents = /* GraphQL */ `
  query SyncAuditContents(
    $filter: ModelAuditContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Category
        Partition
        Number
        Content
        Result
        Details
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCashForm = /* GraphQL */ `
  query GetCashForm($id: ID!) {
    getCashForm(id: $id) {
      id
      store_id
      store_name
      date
      year
      month
      cloud_revenue
      correction
      daily_total_expense
      daily_total_sales
      uber
      uber_cash
      foodpanda
      panda_go
      linepay
      jko
      mace_online
      ipass
      uu_card
      credit_card
      pxpay
      deposit_date
      deposit_amount
      author
      hours
      receipt
      pos_receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCashForms = /* GraphQL */ `
  query ListCashForms(
    $filter: ModelCashFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        store_id
        store_name
        date
        year
        month
        cloud_revenue
        correction
        daily_total_expense
        daily_total_sales
        uber
        uber_cash
        foodpanda
        panda_go
        linepay
        jko
        mace_online
        ipass
        uu_card
        credit_card
        pxpay
        deposit_date
        deposit_amount
        author
        hours
        receipt
        pos_receipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCashForms = /* GraphQL */ `
  query SyncCashForms(
    $filter: ModelCashFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCashForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        store_id
        store_name
        date
        year
        month
        cloud_revenue
        correction
        daily_total_expense
        daily_total_sales
        uber
        uber_cash
        foodpanda
        panda_go
        linepay
        jko
        mace_online
        ipass
        uu_card
        credit_card
        pxpay
        deposit_date
        deposit_amount
        author
        hours
        receipt
        pos_receipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cashByCreated = /* GraphQL */ `
  query CashByCreated(
    $date: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashByCreated(
      date: $date
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_id
        store_name
        date
        year
        month
        cloud_revenue
        correction
        daily_total_expense
        daily_total_sales
        uber
        uber_cash
        foodpanda
        panda_go
        linepay
        jko
        mace_online
        ipass
        uu_card
        credit_card
        pxpay
        deposit_date
        deposit_amount
        author
        hours
        receipt
        pos_receipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cashByMonth = /* GraphQL */ `
  query CashByMonth(
    $month: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashByMonth(
      month: $month
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_id
        store_name
        date
        year
        month
        cloud_revenue
        correction
        daily_total_expense
        daily_total_sales
        uber
        uber_cash
        foodpanda
        panda_go
        linepay
        jko
        mace_online
        ipass
        uu_card
        credit_card
        pxpay
        deposit_date
        deposit_amount
        author
        hours
        receipt
        pos_receipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStaffInfo = /* GraphQL */ `
  query GetStaffInfo($id: ID!) {
    getStaffInfo(id: $id) {
      id
      userName
      staffName
      storeList
      role
      area
      forcePW
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStaffInfos = /* GraphQL */ `
  query ListStaffInfos(
    $filter: ModelStaffInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        staffName
        storeList
        role
        area
        forcePW
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStaffInfos = /* GraphQL */ `
  query SyncStaffInfos(
    $filter: ModelStaffInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStaffInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userName
        staffName
        storeList
        role
        area
        forcePW
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMacuStore = /* GraphQL */ `
  query GetMacuStore($id: ID!) {
    getMacuStore(id: $id) {
      id
      sid
      cid
      name
      address
      phone
      signature
      staffSign
      supervisor_id
      forcePW
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMacuStores = /* GraphQL */ `
  query ListMacuStores(
    $filter: ModelMacuStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMacuStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sid
        cid
        name
        address
        phone
        signature
        staffSign
        supervisor_id
        forcePW
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMacuStores = /* GraphQL */ `
  query SyncMacuStores(
    $filter: ModelMacuStoreFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMacuStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sid
        cid
        name
        address
        phone
        signature
        staffSign
        supervisor_id
        forcePW
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const storeBySid = /* GraphQL */ `
  query StoreBySid(
    $sid: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMacuStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeBySid(
      sid: $sid
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        cid
        name
        address
        phone
        signature
        staffSign
        supervisor_id
        forcePW
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDirectToken = /* GraphQL */ `
  query GetDirectToken($id: ID!) {
    getDirectToken(id: $id) {
      id
      created
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDirectTokens = /* GraphQL */ `
  query ListDirectTokens(
    $filter: ModelDirectTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        created
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDirectTokens = /* GraphQL */ `
  query SyncDirectTokens(
    $filter: ModelDirectTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDirectTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        created
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeliveryData = /* GraphQL */ `
  query GetDeliveryData($id: ID!) {
    getDeliveryData(id: $id) {
      id
      complete
      created
      deliveryInfo
      delType
      drivers
      dropoff_address
      dropoff_eta
      dropoff_name
      dropoff_number
      dropoff_notes
      fee
      items
      kind
      mace_status
      mace_total
      merge_into
      merge_from
      month
      order_from
      pay_state
      pickup_address
      pickup_eta
      pickup_name
      pickup_number
      quote_expires
      quote_id
      status
      store_id
      updated
      year
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDeliveryData = /* GraphQL */ `
  query ListDeliveryData(
    $filter: ModelDeliveryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        complete
        created
        deliveryInfo
        delType
        drivers
        dropoff_address
        dropoff_eta
        dropoff_name
        dropoff_number
        dropoff_notes
        fee
        items
        kind
        mace_status
        mace_total
        merge_into
        merge_from
        month
        order_from
        pay_state
        pickup_address
        pickup_eta
        pickup_name
        pickup_number
        quote_expires
        quote_id
        status
        store_id
        updated
        year
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeliveryData = /* GraphQL */ `
  query SyncDeliveryData(
    $filter: ModelDeliveryDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliveryData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        complete
        created
        deliveryInfo
        delType
        drivers
        dropoff_address
        dropoff_eta
        dropoff_name
        dropoff_number
        dropoff_notes
        fee
        items
        kind
        mace_status
        mace_total
        merge_into
        merge_from
        month
        order_from
        pay_state
        pickup_address
        pickup_eta
        pickup_name
        pickup_number
        quote_expires
        quote_id
        status
        store_id
        updated
        year
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const deliveryByCreated = /* GraphQL */ `
  query DeliveryByCreated(
    $created: String!
    $store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryByCreated(
      created: $created
      store_id: $store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        complete
        created
        deliveryInfo
        delType
        drivers
        dropoff_address
        dropoff_eta
        dropoff_name
        dropoff_number
        dropoff_notes
        fee
        items
        kind
        mace_status
        mace_total
        merge_into
        merge_from
        month
        order_from
        pay_state
        pickup_address
        pickup_eta
        pickup_name
        pickup_number
        quote_expires
        quote_id
        status
        store_id
        updated
        year
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const deliveryByMonth = /* GraphQL */ `
  query DeliveryByMonth(
    $month: String!
    $yearStore_id: ModelDeliveryDataByMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryByMonth(
      month: $month
      yearStore_id: $yearStore_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        complete
        created
        deliveryInfo
        delType
        drivers
        dropoff_address
        dropoff_eta
        dropoff_name
        dropoff_number
        dropoff_notes
        fee
        items
        kind
        mace_status
        mace_total
        merge_into
        merge_from
        month
        order_from
        pay_state
        pickup_address
        pickup_eta
        pickup_name
        pickup_number
        quote_expires
        quote_id
        status
        store_id
        updated
        year
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const deliveryByStatus = /* GraphQL */ `
  query DeliveryByStatus(
    $status: String!
    $createdStore_id: ModelDeliveryDataByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryByStatus(
      status: $status
      createdStore_id: $createdStore_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        complete
        created
        deliveryInfo
        delType
        drivers
        dropoff_address
        dropoff_eta
        dropoff_name
        dropoff_number
        dropoff_notes
        fee
        items
        kind
        mace_status
        mace_total
        merge_into
        merge_from
        month
        order_from
        pay_state
        pickup_address
        pickup_eta
        pickup_name
        pickup_number
        quote_expires
        quote_id
        status
        store_id
        updated
        year
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWeather = /* GraphQL */ `
  query GetWeather($id: ID!) {
    getWeather(id: $id) {
      id
      sid
      store_name
      date
      year
      month
      current
      forecast
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWeathers = /* GraphQL */ `
  query ListWeathers(
    $filter: ModelWeatherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeathers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sid
        store_name
        date
        year
        month
        current
        forecast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWeathers = /* GraphQL */ `
  query SyncWeathers(
    $filter: ModelWeatherFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWeathers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sid
        store_name
        date
        year
        month
        current
        forecast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const weatherBySid = /* GraphQL */ `
  query WeatherBySid(
    $sid: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWeatherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    weatherBySid(
      sid: $sid
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        store_name
        date
        year
        month
        current
        forecast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const weatherByDate = /* GraphQL */ `
  query WeatherByDate(
    $date: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWeatherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    weatherByDate(
      date: $date
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        store_name
        date
        year
        month
        current
        forecast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const weatherByMonth = /* GraphQL */ `
  query WeatherByMonth(
    $month: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWeatherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    weatherByMonth(
      month: $month
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        store_name
        date
        year
        month
        current
        forecast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      Route
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Route
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Route
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      Section
      Type
      Title
      Content
      StartDate
      EndDate
      Link
      Attachment
      Read
      Creator
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Section
        Type
        Title
        Content
        StartDate
        EndDate
        Link
        Attachment
        Read
        Creator
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAnnouncements = /* GraphQL */ `
  query SyncAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Section
        Type
        Title
        Content
        StartDate
        EndDate
        Link
        Attachment
        Read
        Creator
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      beta
      manual
      route
      section
      permission
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        beta
        manual
        route
        section
        permission
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPermissions = /* GraphQL */ `
  query SyncPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        beta
        manual
        route
        section
        permission
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
