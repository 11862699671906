import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMergeDialog } from '../redux/deliverySlice';
import { mergeOrder } from '../utils/lambda';
import Dialog from '../components/Dialog';
import { Checkmark, Crossmark } from '../components/Circlemark';
import { Utf8ArrayToStr } from '../utils/requestDelivery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MergeIcon from '@mui/icons-material/Merge';

const steps = ['選擇訂單', '合併預覽', '合併完成'];

export default function Mergecard({ list, handleQuery }) {
	const dispatch = useDispatch();
	const dialogState = useSelector((state) => state.shipping.mergeDialog);
	const [dialog, setDialog] = useState(false);
	const [checked, setChecked] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [notSame, setNotSame] = useState(1);
	const [radioVal, setRadioVal] = useState(0);
	const [mergeCheck, setMergeCheck] = useState(false);
	const [merging, setMerging] = useState(false);
	const [payState, setPayState] = useState(true);
	const [curPay, setCurPay] = useState('');
	const [mergeRes, setMergeRes] = useState('');

	const setInit = (done) => {
		setActiveStep(0);
		setMergeCheck(false);
		setMerging(false);
		setNotSame(1);
		setRadioVal(0);
		setPayState(false);
		done && setChecked([]);
		done && setCurPay('');
		setMergeRes('');
	};

	useEffect(() => {
		setDialog(dialogState);
		setTimeout(() => setInit(true), 200);
	}, [dialogState]);

	const handleDialog = () => dispatch(updateMergeDialog(!dialogState));

	const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

	const handleBack = () => setInit(false);

	const triggerMerge = async () => {
		const leadID = checked[radioVal].id;
		const mergeTidy = checked.filter((item) => item.id !== leadID);
		const mergeInfo = {
			items: checked[radioVal].items,
			mace_total: checked[radioVal].mace_total,
			quote_id: null,
			quote_exipres: null,
			fee: 0,
		};
		let mergeID = '';
		let res = '合併錯誤';
		mergeTidy.map((val, index) => {
			mergeInfo.items = mergeInfo.items.concat(val.items);
			mergeInfo.mace_total += parseInt(val.mace_total);
			mergeID += val.id;
			if (index !== mergeTidy.length - 1) mergeID += ',';
			return mergeInfo;
		});
		try {
			res = await mergeOrder({ leadID, mergeID, mergeInfo });
			res = Utf8ArrayToStr(res.Payload);
			handleQuery();
		} catch (err) {
			res = '合併錯誤';
			console.log(err);
		}
		setMergeRes(res);
	};

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
		const sortOrder = newChecked.map((item) => item.dropoff_name + item.dropoff_address);
		const isOrderSame = new Set(sortOrder);
		setNotSame(isOrderSame.size);
		const sortPay = newChecked.map((item) => item.pay_state);
		const isPaySame = new Set(sortPay);
		setPayState(isPaySame.size === 1 ? false : true);
		if (isPaySame.size === 1) {
			setCurPay([...isPaySame][0]);
		} else if (isPaySame.size === 0) {
			setCurPay('');
		}
	};

	const handleMergeCheck = () => setMergeCheck(!mergeCheck);

	const handleRadioChange = (event) => setRadioVal(event.target.value);

	const handleSubmitMerge = async () => {
		if (!merging) {
			setMerging(true);
			await triggerMerge();
			handleNext();
			setMerging(false);
		}
	};

	const StepBtn = () => (
		<Box>
			<Chip icon={<WarningAmberIcon />} sx={{ backgroundColor: '#ffda00', color: '#000', display: notSame <= 1 && 'none' }} label='訂購人或地址等資訊不一致' />
			<FormControlLabel control={<Checkbox checked={mergeCheck} onChange={handleMergeCheck} name='confirm' />} label='確認合併' sx={{ display: activeStep === 1 ? 'block' : 'none' }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Box sx={{ m: 1, position: 'relative' }}>
					<Button variant='outlined' color='inherit' sx={{ display: activeStep === 0 && 'none' }} onClick={handleBack} disabled={merging}>
						上一步
					</Button>
				</Box>
				<Box sx={{ flex: '1 1 auto' }} />
				{activeStep === 0 && (
					<Button variant='outlined' onClick={() => handleNext()} disabled={checked.length <= 1 || payState}>
						下一步
					</Button>
				)}
				{activeStep === 1 && (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box sx={{ m: 1, position: 'relative' }}>
							<Button variant='outlined' startIcon={<MergeIcon />} disabled={merging || !mergeCheck} onClick={handleSubmitMerge}>
								合併
							</Button>
							{merging && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />}
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);

	const MergeContent = () => {
		const sorted = [...list].sort(function (a, b) {
			if (a.dropoff_address > b.dropoff_address) return -1;
			if (a.dropoff_address < b.dropoff_address) return 1;
			return 0;
		});
		return (
			<List>
				{sorted.map((value) => {
					const labelId = `checkbox-list-label-${value.id}`;
					return (
						<ListItem key={value.id} disablePadding>
							<ListItemButton selected={checked.indexOf(value) !== -1} role={undefined} onClick={handleToggle(value)} disabled={value.pay_state !== curPay && curPay !== ''} dense>
								<ListItemIcon>
									<Checkbox edge='start' checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={
										<Typography variant='body2' component='div'>
											{value.id} <Chip size='small' label={value.pay_state} variant='outlined' color={value.pay_state === '已支付' ? 'success' : 'error'} />
										</Typography>
									}
									secondary={`${value.dropoff_name} - ${value.dropoff_address}`}
								/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		);
	};

	const ReviewMerge = () => {
		let products = '';
		let qty = 0;
		let final = 0;
		checked.map((value, i) => {
			products += value.items.map((item) => {
				final += parseInt(item.split('@')[2]);
				qty += parseInt(item.split('@')[1]);
				return `${item.split('@')[0]} x ${item.split('@')[1]}`;
			});
			return (products += i + 1 < checked.length ? ', ' : '');
		});
		return (
			<Box>
				<List>
					<FormControl sx={{ padding: 2, display: 'flex' }}>
						<Typography align='center' variant='body' gutterBottom>
							選擇合併至
						</Typography>
						<RadioGroup value={radioVal} onChange={handleRadioChange}>
							{checked.map((value, index) => (
								<ListItem key={value.id} disablePadding>
									<ListItemIcon>
										<FormControlLabel
											value={index}
											control={<Radio />}
											label={
												<ListItemText
													primary={
														<Typography variant='body2' color={'black'}>
															{value.id}
														</Typography>
													}
													secondary={`${value.dropoff_name} - ${value.dropoff_address}`}
												/>
											}
										/>
									</ListItemIcon>
								</ListItem>
							))}
						</RadioGroup>
					</FormControl>
				</List>
				<Divider />
				<Box sx={{ padding: 2, display: 'flex' }}>
					<List dense>
						<Typography align='center' variant='body' gutterBottom>
							合併結果預覽
						</Typography>
						<ListItem key={'merger_id'} disablePadding>
							<ListItemText primary='單號：' secondary={checked[radioVal].id} />
						</ListItem>
						<ListItem key={'merge_name'} disablePadding>
							<ListItemText primary='訂購人姓名：' secondary={checked[radioVal].dropoff_name} />
						</ListItem>
						<ListItem key={'merge_phone'} disablePadding>
							<ListItemText primary='電話：' secondary={checked[radioVal].dropoff_number} />
						</ListItem>
						<ListItem key={'merge_address'} disablePadding>
							<ListItemText primary='運送地址：' secondary={checked[radioVal].dropoff_address} />
						</ListItem>
						<ListItem key={'merge_items'} disablePadding>
							<ListItemText primary={`品項 (${qty})：`} secondary={products} />
						</ListItem>
						<ListItem key={'merge_final'} disablePadding>
							<ListItemText primary={`總計 (${checked[radioVal].pay_state})：`} secondary={`$${final.toLocaleString()}`} />
						</ListItem>
					</List>
				</Box>
			</Box>
		);
	};

	const MergeDone = () => (
		<Box sx={{ padding: 2, display: 'grid' }}>
			<Typography align='center' variant='body' gutterBottom>
				{mergeRes}
			</Typography>
			{mergeRes === '合併完成' ? <Checkmark size='large' /> : <Crossmark size='large' />}
		</Box>
	);

	const StepCard = () => (
		<>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && (
				<React.Fragment>
					<MergeContent />
					<StepBtn />
				</React.Fragment>
			)}
			{activeStep === 1 && (
				<React.Fragment>
					<ReviewMerge />
					<StepBtn />
				</React.Fragment>
			)}
			{activeStep === 2 && (
				<React.Fragment>
					<MergeDone />
				</React.Fragment>
			)}
		</>
	);

	return <Dialog dialog={dialog} title={'合併追加訂單'} content={StepCard()} handleClose={handleDialog} />;
}
