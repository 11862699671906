// import { register } from 'swiper/element/bundle';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-creative';
// register();

export const SwiperSlides = ({ perView, breakpoints, space, slides }) => (
	<Swiper slidesPerView={perView} spaceBetween={space} breakpoints={breakpoints} grabCursor={true} freeMode={true} modules={[FreeMode]}>
		{slides.map((m, idx) => (
			<SwiperSlide key={idx + 'mobile'}>{m}</SwiperSlide>
		))}
	</Swiper>
);
