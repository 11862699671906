import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Copyright } from '../utils/extra';
import Dialog from '../components/Dialog';
import Announcement from '../announcement/announcement';
import { BackToTop } from '../components/Scrolltop';
import Linkbutton from '../components/Linkbutton';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function AppContent({ hideToolPages, location, userLvl, getRoutes, routes, route, openDialog, dialogClose, setAllAnnouncement, allAnnouncement, setUpdateList, updateList }) {
	return (
		<Box
			component='main'
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
				flexGrow: 1,
				height: '100vh',
				overflow: 'auto',
			}}>
			<div id='back-to-top-anchor' />
			<BackToTop />
			<Toolbar />
			<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
				{!hideToolPages.includes(location.pathname) && <Linkbutton position='right' variant='text' color='primary' icon={<MenuBookIcon />} link={location.pathname !== '/' && userLvl ? userLvl.filter((item) => item.route === location.pathname)[0].manual : ''} text='查看手冊' />}
				<Routes>
					{getRoutes(routes, false)}
					<Route path='*' element={<Navigate to={route} />} />
				</Routes>
				<Copyright sx={{ pt: 4 }} />
				<Dialog dialog={openDialog} title={`公告`} content={<Announcement handleClose={dialogClose} setAllAnnouncement={setAllAnnouncement} allAnnouncement={allAnnouncement} setUpdateList={setUpdateList} updateList={updateList} />} cancel={`退出`} handleClose={dialogClose} showBtn={true} disable={false} fullWidth={true} showSub={false} />
			</Container>
		</Box>
	);
}
