import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Storage } from 'aws-amplify';
import { downloadBlob } from '../utils/file';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CampaignIcon from '@mui/icons-material/Campaign';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AddIcon from '@mui/icons-material/Add';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';

const Announcement = ({ allAnnouncement, setAllAnnouncement, updateList, setUpdateList, handleClose }) => {
	const userLogged = useSelector((state) => state.users.value);
	const hq = userLogged.attributes.nickname.split(',').includes('HQ');
	const userName = userLogged.username;
	const [list, setList] = useState([]);
	const navigate = useNavigate();
	const today = moment().format('L');
	const level = useSelector((state) => state.users.userRoutes);
	const link = useSelector((state) => state.users.userSettings);
	const types = {
		功能: { icon: <DeviceHubIcon />, color: 'primary' },
		公告: { icon: <CampaignIcon />, color: 'success' },
	};
	const [checked, setChecked] = useState(false);
	const handleChange = (event) => {
		setChecked(event.target.checked);
		if (event.target.checked) {
			setList(allAnnouncement);
		} else {
			setList((prev) => {
				let newlist = prev.filter((f) => !f.Read.includes(userName) && f.StartDate <= today);
				setList(newlist);
				return prev;
			});
		}
	};

	useEffect(() => {
		filterAnnouncement();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterAnnouncement = async () => {
		let unread = allAnnouncement.filter((a) => !a.Read.includes(userName) && a.StartDate <= today);
		setList(unread);
	};

	const downloadAttachment = async (i) => {
		const result = await Storage.get(i, { download: true });
		downloadBlob(result.Body, i);
	};

	const add = () => {
		navigate('/announcement', { state: { list: allAnnouncement, item: null, name: '新增公告' } });
		handleClose();
	};

	const goEdit = (item) => {
		navigate('/announcement', { state: { list: allAnnouncement, item: item, name: '修改公告' } });
		handleClose();
	};

	const handleRead = async (e, f) => {
		let newAll = [...allAnnouncement];
		let newUpdateList = [...updateList];
		if (!updateList.includes(f.id)) {
			newUpdateList.push(f.id);
		}
		setUpdateList(newUpdateList);
		const index = newAll.findIndex((item) => item.id === f.id);
		if (e.target.checked) {
			newAll[index].Read.push(userName);
			setAllAnnouncement(newAll);
			setList(newAll);
			if (!checked) {
				setList((prev) => {
					let newlist = prev.filter((f) => !f.Read.includes(userName) && f.StartDate <= today);
					setList(newlist);
					return prev;
				});
			}
		} else {
			let read = newAll[index].Read;
			const storeIndex = read.findIndex((i) => i === userName);
			read.splice(storeIndex, 1);
			setAllAnnouncement(newAll);
			setList(newAll);
		}
	};

	return (
		<Box>
			<Box textAlign={'right'} fontSize={14}>
				查看全部
				<Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
				<Button startIcon={<AddIcon />} size='small' variant='outlined' color='inherit' onClick={add} style={{ display: hq ? undefined : 'none' }}>
					新增公告
				</Button>
			</Box>
			{list.length === 0 ? (
				<Box textAlign={'center'}>
					<Typography fontSize={15}>無未讀訊息</Typography>
					<img src={'../../../noMessage.png'} alt='no unread message' style={{ width: 200, height: 150 }} />
				</Box>
			) : (
				list.map((f, index) => (
					<Box key={f.id + index} sx={{ display: level.includes(f.Section) || f.Section === '全部' ? undefined : 'none' }}>
						<Grid container justifyContent='space-between' display={'flex'}>
							<Grid item>
								<Stack direction={'row'} spacing={1}>
									<Chip icon={types[f.Type] ? types[f.Type].icon : <CampaignIcon />} label={f.Type} size='small' color={types[f.Type] ? types[f.Type].color : 'success'} />
									<Typography fontSize={13} color={'text.secondary'} pt={0.5}>
										{f.Title}
									</Typography>
								</Stack>
							</Grid>
							<Grid item>
								<Stack direction={'row'} spacing={1}>
									<Typography fontSize={12} pt={1} color={'error'} fontWeight={'bold'} display={f.StartDate > today ? undefined : 'none'}>
										尚未發布
									</Typography>
									<Typography fontSize={12} pt={1}>
										已讀
									</Typography>
									<Switch checked={f.Read.includes(userName)} onChange={(e) => handleRead(e, f)} inputProps={{ 'aria-label': 'controlled' }} color='success' />
								</Stack>
							</Grid>
						</Grid>
						<Typography variant='body2' color={'inherit'} pl={1} pt={1}>
							{f.Section === '全部' ? f.Content : `${[f.Section]}更新囉! ${f.Content}`}
						</Typography>
						<Typography variant='body2' color={'inherit'} pl={0.4}>
							{!f.Link
								? null
								: f.Link.map((i, index) => (
										<Button size='small' key={index} endIcon={<OpenInNewIcon style={{ color: 'black', fontSize: 'small' }} />}>
											<Link to={i} target='_blank' style={{ color: 'black', fontSize: 12 }}>
												連結{index + 1}
											</Link>
										</Button>
								  ))}
							{!f.Attachment
								? null
								: f.Attachment.map((i, index) => (
										<IconButton size='small' key={index} onClick={(e) => downloadAttachment(i)} sx={{ fontSize: 12, color: '#3030308a' }}>
											附件{index + 1}
											<AttachmentIcon />
										</IconButton>
								  ))}
							{f.Section === '全部' ? null : (
								<Button size='small' endIcon={<ArrowOutwardIcon style={{ color: 'black', fontSize: 'small' }} />}>
									<Link to={link.filter((item) => item.section === f.Section)[0].manual} target='_blank' style={{ color: 'grey', fontSize: 12 }}>
										點此查看手冊
									</Link>
								</Button>
							)}
						</Typography>
						<Typography fontSize={10} color={'inherit'} textAlign={'right'} pb={0.5}>
							<i>發布時間: {f.StartDate}</i>
							<IconButton aria-label='add' size='small' onClick={(e) => goEdit(f)} style={{ display: f.Creator === userName ? undefined : 'none' }}>
								<EditIcon />
							</IconButton>
						</Typography>
						<Divider></Divider>
						<br />
					</Box>
				))
			)}
		</Box>
	);
};
export default Announcement;
