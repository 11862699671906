const DataGridTw = {
	toolbarExport: '匯出資料',
	toolbarExportCSV: '匯出 CSV',
	toolbarExportPrint: '列印資料',
	noRowsLabel: '沒有資料',
	noResultsOverlayLabel: '沒有結果',
	columnMenuLabel: '選單',
	columnMenuShowColumns: '顯示欄位',
	columnMenuManageColumns: '管理欄位',
	columnMenuFilter: '篩選器',
	columnMenuHideColumn: '隱藏',
	columnMenuUnsort: '預設排序',
	columnMenuSortAsc: '升序',
	columnMenuSortDesc: '降序',
	toolbarQuickFilterPlaceholder: '搜尋…',
	toolbarQuickFilterLabel: '搜尋',
	toolbarQuickFilterDeleteIconLabel: '清除',
	toolbarFilters: '篩選器',
	toolbarFiltersLabel: '顯示篩選器',
	toolbarFiltersTooltipHide: '隱藏篩選器',
	toolbarFiltersTooltipShow: '顯示篩選器',
	toolbarFiltersTooltipActive: (count) => `${count} 個篩選器`,
	toolbarColumns: '欄位',
	toolbarColumnsLabel: '選擇欄位',
	columnsPanelTextFieldLabel: '欄位搜尋',
	columnsPanelTextFieldPlaceholder: '欄位名稱',
	columnsPanelDragIconLabel: '排序欄位',
	columnsPanelShowAllButton: '顯示所有',
	columnsPanelHideAllButton: '隱藏所有',
	filterPanelAddFilter: '增加篩選器',
	filterPanelRemoveAll: '清除所有',
	filterPanelDeleteIconLabel: '刪除',
	filterPanelLogicOperator: '邏輯運算子',
	filterPanelOperator: '運算子',
	filterPanelOperatorAnd: '且',
	filterPanelOperatorOr: '或',
	filterPanelColumns: '欄位',
	filterPanelInputLabel: '值',
	filterPanelInputPlaceholder: '篩選值',
	filterOperatorContains: '包含',
	filterOperatorEquals: '等於',
	filterOperatorStartsWith: '以...開頭',
	filterOperatorEndsWith: '以...結束',
	filterOperatorIs: '為',
	filterOperatorNot: '不為',
	filterOperatorAfter: '...之後',
	filterOperatorOnOrAfter: '...(含)之後',
	filterOperatorBefore: '...之前',
	filterOperatorOnOrBefore: '...(含)之前',
	filterOperatorIsEmpty: '為空',
	filterOperatorIsNotEmpty: '不為空',
	filterOperatorIsAnyOf: '是其中之一',
	'filterOperator=': '=',
	'filterOperator!=': '!=',
	'filterOperator>': '>',
	'filterOperator>=': '>=',
	'filterOperator<': '<',
	'filterOperator<=': '<=',
	headerFilterOperatorContains: '包含',
	headerFilterOperatorEquals: '等於',
	headerFilterOperatorStartsWith: '以...開頭',
	headerFilterOperatorEndsWith: '以...結束',
	headerFilterOperatorIs: '為',
	headerFilterOperatorNot: '不為',
	headerFilterOperatorAfter: '...之後',
	headerFilterOperatorOnOrAfter: '...(含)之後',
	headerFilterOperatorBefore: '...之前',
	headerFilterOperatorOnOrBefore: '...(含)之前',
	headerFilterOperatorIsEmpty: '為空',
	headerFilterOperatorIsNotEmpty: '不為空',
	headerFilterOperatorIsAnyOf: '是其中之一',
	'headerFilterOperator=': '等於',
	'headerFilterOperator!=': '不等於',
	'headerFilterOperator>': '大於',
	'headerFilterOperator>=': '大於或等於',
	'headerFilterOperator<': '小於',
	'headerFilterOperator<=': '小於或等於',
	columnHeaderFiltersTooltipActive: (count) => `${count} 個篩選器`,
	columnHeaderFiltersLabel: '顯示篩選器',
	columnHeaderSortIconLabel: '排序',
	footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,
	footerRowSelected: (count) => `已選取 ${count.toLocaleString()} 個`,
	footerTotalRows: '總數:',
};

export default DataGridTw;
