// import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { question, coupon, directAcc } from '../images/images';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function Others() {
	const navigate = useNavigate();
	const tools = [
		{ title: '票券兌換統計', image: coupon, route: '/others/coupon' },
		{ title: '建立 Uber Direct 帳號', image: directAcc, route: '/others/direct' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' },
	];
	return (
		<Box>
			<Grid container>
				{tools.map((t, index) => (
					<Grid item md={4} key={t.title + index}>
						<Card sx={{ width: 300, height: 150, alignContent: 'center', margin: 2 }}>
							<CardContent>
								<Button color='inherit' onClick={() => navigate(t.route)} style={{ position: 'relative', overflow: 'hidden', height: '9vh', width: '100%' }} disabled={t.title === 'pending'}>
									<Stack direction={'column'} justifyContent={'center'} alignItems={'center'} style={{ position: 'relative', zIndex: 1 }}>
										<Typography fontWeight={'bold'} bgcolor={'#fff9'}>
											{t.title === 'pending' ? '' : t.title}
										</Typography>
									</Stack>
									<img src={t.image} alt={t.title} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0.6 }} />
								</Button>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}
