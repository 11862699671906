import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/zh-tw';
import { Storage } from 'aws-amplify';
import { createAnnouncement, updateAnnouncement } from '../utils/requestDelivery';
import Dialog from '../components/Dialog';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function Announcement() {
	const location = useLocation();
	const navigate = useNavigate();
	const list = location.state.list;
	const itemVal = location.state.item;
	const [item, setItem] = useState(itemVal);
	const today = moment().format('L');
	const userlogged = useSelector((state) => state.users.value);
	const userName = userlogged.username;
	const link = useSelector((state) => state.users.userSettings);
	const sections = Array.from(new Set(link.map((l) => l.section)));
	const types = Array.from(new Set(list.map((l) => l.Type)));
	const [file, setFile] = useState([]);
	const [now, setNow] = useState(moment().format('YYMMDDHHMMSS'));
	const ogFile = item && item.Attachment ? item.Attachment : [];
	const [dialog, setDialog] = useState(false);
	const handleDialog = () => setDialog(!dialog);
	const ogInput = (item) => ({
		Title: item === null ? '' : item.Title,
		Type: item === null ? types[0] : item.Type,
		Section: item === null ? sections[0] : item.Section,
		Content: item === null ? '' : item.Content,
		StartDate: item === null ? today : item.StartDate,
		EndDate: item === null ? moment().add(7, 'day').format('L') : item.EndDate,
		Link: item === null || (item && item.Link.length === 0) ? [''] : item.Link,
		Attachment: item === null || (item && item.Attachment.length === 0) ? [''] : item.Attachment,
		Read: item === null ? [] : item.Read,
		Id: item === null ? '' : item.id,
		Version: item === null ? '' : item._version,
	});
	const [input, setInput] = useState(ogInput(item));

	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'absolute',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 1,
	});

	useEffect(() => {
		setNow(moment().format('YYMMDDHHMMSS'));
		const newItem = location.state.item;
		setItem(newItem);
		setInput(ogInput(newItem));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemVal]);

	async function getInput(e, key, index) {
		let oVal = { ...input };
		let inputVal;
		let files = [...file];
		switch (key) {
			case 'Link':
				inputVal = e.target.value;
				oVal[key][index] = inputVal;
				break;
			case 'Attachment':
				let file = e.target.files[0];
				inputVal = file ? 'announcement/' + file.name.split('.')[0] + now + '.' + file.name.split('.')[1] : '';
				oVal['Attachment'][index] = inputVal;
				break;
			case 'addLink':
				oVal.Link.push('');
				break;
			case 'removeLink':
				oVal.Link.splice(index, 1);
				if (oVal.Link.length === 0) {
					oVal.Link.push('');
				}
				break;
			case 'addAttachment':
				oVal.Attachment.push('');
				break;
			case 'removeAttachment':
				handleDialog();
				if (ogFile.length > 0) {
					await Storage.remove(oVal.Attachment[index]); //only delete when ofile exists
				}
				oVal.Attachment.splice(index, 1);
				files.splice(index, 1);
				if (oVal.Attachment.length === 0) {
					oVal.Attachment.push('');
				}
				setFile(files);
				break;
			case 'StartDate':
			case 'EndDate':
				if (e) {
					inputVal = moment(new Date(e._d)).format('L');
					oVal[key] = inputVal || '';
				} else {
					oVal[key] = '';
				}
				break;
			default:
				inputVal = e.target.value || '';
				oVal[key] = inputVal;
				break;
		}
		setInput(oVal);
	}

	async function hanldeSubmit() {
		let newData = input.Id === '' ? true : false;
		let attach = input.Attachment.filter((f) => f !== '');
		let link = input.Link.filter((l) => l !== '');
		let variable = {
			Title: input.Title,
			Type: input.Type,
			Section: input.Section,
			Content: input.Content,
			StartDate: input.StartDate,
			EndDate: input.EndDate,
			Link: link,
			Attachment: attach,
			Read: input.Read,
		};
		if (file) {
			await upload();
		}
		if (newData) {
			variable.Creator = userName;
			try {
				await createAnnouncement(variable);
			} catch (error) {
				console.log('error', error);
			}
		} else {
			variable.id = input.Id;
			variable._version = input.Version;
			variable.Read = [];
			try {
				await updateAnnouncement(variable);
			} catch (error) {
				console.log('error', error);
			}
		}
		navigate('/audit', { state: { name: '自檢表' } });
	}

	const selectedFile = (e, key, index) => {
		let files = [...file];
		files.push(e.target.files[0]);
		setFile(files);
		getInput(e, key, index);
	};

	const upload = async () => file.map(async (f) => await Storage.put(`announcement/${f.name.split('.')[0]}${now}.${f.name.split('.')[1]}`, f));

	const discard = () => navigate('/');

	return (
		<Box sx={{ padding: { xs: 1, md: 3 }, backgroundColor: '#e0dfdf' }}>
			<Paper elevation={5}>
				<Grid container>
					<Grid item md={4} padding={6} textAlign={'left'} fontSize={14} fontWeight={'bold'}>
						標題
						<TextField sx={{ pt: 2, width: 250 }} size='small' defaultValue={input.Title} onChange={(e) => getInput(e, 'Title', null)} />
					</Grid>
					<Grid item md={4} padding={6} textAlign={'left'} fontSize={14} fontWeight={'bold'}>
						類型
						<Autocomplete disablePortal id='combo-box-demo' options={types} value={input.Type} sx={{ pt: 2, width: 250 }} size='small' onBlur={(e) => getInput(e, 'Type', null)} renderInput={(params) => <TextField {...params} />} />
					</Grid>
					<Grid item md={4} padding={6} textAlign={'left'} fontSize={14} fontWeight={'bold'}>
						顯示頁面
						<FormControl fullWidth size='small' sx={{ mt: 2, width: 250 }}>
							<Select labelId='demo-simple-select-label' id='demo-simple-select' value={input.Section} onChange={(e) => getInput(e, 'Section', null)}>
								{sections.map((s, index) => (
									<MenuItem value={s} key={index}>
										{s}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={12} px={6} textAlign={'left'} fontSize={14} fontWeight={'bold'}>
						內容
						<TextField sx={{ pt: 2 }} fullWidth size='large' multiline rows={4} defaultValue={input.Content} onChange={(e) => getInput(e, 'Content', null)} />
					</Grid>
					<Grid item md={6} padding={6} textAlign={'left'}>
						<Typography fontSize={14} fontWeight={'bold'} pb={2}>
							公告區間
						</Typography>
						<DatePicker views={['year', 'month', 'day']} format='YYYY MMM DD' label={'開始'} localeText={{ datePickerToolbarTitle: '開始日期' }} slotProps={{ toolbar: { toolbarFormat: 'YYYY MMM DD', hidden: false } }} sx={{ backgroundColor: 'white', width: { md: 180, xs: 200 }, pr: { md: 1, xs: 0 } }} dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')} value={moment(input.StartDate)} disablePast onChange={(e) => getInput(e, 'StartDate', null)} />
						<DatePicker views={['year', 'month', 'day']} format='YYYY MMM DD' label={'結束'} localeText={{ datePickerToolbarTitle: '結束日期' }} slotProps={{ toolbar: { toolbarFormat: 'YYYY MMM DD', hidden: false } }} sx={{ backgroundColor: 'white', width: { md: 180, xs: 200 }, pt: { xs: 2, md: 0 } }} dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')} value={moment(input.EndDate)} minDate={moment(input.StartDate)} onChange={(e) => getInput(e, 'EndDate', null)} />
					</Grid>
					{input.Link.map((m, index) => (
						<Grid item md={6} padding={6} textAlign={'left'} key={index}>
							<Typography fontWeight={'bold'} fontSize={14}>
								連結{index + 1}.
							</Typography>
							<TextField defaultValue={m} sx={{ width: 250, pt: 2 }} size='small' multiline rows={3} onChange={(e) => getInput(e, 'Link', index)} />
							<IconButton aria-label='add' sx={{ pt: 3 }} onClick={(e) => getInput(e, 'removeLink', index)}>
								<CancelIcon />
							</IconButton>
							<IconButton onClick={(e) => getInput(e, 'addLink', index)} aria-label='add' sx={{ pt: 3, display: input.Link.length === index + 1 ? undefined : 'none' }} disabled={!input.Link[index]}>
								<AddCircleIcon />
							</IconButton>
						</Grid>
					))}
					{input.Attachment.map((m, index) => (
						<Grid item md={6} padding={6} textAlign={'left'} key={index}>
							<Typography fontWeight={'bold'} fontSize={14}>
								附件{index + 1}.
							</Typography>
							<TextField variant='standard' maxRows={2} sx={{ width: 250, pt: 2 }} size='small' defaultValue={m === '' ? '' : m.split('/')[1]} disabled />
							<Tooltip title='選擇檔案'>
								<IconButton aria-label='add' sx={{ pt: 3 }} component='label'>
									<AttachFileIcon />
									<VisuallyHiddenInput type='file' onChange={(e) => selectedFile(e, 'Attachment', index)} />
								</IconButton>
							</Tooltip>
							<IconButton aria-label='add' sx={{ pt: 3 }} onClick={handleDialog}>
								<CancelIcon />
							</IconButton>
							<Dialog dialog={dialog} title={'移除將無法復原'} content={'確定移除附件?'} cancel={'返回'} submit={'確定'} handleClose={handleDialog} handleSubmit={(e) => getInput(e, 'removeAttachment', index)} showBtn={true} disable={false} fullWidth={false} showSub={true} />
							<IconButton onClick={(e) => getInput(e, 'addAttachment', index)} aria-label='add' sx={{ pt: 3, display: input.Attachment.length === index + 1 ? undefined : 'none' }} disabled={!input.Attachment[index]}>
								<AddCircleIcon />
							</IconButton>
						</Grid>
					))}
				</Grid>
				<Stack pb={2} direction={'row'} spacing={2} display={'block'}>
					<Button startIcon={<FileUploadIcon />} variant='contained' style={{ backgroundColor: '#39be40' }} onClick={(e) => hanldeSubmit()}>
						發布
					</Button>
					<Button startIcon={<DeleteForeverIcon />} variant='contained' style={{ backgroundColor: '#dc4848' }} onClick={discard}>
						放棄
					</Button>
				</Stack>
			</Paper>
		</Box>
	);
}
