import moment from 'moment';
import { getIconColor, iconString } from '../utils/weather';
import { SwiperSlides } from '../components/SwiperSlides';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import TodayIcon from '@mui/icons-material/Today';
import ImportExportIcon from '@mui/icons-material/ImportExport';

export default function WeatherTop({ weatherData, curSid }) {
	const weather = weatherData.filter((i) => i.sid === curSid);
	const CurContent = ({ padding, icon, header, val, img }) => (
		<ListItem sx={{ height: '90%', padding: { md: padding[0], xs: padding[1] } }} key={val + header + Math.random()}>
			<ListItemAvatar>
				<Avatar sx={{ height: 30, width: 30 }} variant='round'>
					{icon}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={<Typography fontSize={{ xs: 12, md: 14 }}>{header}</Typography>}
				secondary={
					<Typography fontSize={{ xs: 12, md: 14 }} color='text.secondary'>
						{val}
					</Typography>
				}
			/>
			{img}
		</ListItem>
	);

	const CurWeather = () =>
		(weather[0] !== null || weather[0] !== undefined) && (
			<Grid container>
				<Grid item md={2} pl={{ md: 2, xs: 0 }}>
					<CurContent padding={['8px', '12px']} icon={<TodayIcon fontSize='12' />} header='今日:' val={moment().format('YYYY/MM/DD')} />
				</Grid>
				<Grid item md={2} pr={1}>
					<CurContent
						padding={['8px', '12px']}
						icon={
							<Avatar sx={{ bgcolor: getIconColor(weather[0].current.icon), width: 30, height: 30 }}>
								<img alt='forecastIcon' src={iconString(weather[0].current.icon)} height='30' width='30' />
							</Avatar>
						}
						header={'天氣概況'}
						val={weather[0].current.description}
					/>
				</Grid>
				<Grid item md={2} pr={1} pl={1}>
					<CurContent padding={['8px', '12px']} icon={<ThermostatIcon fontSize='12' />} header='氣溫' val={`${parseInt(weather[0].current.temp)}\u00B0C`} />
				</Grid>
				<Grid item md={2} pr={1}>
					<CurContent padding={['8px', '12px']} icon={<EmojiPeopleIcon fontSize='12' />} header='體感溫度' val={`${parseInt(weather[0].current.feels_like)}\u00B0C`} />
				</Grid>
				<Grid item md={2} pr={1}>
					<CurContent padding={['8px', '12px']} icon={<ImportExportIcon fontSize='12' />} header='溫度區間' val={`${parseInt(weather[0].current.temp_min)}~${parseInt(weather[0].current.temp_max)}\u00B0C`} />
				</Grid>
				<Grid item md={2}>
					<CurContent padding={['8px', '12px']} icon={<AccessTimeIcon fontSize='12' />} header='天氣更新' val={moment(weather[0].current.time).format('LT')} />
				</Grid>
			</Grid>
		);

	const ForecastWeather = () =>
		(weather[0] !== null || weather[0] !== undefined) && (
			<Stack direction='row' pl={{ md: 4, xs: 0 }} pt={0} spacing={3} divider={<Divider orientation='vertical' flexItem />}>
				{weather[0].forecast &&
					weather[0].forecast.map((m, i) => {
						if (i === 0 || i === 3 || i === 6) {
							return (
								<CurContent
									padding={['4px', '8px']}
									icon={
										<Avatar sx={{ bgcolor: getIconColor(m.icon), width: 30, height: 30 }}>
											<img alt='forecastIcon' src={iconString(m.icon)} height='30' width='30' />
										</Avatar>
									}
									header={`${moment(m.time).format('ddd')} (${moment(m.time).format('MM/DD')})`}
									val={`${m.description}, ${parseInt(m.temp)}\u00B0C`}
								/>
							);
						}
						return '';
					})}
			</Stack>
		);

	const WeatherCard = () =>
		weather[0] === undefined || !curSid ? (
			<Card sx={{ height: 60 }}>
				<CardContent>
					<Stack spacing={1} direction='row' display='inline-flex'>
						<TodayIcon />
						<Typography color='text.secondary'>
							今日: <b>{moment().format('YYYY/MM/DD')}</b>
						</Typography>
					</Stack>
				</CardContent>
			</Card>
		) : (
			<Card sx={{ height: { md: 60, xs: 62 }, padding: 0 }}>
				<CardContent sx={{ padding: 0 }}>
					<SwiperSlides perView={0.5} space={0} breakpoints={{ 640: { slidesPerView: 0.5 }, 768: { slidesPerView: 0.5 }, 1024: { slidesPerView: 1 } }} slides={[<CurWeather />, <ForecastWeather />]} />
				</CardContent>
			</Card>
		);

	return <WeatherCard />;
}
