import { API } from 'aws-amplify';
import { storeBySid, listStaffInfos } from '../graphql/queries';
import { updateMacuStore, updateStaffInfo } from '../graphql/mutations';

export const getAccVersion = async (qtype, variables) => {
	let id, version;
	let msg = 200;
	let query = storeBySid;
	if (qtype === 'staff') query = listStaffInfos;
	try {
		const hqCheckPW = await API.graphql({
			query,
			variables,
		});
		if (qtype === 'staff') {
			id = hqCheckPW.data.listStaffInfos.items[0].id;
			version = hqCheckPW.data.listStaffInfos.items[0]._version;
		} else {
			id = hqCheckPW.data.storeBySid.items[0].id;
			version = hqCheckPW.data.storeBySid.items[0]._version;
		}
	} catch (error) {
		msg = error;
	}
	return { id: id, version: version, status: msg };
};

export const updateForcePW = async (qtype, input) => {
	let msg = 200;
	let query = updateMacuStore;

	if (qtype === 'staff') query = updateStaffInfo;
	try {
		await API.graphql({
			query,
			variables: { input },
		});
	} catch (error) {
		msg = error;
	}
	return { status: msg };
};
