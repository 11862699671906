import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions({ storeData, dataContent }) {
	const yearLimit = 2024;
	const thisYear = parseInt(moment().format('YYYY'));
	const curMonth = parseInt(moment().format('MM'));
	const [expanded, setExpanded] = useState(false);
	const [dataYear, setDataYear] = useState(thisYear);
	const [dataMonth, setDataMonth] = useState(curMonth);
	const [uploaded, setUploaded] = useState([]);
	const [notuploaded, setNotuploaded] = useState([]);

	const sortData = (prev, prevYear) => {
		const up = storeData[0].filter((item) => item.month === prev && item.year === prevYear)[0];
		const not = storeData[1].filter((item) => item.month === prev && item.year === prevYear)[0];
		up ? setUploaded(up.values) : setUploaded([]);
		not ? setNotuploaded(not.values) : setNotuploaded([]);
	};

	const handleData = (previous) => {
		if (previous) {
			setDataMonth((prev) => {
				prev = prev - 1 === 0 ? 12 : prev - 1;
				setDataYear((prevYear) => {
					prevYear = prev === 12 ? prevYear - 1 : prevYear;
					sortData(prev, prevYear);
					return prevYear;
				});
				return prev;
			});
		} else {
			setDataMonth((prev) => {
				prev = prev + 1 === 13 ? 1 : prev + 1;
				setDataYear((prevYear) => {
					prevYear = prev === 1 ? prevYear + 1 : prevYear;
					sortData(prev, prevYear);
					return prevYear;
				});
				return prev;
			});
		}
	};

	const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

	useEffect(() => {
		setDataMonth((prev) => {
			sortData(prev, thisYear);
			return prev;
		});
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Box sx={{ display: 'flex', flexDirection: 'row', pb: 2 }}>
				<Box sx={{ position: 'relative' }}>
					<Button color='info' variant='contained' onClick={() => handleData(true)} disabled={dataYear === yearLimit && dataMonth === 1}>
						上個月
					</Button>
				</Box>
				<Box sx={{ flex: '1 1 auto', alignContent: 'center', textAlign: 'center' }}>
					<Typography variant='h6'>
						{dataYear}年{dataMonth}月
					</Typography>
				</Box>
				<Box sx={{ position: 'relative' }}>
					<Button color='primary' variant='contained' onClick={() => handleData(false)} disabled={dataMonth === curMonth && dataYear === thisYear}>
						下個月
					</Button>
				</Box>
			</Box>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
					<Typography color='green' sx={{ width: '45%', flexShrink: 0 }}>
						已上傳
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>{uploaded.length} 家門市</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ height: 300, overflowX: 'auto', paddingTop: 0 }}>{dataContent(uploaded)}</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2bh-content' id='panel2bh-header'>
					<Typography color='error' sx={{ width: '45%', flexShrink: 0 }}>
						未上傳
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>{notuploaded.length} 家門市</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ height: 300, overflowX: 'auto', paddingTop: 0 }}>{dataContent(notuploaded)}</AccordionDetails>
			</Accordion>
		</div>
	);
}
