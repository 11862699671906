import moment from 'moment';
import { Storage } from '@aws-amplify/storage';
import { sortFolderFileList, flattenTree } from '../utils/file';

const PAGE_SIZE = 1000;

const profitStats = async (data) => {
	let result = [];
	data[0].files.map(
		(i) =>
			i.files &&
			i.files.filter((j) => {
				let check = false;
				if (moment(j).month() === moment().month()) {
					check = true;
				}
				result.push({
					store: i.name.split('_')[1],
					uploaded: check,
					lastUploaded: j.lastModified,
				});
				return result;
			})
	);
	return result;
};

const sortuploadStores = async (data) => {
	const thisYear = parseInt(moment().format('YYYY'));
	const uploaded = [];
	const notUploaded = [];
	const storeLists = data.map((item) => ({ store: item.store, uploaded: false, lastUploaded: '-' }));
	for (let year = 2024; year <= thisYear; year++) {
		for (let month = 1; month <= 12; month++) {
			notUploaded.push({
				month: month,
				year: year,
				values: storeLists,
			});
		}
	}

	const findOrCreateMonthObject = (month, year) => {
		let monthObj = uploaded.find((item) => item.month === month && item.year === year);
		if (!monthObj) {
			monthObj = { month: month, year: year, values: [] };
			uploaded.push(monthObj);
		}
		return monthObj;
	};

	data.forEach((item) => {
		if (item.uploaded) {
			const month = parseInt(moment(item.lastUploaded).format('MM'));
			const year = parseInt(moment(item.lastUploaded).format('YYYY'));
			const monthObj = findOrCreateMonthObject(month, year);
			monthObj.values.push(item);
			const notUploadedMonth = notUploaded.find((val) => val.month === month && val.year === year);
			if (notUploadedMonth) {
				notUploadedMonth.values = notUploadedMonth.values.filter((store) => store.store !== item.store);
			}
		}
	});

	uploaded.sort((a, b) => a.month - b.month);
	return [uploaded, notUploaded];
};

export const getFiles = async (path, prefix) => {
	let nextToken = undefined;
	let hasNextPage = true;
	let allItems = { results: [] };
	// path = (path === '') ? path : path.replace(/^[^/]*\//, "");
	while (hasNextPage) {
		let response = await Storage.list(path, {
			pageSize: PAGE_SIZE,
			nextToken: nextToken,
			// level: 'private'
		});
		allItems.results = allItems.results.concat(response.results);
		if (response.hasNextToken) {
			nextToken = response.nextToken;
		} else {
			nextToken = undefined;
		}
		hasNextPage = nextToken ? true : false;
	}

	const tree = [];
	allItems.results.forEach((item) => {
		if (item.key !== '' && prefix.some((p) => item.key.startsWith(p) || item.key.includes(p))) {
			sortFolderFileList(item.key, tree, item);
		}
		return '';
	});

	const result = [{ type: 'folder', name: 'Root', files: tree }];
	const flatData = flattenTree(result);
	const checkProfit = await profitStats(tree.filter((i) => i.name === '損益表'));
	const sortedUpload = await sortuploadStores(checkProfit);
	return { sortedF: flatData, profitStats: checkProfit, sortedUpload: sortedUpload };
};

export const publicFolders = (username) => {
	const public_folders = [];
	let folders = ['C_zone', 'N_zone'];
	folders = public_folders.filter((item) => item.charAt(0) === username.charAt(0));
	return public_folders.concat(folders);
};

// previously used to render folder just added without refresh page
export const pushNewData = async (keys, allData, curPath, setRows, setAllData) => {
	const { parentId, subindex } = allData.filter((item) => item.path === `Root/${curPath}`)[0];
	const valIndex = allData.findIndex((x) => x.path === `Root/${curPath}`);
	let newVals = [];
	for (let k = 0; k < keys.length; k++) {
		const { eTag, lastModified } = await Storage.getProperties(keys[k]);
		newVals.push({
			id: keys[k],
			parentId: `${parentId}-${subindex - 1}`,
			path: `Root/${keys[k].slice(0, -1)}`,
			itemType: '資料夾',
			folderType: true,
			name: keys[k].split('/')[keys[k].split('/').length - 2],
			files: [],
			breadcrumb: false,
			key: keys[k],
			eTag: eTag,
			lastModified: moment(lastModified).format('YYYY-MM-DD HH:mm:ss'),
			size: '0 Bytes',
		});
	}
	setRows((prev) => [...prev, ...newVals]);
	setAllData((prev) => {
		let sorted = [];
		prev = prev.map((item, index) => {
			if (index === valIndex) {
				let i = item.files.length;
				sorted = newVals.map((v) => {
					i += 1;
					v.subindex = i;
					return v;
				});
				item.files.push(sorted);
			}
			return item;
		});
		return [...prev, ...sorted];
	});
};
