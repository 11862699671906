import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { getAccVersion, updateForcePW } from './util';
import { updateUserAttr } from '../utils/lambda';
import { userComplete } from '../images/images.js';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';

const steps = ['輸入帳號與信箱', '輸入驗證碼', '完成'];

export default function ResetPw() {
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState();
	const [user, setUser] = useState();
	const [accType, setAccType] = useState();
	const [accInfo, setAccInfo] = useState({});

	const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

	const changeLoading = () => setLoading((prev) => !prev);

	const forgotPassword = async (event) => {
		event.preventDefault();
		changeLoading();
		setErrMsg('');
		const form = new FormData(event.currentTarget);
		const username = form.get('username');
		const email = form.get('email');
		const acctype = form.get('acctype');
		try {
			setUser(username);
			setAccType(acctype);
			let variables = { sid: username };
			if (acctype === 'staff') variables = { filter: { userName: { eq: username } } };
			getAccVersion(acctype, variables).then(async (res) => {
				//get version
				if (res.status === 200) {
					setAccInfo(res);
					//prettier-ignore
					await updateUserAttr([username], [
            {"Name": "email", "Value": email},
            {"Name": "email_verified", "Value": "true"}
          ]); // add user attiribute
					await Auth.forgotPassword(username);
					changeLoading();
					handleNext();
				} else {
					setErrMsg('帳號或帳號類別有錯');
				}
			});
		} catch (err) {
			setErrMsg(err);
			changeLoading();
		}
	};

	const forgotPasswordSubmit = async (event) => {
		event.preventDefault();
		changeLoading();
		setErrMsg('');
		const form = new FormData(event.currentTarget);
		const code = form.get('code');
		const pass1 = form.get('newpassword1');
		const pass2 = form.get('newpassword2');
		try {
			if (pass1 === pass2) {
				const data = await Auth.forgotPasswordSubmit(user, code, pass1);
				//prettier-ignore
				await updateUserAttr([user], [
          {"Name": "email", "Value": ""}
        ]); //remove email attribute
				if (data === 'SUCCESS') {
					//set forcePW to true
					const variables = { id: accInfo.id, _version: accInfo.version, forcePW: false };
					updateForcePW(accType, variables).then((res) => {
						if (res.status === 200) {
							changeLoading();
							handleNext();
						}
					});
				} else {
					setErrMsg('密碼重設失敗');
					changeLoading();
				}
			} else {
				setErrMsg('密碼不一致');
				changeLoading();
			}
		} catch (err) {
			setErrMsg(err);
			changeLoading();
		}
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				{' '}
				{/*, height: '100vh'*/}
				{/* <Avatar sx={{ m: 1, bgcolor: 'black' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography fontWeight='bold'>重設密碼</Typography> */}
				<Stepper activeStep={activeStep} sx={{ pt: 6 }}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{activeStep === 0 && (
					<React.Fragment>
						<Box component='form' onSubmit={forgotPassword} mt={1}>
							<TextField margin='normal' required fullWidth id='username' label='帳號' name='username' autoFocus />
							<TextField margin='normal' required fullWidth id='email' label='信箱' name='email' />
							<FormControl fullWidth>
								<InputLabel id='account-type-select-label'>帳號類別</InputLabel>
								<Select labelId='account-type-select-label' id='account-type-select' defaultValue={''} label='AccType' name='acctype'>
									<MenuItem value='staff'>總部</MenuItem>
									<MenuItem value='store'>門店</MenuItem>
								</Select>
							</FormControl>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Button variant='outlined' color='inherit' disabled>
									上一步
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button variant='outlined' type='sumbit'>
									<Typography variant='primary' display={!loading ? undefined : 'none'}>
										下一步
									</Typography>
									<CircularProgress size={24} sx={{ color: 'primary', display: loading ? undefined : 'none' }} />
								</Button>
							</Box>
						</Box>
					</React.Fragment>
				)}
				{activeStep === 1 && (
					<React.Fragment>
						<Box component='form' onSubmit={forgotPasswordSubmit} mt={1}>
							<TextField margin='normal' required fullWidth id='code' label='驗證碼' name='code' autoComplete='off' />
							<TextField margin='normal' required fullWidth id='newpassword1' label='新密碼' name='newpassword1' type='password' autoComplete='off' />
							<TextField margin='normal' required fullWidth id='newpassword2' label='確認新密碼' name='newpassword2' type='password' autoComplete='off' />
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Button variant='outlined' color='inherit' onClick={handleBack} sx={{ mr: 1 }} disabled={loading}>
									上一步
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button variant='contained' type='sumbit'>
									<Typography color='white' display={!loading ? undefined : 'none'}>
										完成
									</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: loading ? undefined : 'none' }} />
								</Button>
							</Box>
						</Box>
					</React.Fragment>
				)}
				{activeStep === 2 && (
					<React.Fragment>
						<Box width='100%' alignSelf='center' pt={3}>
							<Typography pb={1} variant='h6'>
								重設密碼成功！
							</Typography>
							<Typography pb={1} variant='body2'>
								*第一次登入時需更改密碼
							</Typography>
							<img src={userComplete} alt='user password changed' style={{ width: 300, height: 250 }} />
						</Box>
					</React.Fragment>
				)}
				<Typography pt={2} color='error'>
					{errMsg}
				</Typography>
				<Box width='100%' mt={1} alignSelf='center'>
					<Button variant='outlined' color='inherit' onClick={() => navigate('/home')} sx={{ width: '100%' }} disabled={loading}>
						返回
					</Button>
				</Box>
			</Box>
		</Container>
	);
}
