import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';

const getMothDates = (date) => {
	let curMonth = moment(date).startOf('month'); // get start of current month
	let curMonthDays = curMonth.daysInMonth(); // get days of current month
	let curMonthDates = []; // Collect dates of previous month
	for (let i = 0; i < curMonthDays; i++) {
		// Calculate moment based on start of previous month, plus day offset
		let curMonthDay = curMonth.clone().add(i, 'days').format('MM/DD/YYYY');
		curMonthDates.push(curMonthDay);
	}
	return curMonthDates;
};

const resetVal = (fields, date, elem) => {
	fields = Object.keys(fields).reduce((acc, curr) => {
		if (curr === 'date' || curr === 'createdAt' || curr === 'updatedAt') return { ...acc, [curr]: moment(date).format('MM/DD/YYYY') };
		if (curr === 'correction' || curr === 'daily_total_expense' || curr === 'daily_total_sales') return { ...acc, [curr]: '{"AM":0,"PM":0,"total":0}' };
		if (curr === 'month') return { ...acc, [curr]: elem['month'] };
		if (curr === 'year') return { ...acc, [curr]: elem['year'] };
		if (curr === 'store_id') return { ...acc, [curr]: elem['store_id'] };
		if (curr === 'store_name') return { ...acc, [curr]: elem['store_name'] };
		if (curr !== 'month' || curr !== 'store_id' || curr !== 'store_name' || curr !== 'year') {
			if (typeof fields[curr] === 'string') return { ...acc, [curr]: '' };
			if (typeof fields[curr] === 'number') return { ...acc, [curr]: 0 };
			if (fields[curr] instanceof Array) return { ...acc, [curr]: [] };
			if (typeof fields[curr] === 'object') return { ...acc, [curr]: {} };
		}
		return acc;
	}, {});
	fields['日總收入'] = 0;
	return fields;
};
//prettier-ignore
const pushList = (elem, val) => ({ '門店': elem['門店'], '門店ID': elem['門店ID'], '日期': val[0], '年份': elem['年份'], '月份': elem['月份'], '雲端營收': val[1], '更正': val[2], '日總收入': val[3], '差額': val[4], '日總支出': val[5], '門市現金': val[6], 'UBER': val[7], 'UBER現金': val[8], '熊貓': val[9], 'PANDAGO': val[10], 'LINEPAY': val[11], '街口支付': val[12], '麻吉線上金流': val[13], '一卡通': val[14], '悠遊卡': val[15], '信用卡': val[16], '全支付': val[17], '應存入金額': val[18], '存入金額': val[19], '存入差額': val[20] });

const ExportExcel = ({ excelData, columnTitles, storeId }) => {
	const [month, setMonth] = useState('');
	const prevDates = excelData.length !== 0 ? getMothDates(excelData[0]['date']) : '';
	useEffect(() => {
		if (excelData.length !== 0) {
			setMonth(excelData[0]['month']);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let row = [...excelData];
	let raw = excelData[0];
	if (excelData.length !== 0) {
		storeId.map((sid) => prevDates.map((dItem) => (row.findIndex((obj) => obj.date === dItem && obj.store_id === sid) === -1 ? row.push(resetVal(raw, dItem, row.filter((obj) => obj.store_id === sid)[0])) : '')));
	}
	let newRow = [];
	let rowVal = {};
	row.map((r) => {
		rowVal = {};
		columnTitles.map((c, index) => {
			Object.keys(r).map((key) => {
				if (key === c.field) {
					rowVal[c.headerName] = r[c.field];
				}
				return key;
			});
			return c;
		});
		newRow.push(rowVal);
		return r;
	});
	row = newRow;

	row.sort(function (a, b) {
		let sid = a['門店ID'].localeCompare(b['門店ID']);
		return sid !== 0 ? sid : a['日期'].localeCompare(b['日期']);
	});

	row.map((r) => {
		r['更正'] = JSON.parse(r['更正']).total || 0;
		r['日總支出'] = JSON.parse(r['日總支出']).total || 0;
		r['門市現金'] = JSON.parse(r['門市現金']).total || 0;
		return '';
	});

	const store_days = row.map((i) => i['門店ID']);
	const counts = {};

	for (const num of store_days) {
		counts[num] = counts[num] ? counts[num] + 1 : 1;
	}

	let w = 1;
	let x = 1;
	let [cloud_revenue, correction, daily_revenue, discrepancy, daily_total_expense, cash, uber, uber_cash, foodpanda, panda_go, linepay, jsko, mace, ipass, uucard, cc, pxpay, estimated_deposit_amount, deposit_amount, deposit_discrepancy] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	let [cloud_revenue_total, correction_total, daily_revenue_total, discrepancy_total, daily_total_expense_total, cash_total, uber_total, uber_cash_total, foodpanda_total, panda_go_total, linepay_total, jsko_total, mace_total, ipass_total, uucard_total, cc_total, pxpay_total, estimated_deposit_amount_total, deposit_amount_total, deposit_discrepancy_total] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	let week_uber = [];
	let week_fd = [];
	row = row.reduce((list, elem, i) => {
		delete elem['id']; //remove id from sheet
		delete elem['人員'];
		delete elem['修改人員'];
		delete elem['時數'];
		delete elem['產值'];
		delete elem['發票'];
		delete elem['POS發票'];
		delete elem['建立日期'];

		// elem['建立日期'] = moment(elem['建立日期']).format('MM/DD/YYYY');
		list.push(elem);
		cloud_revenue += elem['雲端營收'];
		correction += elem['更正'];
		daily_revenue += elem['日總收入'];
		discrepancy += elem['差額'];
		estimated_deposit_amount += elem['應存入金額'];
		deposit_amount += elem['存入金額'];
		deposit_discrepancy += elem['存入差額'];
		daily_total_expense += elem['日總支出'];
		cash += elem['門市現金'];
		uber += elem['UBER'];
		foodpanda += elem['熊貓'];
		linepay += elem['LINEPAY'];
		jsko += elem['街口支付'];
		mace += elem['麻吉線上金流'];
		ipass += elem['一卡通'];
		uucard += elem['悠遊卡'];
		cc += elem['信用卡'];
		pxpay += elem['全支付'];
		uber_cash += elem['UBER現金'];
		panda_go += elem['PANDAGO'];

		cloud_revenue_total += elem['雲端營收'];
		correction_total += elem['更正'];
		daily_revenue_total += elem['日總收入'];
		discrepancy_total += elem['差額'];
		estimated_deposit_amount_total += elem['應存入金額'];
		deposit_amount_total += elem['存入金額'];
		deposit_discrepancy_total += elem['存入差額'];
		daily_total_expense_total += elem['日總支出'];
		cash_total += elem['門市現金'];
		uber_total += elem['UBER'];
		foodpanda_total += elem['熊貓'];
		linepay_total += elem['LINEPAY'];
		jsko_total += elem['街口支付'];
		mace_total += elem['麻吉線上金流'];
		ipass_total += elem['一卡通'];
		uucard_total += elem['悠遊卡'];
		cc_total += elem['信用卡'];
		pxpay_total += elem['全支付'];
		uber_cash_total += elem['UBER現金'];
		panda_go_total += elem['PANDAGO'];

		if (x % 7 === 0 && x < counts[elem['門店ID']] && w < 4) {
			list.push(pushList(elem, ['第' + w + '周總和', cloud_revenue, correction, daily_revenue, discrepancy, daily_total_expense, cash, uber, uber_cash, foodpanda, panda_go, linepay, jsko, mace, ipass, uucard, cc, pxpay, estimated_deposit_amount, deposit_amount, deposit_discrepancy]));
			w += 1;
			week_uber.push(parseInt(uber * 0.3));
			week_fd.push(parseInt(foodpanda * 0.28));
			[cloud_revenue, correction, daily_revenue, discrepancy, daily_total_expense, cash, uber, uber_cash, foodpanda, panda_go, linepay, jsko, mace, ipass, uucard, cc, pxpay, estimated_deposit_amount, deposit_amount, deposit_discrepancy] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		}
		if (x === counts[elem['門店ID']]) {
			list.push(pushList(elem, ['第' + w + '周總和', cloud_revenue, correction, daily_revenue, discrepancy, daily_total_expense, cash, uber, uber_cash, foodpanda, panda_go, linepay, jsko, mace, ipass, uucard, cc, pxpay, estimated_deposit_amount, deposit_amount, deposit_discrepancy]));
			list.push(pushList(elem, ['Total', cloud_revenue_total, correction_total, daily_revenue_total, discrepancy_total, daily_total_expense_total, cash_total, uber_total, uber_cash_total, foodpanda_total, panda_go_total, linepay_total, jsko_total, mace_total, ipass_total, uucard_total, cc_total, pxpay_total, estimated_deposit_amount_total, deposit_amount_total, deposit_discrepancy_total]));
			week_uber.push(parseInt(uber * 0.3));
			week_fd.push(parseInt(foodpanda * 0.28));
			for (let i = 1; i < 5; i++) {
				//prettier-ignore
				list.push({ '門店': elem['門店'], '門店ID': elem['門店ID'], '日期': '第' + i + '周外送費用', '年份': elem['年份'], '月份': elem['月份'], '雲端營收': '', '更正': '', '日總收入': '', '差額': '', '日總支出': '', '門市現金': '', 'UBER': week_uber[i - 1] === undefined ? 0 : week_uber[i - 1], '熊貓': week_fd[i - 1] === undefined ? 0 : week_fd[i - 1], 'LINEPAY': '', '街口支付': '', '麻吉線上金流': '', '一卡通': '', '悠遊卡': '', '信用卡': '', '全支付': '', '應存入金額': '', '存入金額': '', '存入差額': '' });
			}
			w = 1;
			x = 1;
			[cloud_revenue, correction, daily_revenue, discrepancy, daily_total_expense, cash, uber, uber_cash, foodpanda, panda_go, linepay, jsko, mace, ipass, uucard, cc, pxpay, estimated_deposit_amount, deposit_amount, deposit_discrepancy] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			[cloud_revenue_total, correction_total, daily_revenue_total, discrepancy_total, daily_total_expense_total, cash_total, uber_total, uber_cash_total, foodpanda_total, panda_go_total, linepay_total, jsko_total, mace_total, ipass_total, uucard_total, cc_total, pxpay_total, estimated_deposit_amount_total, deposit_amount_total, deposit_discrepancy_total] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			week_fd = [];
			week_uber = [];
		} else {
			x += 1;
		}
		return list;
	}, []);

	const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
	const fileExtension = '.xlsx';
	const fileName = `麻古直營門市對帳報表${month}月份(單店)`;
	const highlight = {
		fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFC33' } },
		border: { bottom: { style: 'thin', color: '000000' } },
	};
	const exportToExcel = async () => {
		let stores = [];
		let ws1 = {};
		let wb = { Sheets: {}, SheetNames: [] };

		storeId.sort((a, b) => {
			let sortedStore = a.localeCompare(b);
			return sortedStore;
		});
		storeId.map((s) => {
			let name = '';
			stores = row.filter((item) => {
				let getStore = item['門店ID'];
				if (getStore === s) {
					name = item['門店'];
					return item;
				}
				return '';
			});
			ws1 = XLSX.utils.json_to_sheet(stores, { origin: 'A2' });
			if (!ws1['!cols']) ws1['!cols'] = [];
			ws1['!cols'][1] = { width: 15 };
			ws1['!cols'][2] = { width: 12 };
			ws1['!cols'][12] = { width: 10 };
			ws1['!cols'][14] = { width: 10 };
			ws1['!cols'][17] = { width: 15 };
			ws1['!cols'][22] = { width: 12 };
			ws1['!cols'][23] = { width: 12 };
			ws1['!cols'][24] = { width: 12 };
			// ws1['!cols'][25] = {width: 10};

			for (let i = 65; i <= 89; i++) {
				ws1[String.fromCharCode(i) + '2'].s = {
					border: { bottom: { style: 'thin', color: '000000' } },
				};

				if (i > 73 && i < 87) {
					ws1[String.fromCharCode(i) + '2'].s['fill'] = {
						type: 'pattern',
						patternType: 'solid',
						fgColor: { rgb: 'FFFC33' },
					};
				}
			} //underline and highlight title
			XLSX.utils.sheet_add_aoa(ws1, [[`麻古茶坊直營門市每日收支明細-${name}`]], { origin: 'B1' });
			ws1['!merges'] = [{ s: { c: 1, r: 0 }, e: { c: 25, r: 0 } }];
			ws1['B1'].s = {
				alignment: { vertical: 'center', horizontal: 'center' },
				font: { name: 'DFKai-SB', sz: 14 },
			};
			ws1['F2'].s = highlight;
			ws1['G2'].s = highlight;
			ws1['X2'].s = highlight;
			let range = XLSX.utils.decode_range(ws1['!ref']);
			for (let R = range.s.r; R <= range.e.r; ++R) {
				for (let C = range.s.c; C <= range.e.c; ++C) {
					/* find the cell object */
					let cellref = XLSX.utils.encode_cell({ c: C, r: R }); // construct A1 reference for cell
					if (!ws1[cellref]) continue; // if cell doesn't exist, move on
					let cell = ws1[cellref];
					if (cell.v === 'Total') {
						for (let i = 65; i <= 89; i++) {
							ws1[String.fromCharCode(i) + (R + 1)].s = {
								fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: '928D8D' } },
							};
						}
					} //for total row
					if (cell.v.toString().includes('總和')) {
						for (let i = 65; i <= 89; i++) {
							ws1[String.fromCharCode(i) + (R + 1)].s = {
								fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'D0CECE' } },
							};
						}
					} //for week row
				}
			}
			wb.Sheets[name] = ws1;
			wb.SheetNames.push(name);
			return s;
		});
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
		<>
			<FormControl variant='outlined'>
				<Tooltip title='下載檔案'>
					<>
						<Button
							color='primary'
							variant='contained'
							startIcon={<DownloadIcon />}
							onClick={(e) => {
								exportToExcel(fileName);
							}}
							disabled={row.length === 0}>
							下載
						</Button>
					</>
				</Tooltip>
			</FormControl>
		</>
	);
};
export default ExportExcel;
