import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import XLSX from 'sheetjs-style';
import moment from 'moment';
import 'moment/locale/zh-tw';
import DataGridTw from '../utils/datagridtw';
import { getStoreIds } from '../utils/extra';
import { getAllAuditLists } from '../utils/requestDelivery';
import Tooltip from '../components/Tooltip';
import Dialog from '../components/Dialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddIcon from '@mui/icons-material/Add';

export default function Auditlist() {
	const navigate = useNavigate();
	const location = useLocation();
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.zone;
	const hq = userVal.hq;
	const userName = userVal.userName;
	const [storeIDS, setstoreIDS] = useState([userName]);
	// const storeIDS = userVal.value.attributes.name.split(',');
	// const staffStores = userVal.staffStores.map(i => i.store_name);
	const [sortedRows, setSortedRows] = useState([]);
	const [fromDate, setFromDate] = useState(location.state !== null && location.state.from !== undefined ? moment(location.state.from) : moment().subtract(1, 'month'));
	const [toDate, setToDate] = useState(location.state !== null && location.state.to !== undefined ? moment(location.state.to) : moment());
	const [endHandle, setEndHandle] = useState(false);
	const [storeVal, setStoreVal] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setloading] = useState(true);
	const [onload, setOnload] = useState(true);
	const descriptionElementRef = useRef(null);

	useEffect(() => {
		if (hq) {
			getStoreIds(hq, zoneInfo, userName, '/audit', 'id').then((res) => {
				setstoreIDS(res);
				getForm(fromDate, toDate, res);
				setOnload(false);
			});
		} else {
			getForm(fromDate, toDate, storeIDS);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hq]);

	useEffect(() => {
		if (!onload) {
			getForm(fromDate, toDate, storeIDS);
		}
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

	async function getForm(from, to, sID) {
		setloading(true);
		// const reqNames = storeIDS[0] === '總部' ? [storeIDS[0]] : storeIDS;
		const reqNames = hq ? ['總部'] : sID;
		getAllAuditLists(reqNames, moment(from).format('YYYY/MM/DD'), moment(to).format('YYYY/MM/DD')).then((res) => {
			if (hq && zoneInfo !== 'master' && sID.length >= 1) {
				res[0] = res[0].filter((item) => sID.includes(item.store_id));
			}
			const post = res[0];
			let form = res[0];
			let storeV = [];
			let formsCount = {};
			let formScore = {};
			let content = post.map((p) => p.Content.map((c) => JSON.parse(c)));
			form = form.filter((f, index) => {
				f.createdAt = moment(f.createdAt).format('L');
				f.Status = f.Status[f.Status.length - 1].split('@')[0] === 'submit' ? '提交' : '草稿';
				let checkState = !(f.createdAt < moment().subtract(2, 'day').format('L') && f.Status === '草稿');
				f.Content = content[index];
				if (checkState && f.Status === '提交') {
					formsCount[f.Store] = formsCount[f.Store] ? formsCount[f.Store] + 1 : 1;
					formScore[f.Store] = formScore[f.Store] ? (formScore[f.Store] += f.Final) : f.Final;
				}
				return checkState;
			});
			//remove working status for more than 3 days
			form = form.map((f) => f).filter((item) => (sID.includes('HQ') ? item.Status === '提交' : sID.includes(item.store_id)));
			//check visibility //!storeName.includes('總部')
			form.sort((a, b) => {
				let store = a['Store'].localeCompare(b['Store']);
				return store !== 0 ? store : a['createdAt'].localeCompare(b['createdAt']);
			});

			Object.keys(formsCount).map((f) => storeV.push({ store: f, count: formsCount[f], score: Math.round((formScore[f] / formsCount[f]) * 100) / 100 })); //get store statistics
			storeV.sort((a, b) => b['score'] - a['score']);
			setStoreVal(storeV);
			setSortedRows(form);
			setloading(false);
		});
	}

	const iconNav = (title, paramsVal, actionVal, fromVal, toVal) => navigate('/audit/edit', { state: { name: title, route: paramsVal, action: actionVal, from: fromVal, to: toVal } });

	const columns = [
		{
			field: '操作',
			headerName: '操作',
			type: 'actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (params) => {
				const paramsVal = JSON.stringify(params);
				const fromVal = moment(fromDate).format('L');
				const toVal = moment(toDate).format('L');
				return [
					<>
						<GridActionsCellItem
							icon={
								<Tooltip title='檢視'>
									<RemoveRedEyeIcon color='error' />
								</Tooltip>
							}
							label='View'
							onClick={(e) => iconNav('檢視自檢表', paramsVal, 'view', fromVal, toVal)}
						/>
						<GridActionsCellItem
							icon={
								<Tooltip title='編輯'>
									<EditIcon color={params.row.Status === '提交' ? 'disabled' : 'success'} />
								</Tooltip>
							}
							label='Edit'
							disabled={params.row.Status === '提交'}
							onClick={(e) => iconNav('編輯自檢表', paramsVal, 'edit', fromVal, toVal)}
						/>
					</>,
				];
			},
		},
		{ field: 'Status', headerName: '狀態', width: 80 },
		{ field: 'Store', headerName: '門市', width: 120 },
		{ field: 'createdAt', headerName: '建立日期', width: 120 },
		{ field: 'Model', headerName: '評核模式', width: 150 },
		{ field: 'Type', headerName: '評核類別', width: 120 },
		{ field: 'Final', headerName: '總分', width: 120 },
		{ field: 'Suggestion', headerName: '本次建議事項', width: 150 },
		{ field: 'Improvement', headerName: '待改善事項', width: 150 },
	];

	const getDate = (from, to) => getForm(from, to, storeIDS);

	const DContent = () => {
		return (
			<Grid container>
				<Grid item xs={4} fontWeight='bold'>
					門店
				</Grid>
				<Grid item xs={4} fontWeight='bold'>
					表單數
				</Grid>
				<Grid item xs={4} fontWeight='bold'>
					平均分數
				</Grid>
				{storeVal.map((s, index) => (
					<React.Fragment key={index + s.store + s}>
						<Grid item xs={4}>
							{s.store}
						</Grid>
						<Grid item xs={4}>
							{s.count}
						</Grid>
						<Grid item xs={4}>
							{s.score}
						</Grid>
					</React.Fragment>
				))}
			</Grid>
		);
	};

	const exportToExcel = async (excelData, from, to) => {
		const fileName = `${moment(from).format('L')}-${moment(to).format('L')}自檢表門店統計.xlsx`;
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		for (let i = 65; i <= 67; i++) {
			worksheet[String.fromCharCode(i) + '1'].s = {
				font: { bold: true },
			};
		} // bold header
		worksheet['A1'].v = '門店';
		worksheet['B1'].v = '表單數';
		worksheet['C1'].v = '平均分數';
		if (!worksheet['!cols']) worksheet['!cols'] = [];
		worksheet['!cols'][0] = { width: 15 };
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, '自檢表統計');
		XLSX.writeFile(workbook, fileName, { compression: true });
	};

	function CustomToolbar() {
		return (
			<>
				<GridToolbarContainer>
					<Button onClick={handleClickOpen} variant='contained' startIcon={<AssessmentIcon />}>
						門店統計
					</Button>
					<Tooltip title='新增資料'>
						<Button
							component='label'
							variant='contained'
							color='inherit'
							startIcon={<AddIcon />}
							onClick={(e) => {
								navigate('/audit/edit', { state: { name: '新增自檢表' } });
							}}>
							新增
						</Button>
					</Tooltip>
					<GridToolbarQuickFilter />
				</GridToolbarContainer>
			</>
		);
	}

	return (
		<Box>
			<Grid container spacing={2} pb={2} display='flex' alignItems='center'>
				<Grid item xs={12} md={5}>
					<Stack direction='row' spacing={2} paddingBottom={2}>
						<DatePicker disableFuture label={'開始'} value={fromDate} onChange={(date) => setFromDate(date)} onClose={() => setEndHandle(true)} views={['year', 'month', 'day']} format='YYYY MMM DD' localeText={{ datePickerToolbarTitle: '開始日期' }} slotProps={{ toolbar: { toolbarFormat: 'YYYY MMM DD', hidden: false } }} sx={{ backgroundColor: 'white' }} dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')} />
						<DatePicker disableFuture value={toDate} onChange={(date) => setToDate(date)} onClose={() => setEndHandle(false)} onOpen={() => setEndHandle(true)} onAccept={(date) => getDate(fromDate, moment(date))} open={endHandle} minDate={fromDate} label={'結束'} views={['year', 'month', 'day']} format='YYYY MMM DD' localeText={{ datePickerToolbarTitle: '結束日期' }} slotProps={{ toolbar: { toolbarFormat: 'YYYY MMM DD', hidden: false } }} sx={{ backgroundColor: 'white' }} dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')} />
					</Stack>
				</Grid>
				<Grid item xs={12} md={2} sx={{ display: storeIDS.includes('HQ') ? undefined : 'none' }}>
					<Dialog dialog={open} title={`區間門店數: ${storeVal.length}`} content={DContent()} cancel={'關閉'} submit={'下載'} handleSubmit={(e) => exportToExcel(storeVal, fromDate, toDate)} handleClose={handleClose} showBtn={true} disable={false} fullWidth={false} showSub={true} />
				</Grid>
			</Grid>
			<Card>
				<CardContent>
					<div style={{ height: 600, width: '100%' }}>
						<DataGrid
							rows={sortedRows}
							columns={columns}
							loading={loading}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[10]}
							slots={{ toolbar: CustomToolbar }}
							localeText={DataGridTw}
						/>
					</div>
				</CardContent>
			</Card>
		</Box>
	);
}
