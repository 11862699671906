import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

export default function CircularProgressWithLabel(props) {
	return (
		<Box sx={{ position: 'relative', display: props.filename ? 'inline-flex' : 'none', pt: 2 }}>
			<CircularProgress variant='determinate' {...props} color='success' size={60} />
			<Box
				sx={{
					top: 15,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{Math.round(props.value) === 100 ? <CheckIcon color='success' /> : <Typography variant='caption' component='div' fontSize={16}>{`${Math.round(props.value)}%`}</Typography>}
			</Box>
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
	filename: PropTypes.any,
};
