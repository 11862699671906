import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { updateMergeDialog } from '../redux/deliverySlice';
import { reqQuote, reqDriver, manageDelivery } from '../utils/lambda';
import Loadeffect from '../components/Loadeffect';
import { handleScroll } from '../components/Scrolltop';
import { filteredItems, SearchBox } from '../components/ShippingSearch';
import { Utf8ArrayToStr } from '../utils/requestDelivery';
import Createorder from './Createorder';
import Mergecard from './Mergecard';
import Storecard from './Storecard';
import Ordercard from './Ordercard';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import MergeIcon from '@mui/icons-material/Merge';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export default function Dashboard({ user, storeid, dList, info, token, onMergeUpdate, setCurSid, allList, betaUser }) {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const dialogState = useSelector((state) => state.shipping.mergeDialog);
	const weatherData = useSelector((state) => state.users.weather);
	const [store, setStore] = useState('');
	const [count, setCount] = useState(0);
	const [deliveryList, updateDeliveryList] = useState([]);
	const [filterList, updateFilterList] = useState([]);
	const [createDialog, setCreateDialog] = useState(false);
	const [open, setOpen] = useState(true);
	const [source, setSource] = useState('all');
	const [sourceCount, setSourceCount] = useState({});
	const [searchItem, setsearchItem] = useState('');
	const [animationParent] = useAutoAnimate();
	const maxDelivery = 40;

	useEffect(() => {
		setOpen(true);
		updateDeliveryList([...dList]);
		if (storeid === null && store !== '') {
			let filter = [];
			[...dList].map((item) => (item.pickup_name === store ? filter.push(item) : null));
			countSource(filter);
			updateFilterList([...filter]);
			setCount(filter.length);
		} else {
			countSource(dList);
			updateFilterList([...dList]);
			setCount(dList.length);
		}
		setSource('all');
		setOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dList]);

	const handleDialog = () => dispatch(updateMergeDialog(!dialogState));

	const handleSource = (event, newSource) => {
		if (newSource !== null) {
			setSource(newSource);
			let filter = [];
			if (store !== '' && newSource === 'all') {
				deliveryList.map((item) => (item.pickup_name === store ? filter.push(item) : null));
			} else if (store !== '') {
				deliveryList.map((item) =>
					item.pickup_name === store && item.order_from === newSource ? filter.push(item) : null
				);
			} else {
				newSource === 'all'
					? (filter = [...deliveryList])
					: deliveryList.map((item) => (item.order_from === newSource ? filter.push(item) : null));
			}
			updateFilterList(filter);
		}
	};

	const countSource = (arr) => {
		const fromCount = {};
		const unique = [...new Set(arr.map((item) => item.order_from))];
		unique.map((val) => (fromCount[val] = arr.filter((item) => item.order_from === val).length));
		setSourceCount(fromCount);
	};

	const qutoeDriver = async (list, priceitem) => {
		setOpen(true);
		try {
			// prettier-ignore
			let cashpay = (list.pay_state === '未支付')? {
        "requirements":[{
          "paying_party": "recipient",
          "amount": priceitem,
          "payment_methods": {
            "cash": {
              "enabled": true
            }
          }
        }]
      }: null;
			const data = await reqQuote(list, info, token, cashpay, priceitem);
			let res = Utf8ArrayToStr(data.Payload);
			if (res && res.id) {
				snackbar('運費預估成功！');
			} else {
				snackbar(`運費預估錯誤${res.message}`);
			}
		} catch (err) {
			console.log('Error', err);
		}
	};

	const getDriver = async (list, count, priceitem, allItem, drivers) => {
		setOpen(true);
		let qfee = parseInt(list.fee) || 0;
		let dataRes = 0;
		let dType = 'Direct';
		for (let i = 0; i < drivers; i++) {
			qfee += dataRes;
			let valitem = [];
			if (count > maxDelivery) {
				valitem = [
					{ name: '[提醒]此為大訂單-由多位外送夥伴配送，您僅須領取其中一部分商品', quantity: 1, size: 'large' },
					...allItem,
				];
			} else {
				valitem = [...allItem];
			}
			// prettier-ignore
			let cashpay = (list.pay_state === '未支付')? {
					"requirements":[{
						"paying_party": "recipient",
						"amount": priceitem,
						"payment_methods": {
							"cash": {
								"enabled": true
							}
						}
					}]
				}: null;

			let qid = moment() < moment(list.quote_expires) ? list.quote_id : null;
			if (i > 0 && qid) {
				let res = await reqQuote(list, info, token, cashpay);
				qid = Utf8ArrayToStr(res.Payload).id;
			}

			// console.log(i,list, info, token, valitem, qid, priceitem, count>maxDelivery, cashpay, i, dType, qfee);
			try {
				// prettier-ignore
				const data = await reqDriver(list, info, token, valitem, qid, priceitem, count > maxDelivery, cashpay, i, dType, qfee);
				let res = Utf8ArrayToStr(data.Payload);
				if (res && res.id) {
					dataRes = res.fee;
					snackbar('司機下單成功！');
				} else {
					snackbar(`下單錯誤${res.message}`);
				}
			} catch (err) {
				console.log('Error', err);
			}
		}
	};

	const hideDelivery = async (event, list) => {
		event.preventDefault();
		setOpen(true);
		try {
			await manageDelivery({ id: list.id, d_id: '' }, info, token, 'hide');
			updateDeliveryList((prev) => {
				let newData = [];
				prev.map((item) => (item.id !== list.id ? newData.push(item) : ''));
				updateFilterList(newData);
				setCount(newData.length);
				return newData;
			});
			snackbar('訂單已轉為不配送！');
		} catch (err) {
			console.log('Error', err);
		}
	};

	const snackbar = (msg) => enqueueSnackbar(msg) && setOpen(false);

	const hqClick = (e, val, sid) => {
		let filter = [];
		if (val !== null) {
			setCurSid(sid);
			setStore(() => val);
			deliveryList.map((item) => (item.pickup_name === val ? filter.push(item) : null));
			countSource(filter);
			updateFilterList(filter);
			setCount(filter.length);
			setSource('all');
			handleScroll(e, 'instant');
		}
		return filter;
	};

	const handleBack = () => {
		setStore('');
		setCurSid('');
		updateFilterList([]);
		setCount(deliveryList.length);
	};

	const hanldeCreateDialog = () => setCreateDialog(!createDialog);

	const handleSearchChange = (event) => setsearchItem(event.target.value);

	return (
		<Grid container spacing={3}>
			<Loadeffect state={open} />
			<Grid container justifyContent={'space-between'} paddingLeft={3}>
				<Grid item xs={12} md={8} paddingTop={1}>
					<Stack direction='row' spacing={1} useFlexGap flexWrap='wrap'>
						<Chip variant='outlined' icon={<ListAltIcon />} label={`待配送: ${count} 單`} />
						<Chip variant='outlined' icon={<InfoIcon />} label={`每位司機最多可配送 ${maxDelivery} 杯`} />
						<Button
							onClick={handleDialog}
							startIcon={<MergeIcon />}
							variant='contained'
							size='small'
							color='inherit'
							sx={{ display: storeid === null && store === '' ? 'none' : undefined }}>
							合併追加訂單
						</Button>
						{betaUser.create_order.includes(user) && (
							<Button
								onClick={hanldeCreateDialog}
								startIcon={<NoteAddIcon />}
								variant='contained'
								size='small'
								color='inherit'
								sx={{ display: storeid === null && store === '' ? 'none' : undefined }}>
								建立訂單
							</Button>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} md={4} paddingTop={2} display={storeid === null && store === '' ? 'none' : undefined}>
					<ToggleButtonGroup
						color='error'
						value={source}
						exclusive
						onChange={handleSource}
						aria-label='Platform'
						fullWidth>
						<ToggleButton value='all'>全部 {count}</ToggleButton>
						<ToggleButton value='食在麻吉'>食在麻吉 {sourceCount['食在麻吉'] || 0}</ToggleButton>
						<ToggleButton value='電話'>電話 {sourceCount['電話'] || 0}</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>
			{storeid === null && store === '' ? (
				<Storecard val={deliveryList} hqClick={hqClick} weather={weatherData} />
			) : (
				<>
					<Stack direction={'row'} pt={{ xs: 2, md: 0 }}>
						<Button
							sx={{ marginLeft: 3, display: store !== '' ? undefined : 'none' }}
							variant='contained'
							color='inherit'
							onClick={handleBack}>
							返回
						</Button>
						<SearchBox
							searchItem={searchItem}
							handleSearchChange={handleSearchChange}
							boxStyle={{ width: { md: 350, xs: 250 }, pl: 3, pt: { md: 1, xs: 2 } }}
						/>
					</Stack>
					<div ref={animationParent}>
						{[...filteredItems([...filterList], searchItem, true)].map((item, index) => (
							<Grid item xs={12} key={item + index}>
								<Ordercard
									user={user}
									order={item}
									maxDelivery={maxDelivery}
									hideDelivery={hideDelivery}
									qutoeDriver={qutoeDriver}
									getDriver={getDriver}
									snackbar={snackbar}
									betaUser={betaUser}
								/>
							</Grid>
						))}
					</div>
				</>
			)}
			<Mergecard list={filterList} handleQuery={onMergeUpdate} />
			{betaUser.create_order.includes(user) && (
				<Createorder
					storeid={storeid}
					createDialog={createDialog}
					setCreateDialog={setCreateDialog}
					allList={allList}
				/>
			)}
		</Grid>
	);
}
