export const couponWorker = new Worker(
	URL.createObjectURL(
		new Blob([
			`\
      /* load standalone script from CDN */
      importScripts("https://cdn.sheetjs.com/xlsx-0.20.2/package/dist/xlsx.full.min.js");
      /* this callback will run once the main context sends a message */
      self.addEventListener('message', (e) => {
        try {
          /* Read file data */
          const ab = new FileReaderSync().readAsArrayBuffer(e.data.file);
          /* Parse file */
          const wb = XLSX.read(ab);
          const ws = wb.Sheets[wb.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(ws);
          /* Reply with result */
          postMessage({ jsonData, ws });
        } catch(e) {
          /* Pass the error message back */
          postMessage({html: String(e.message || e).bold() });
        }
      }, false);
    `,
		])
	)
);
