import { useEffect, useState } from 'react';

export const Countdown = (time) => {
	const [seconds, setSeconds] = useState(60);

	useEffect(() => {
		const intervalId = setInterval(async () => {
			setSeconds((oldSeconds) => {
				if (oldSeconds === 0) {
					return 60;
				}
				return oldSeconds - 1;
			});
		}, time);
		return () => clearInterval(intervalId);
	}, [time]);

	return seconds;
};

export const Clock = () => {
	const [time, setTime] = useState();
	useEffect(() => {
		setInterval(() => {
			const currentTime = new Date().toLocaleTimeString();
			setTime(currentTime);
		}, 1000);
	}, []);
	return time;
};
