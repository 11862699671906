import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const style = {
	position: 'fixed',
	bottom: '60px',
	right: '10px',
	zIndex: '99',
};

export const handleScroll = (event, effect) => {
	const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
	if (anchor) {
		anchor.scrollIntoView({ behavior: effect, block: 'center' });
	}
};

export const BackToTop = (props) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			const element = document.querySelector('#back-to-top-anchor');
			const rect = element.getBoundingClientRect();
			rect.top === 0 ? setIsVisible(false) : setIsVisible(true);
		};

		document.documentElement.addEventListener('scroll', onScroll, true);
		return () => document.documentElement.removeEventListener('scroll', onScroll, true);
	}, []);

	return (
		<Zoom in={isVisible}>
			<div onClick={(e) => handleScroll(e, 'smooth')} role='presentation' style={style}>
				<Fab sx={{ backgroundColor: '#f4f4f4' }} size='small' aria-label='scroll back to top'>
					<KeyboardArrowUpIcon />
				</Fab>
			</div>
		</Zoom>
	);
};

BackToTop.propTypes = {
	children: PropTypes.element,
};
