import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function Dialog({ dialog, title, content, cancel, submit, handleClose, handleSubmit, showBtn, disable, fullWidth, showSub }) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(dialog);
	}, [dialog]);

	return (
		<MuiDialog open={open === undefined ? false : open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' fullWidth={fullWidth}>
			<DialogTitle id='alert-dialog-title' align='center'>
				{title}
			</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions sx={{ display: showBtn ? undefined : 'none' }}>
				<Button variant={'contained'} color='inherit' onClick={handleClose}>
					{cancel}
				</Button>
				<Button variant={'outlined'} color='error' onClick={handleSubmit} autoFocus disabled={disable} sx={{ display: showSub ? undefined : 'none' }}>
					{submit}
				</Button>
			</DialogActions>
		</MuiDialog>
	);
}
