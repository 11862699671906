import { alpha, styled } from '@mui/material/styles';
import { pink, green } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

export const PaySwitch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		'&::before, &::after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},
		'&::before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		'&::after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	'& .MuiSwitch-switchBase': {
		color: pink[600],
		'&:hover': {
			backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
		},
		'&.Mui-checked': {
			'&.Mui-disabled .MuiSwitch-thumb': {
				color: green[600],
			},
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: pink[600],
		},
	},
	'& .MuiSwitch-switchBase + .MuiSwitch-track': {
		backgroundColor: pink[600],
	},
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: green[600],
		'&:hover': {
			backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: green[600],
	},
}));

const formalNumber = (text) => {
	const numberMap = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
	const units = ['', '十', '百', '千', '萬', '億'];

	function numberToChinese(num) {
		const numStr = num.toString();
		const length = numStr.length;
		let result = '';

		for (let i = 0; i < length; i++) {
			const currentNum = parseInt(numStr[i]);
			const unitIndex = length - i - 1;

			if (currentNum !== 0) {
				if (unitIndex === 1 && currentNum === 1 && length === 2) {
					// 特殊情況：10-19之間的數字，省略「一」字，如「十一」、「十二」
					result += units[unitIndex];
				} else {
					result += numberMap[currentNum] + units[unitIndex];
				}
			} else {
				if (i !== length - 1 && numStr[i + 1] !== '0') {
					// 中間的零保留，如「一百零一」
					result += numberMap[0];
				}
			}
		}

		return result;
	}

	const regex = /\d+/g;
	return text.replace(regex, (match, offset, originalText) => {
		// 判斷數字後面的字符，決定是否轉換
		const nextChar = originalText[offset + match.length];
		if (!['巷', '弄'].includes(nextChar)) {
			return numberToChinese(match);
		}
		return match; // 否則不轉換數字
	});
};

export const structureAddress = (address) => {
	const taiwanAddressRegex =
		/^(?<zip_code>\d{0,6})?(?<state>[^縣市]+[縣市])(?<city>\D+?(市區|鎮區|鎮市|[鄉鎮市區]))(?<street>.+[路|街|大道|巷|弄|里|鄰|道|段])(?<number>\d+(-\d+)?號)(?<floor>.*)$/;
	// /^(?<zip_code>\d{0,6})?(?<state>[^\d\s]+[市縣])(?<city>[^\d\s]+[區鄉鎮市])(?<street>.+((路|大道(\d*段))?|街|大道|巷|弄|里|鄰|道))(?<number>\d+(-\d+)?號)(?<floor>.*)$/;

	const match = address.match(taiwanAddressRegex);
	if (match && match.groups) {
		return JSON.stringify({
			// street_address: [(match.groups.street_address || '').trim(), (match.groups.floor || '').trim()].filter(Boolean),
			street_address: [formalNumber(match.groups.street) + match.groups.number, match.groups.floor.trim()],
			state: match.groups.state || '',
			city: match.groups.city || '',
			zip_code: match.groups.zip_code || '',
		});
	} else {
		return null;
	}
};
