import moment from 'moment/moment';
import store from '../redux/store';
import { addWeather, addWeatherUpdate } from '../redux/authSlice';
import { getWeather } from './requestDelivery';
import { getStoreIds } from './extra';

export const fetchWeather = async (hq, zoneinfo, userName) => {
	try {
		const weatherToday = moment().format('MM/DD/YYYY');
		const updateHour = [6, 9, 12, 15, 18, 21];
		const currentHr = parseInt(moment().format('Hm'));
		let nextHr = 6;
		if (630 <= currentHr && currentHr < 2130) {
			for (let i = 0; i < updateHour.length; i++) {
				if (currentHr <= updateHour[i] * 100 + 30) {
					nextHr = updateHour[i];
					break;
				}
			}
		}
		const endTime = new Date();
		endTime.setHours(nextHr, 30, 0, 0);
		const storeid = hq ? await getStoreIds(hq, zoneinfo, userName, '/home', 'id') : [userName];
		let weather = [];
		await getWeather(weatherToday, storeid).then((res) => {
			res.map((s) =>
				weather.push({
					sid: s.sid,
					store_name: s.store_name,
					current: JSON.parse(s.current),
					forecast: s.forecast === null ? null : s.forecast.map((f) => JSON.parse(f)),
				})
			);
			store.dispatch(addWeather(weather));
			store.dispatch(addWeatherUpdate(moment(weather[0].current.time).format('HH:mm')));
		});
		return weather;
	} catch (error) {
		console.log(error);
	}
};

export const getIconColor = (icon) => {
	switch (icon) {
		case '01d':
		case '02d':
			return '#ffdf74';
		case '09d':
		case '10d':
			return '#a6a6a6';
		default:
			return '#e5e5e5';
	}
};

export const iconString = (val) => `https://openweathermap.org/img/wn/${val}@2x.png`;

export const refreshWeather = async (userName, weatherData, hq, zoneInfo, setWeatherList) => {
	const updateHour = [630, 930, 1230, 1530, 1830, 2130];
	const currentHr = parseInt(moment().format('Hm'));
	const expire = updateHour.includes(currentHr) ? true : false;
	if ((expire && userName !== '') || (!expire && weatherData.length < 1)) {
		const weather = await fetchWeather(hq, zoneInfo, userName);
		if (setWeatherList) {
			setWeatherList(weather.slice(0, 10));
		}
	}
};
