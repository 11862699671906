import Announcement from './announcement/AddNotice';
import Home from './home/home';
import Shipping from './shipping/Shipping';
import Dashboard from './shipping/Dashboard';
import Delivery from './shipping/Delivery';
import Done from './shipping/Done';
import Analysis from './analysis/analysis';
import Drive from './drive/Drive';
import Cashview from './daily/cashview';
import Cashform from './daily/cashform';
import Audit from './audit/Audit';
import ChangePw from './auth/ChangePw';
import CreateUser from './auth/CreateUser';
import Permission from './auth/Permission';
import ResetPw from './auth/ResetPw';
import Auditlist from './audit/Auditlist';
import Others from './others';
import Coupon from './others/coupon';
import Direct from './others/direct';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import LockResetIcon from '@mui/icons-material/LockReset';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import HomeIcon from '@mui/icons-material/Home';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const routes = [
	{
		type: 'collapse',
		name: '首頁',
		key: 'home',
		route: '/home',
		component: <Home />,
		icon: <HomeIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: false,
	},
	{
		type: 'collapse',
		name: '配送表',
		key: 'shipping',
		route: '/shipping',
		component: <Shipping />,
		icon: <DeliveryDiningIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: false,
	},
	{
		type: 'collapse',
		name: '待配送',
		key: 'order',
		route: '/order',
		component: <Dashboard />,
		icon: <ListAltIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '配送中',
		key: 'delivery',
		route: '/delivery',
		component: <Delivery />,
		icon: <DeliveryDiningIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '已完成',
		key: 'done',
		route: '/done',
		component: <Done />,
		icon: <CheckCircleOutlineIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '數據統計',
		key: 'analysis',
		route: '/analysis',
		component: <Analysis />,
		icon: <AssessmentIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: false,
	},
	{
		type: 'collapse',
		name: '雲端硬碟',
		key: 'drive',
		route: '/drive',
		component: <Drive />,
		icon: <FolderCopyIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: false,
	},
	{
		type: 'collapse',
		name: '每日收支',
		key: 'cash',
		route: '/cash',
		component: <Cashview />,
		icon: <RequestQuoteIcon />,
		collapse: [
			{
				name: '每日收支',
				key: 'cash',
				route: '/cash',
				component: <Cashview />,
				icon: <RequestQuoteIcon />,
				level: [],
				private: true,
				hide: false,
			},
			{
				name: '每日收支',
				key: 'edit',
				route: '/cash/edit',
				component: <Cashform />,
				icon: <RequestQuoteIcon />,
				level: [],
				private: true,
				hide: false,
			},
		],
		level: [],
		private: true,
		hide: false,
	},
	{
		type: 'collapse',
		name: '自檢表',
		key: 'audit',
		route: '/audit',
		icon: <FactCheckRoundedIcon fontSize='small' />,
		component: <Auditlist />,
		level: [],
		private: false,
		hide: false,
		collapse: [
			{
				name: '自檢表',
				key: 'audit',
				route: '/audit',
				component: <Auditlist />,
				icon: <FactCheckRoundedIcon fontSize='small' />,
				level: [],
				private: false,
				hide: false,
			},
			{
				name: '自檢表',
				key: 'audit',
				route: '/audit/edit',
				component: <Audit />,
				icon: <FactCheckRoundedIcon fontSize='small' />,
				level: [],
				private: false,
				hide: false,
			},
		],
	},
	{
		type: 'collapse',
		name: '公告',
		key: 'announcement',
		route: '/announcement',
		component: <Announcement />,
		icon: <PriceChangeIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '變更密碼',
		key: 'changePw',
		route: '/changePw',
		component: <ChangePw />,
		icon: <DeliveryDiningIcon />,
		noCollapse: true,
		level: [],
		private: false,
		hide: true,
	},
	{
		type: 'collapse',
		name: '建立帳號',
		key: 'createUser',
		route: '/createUser',
		component: <CreateUser />,
		icon: <DeliveryDiningIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '設定權限',
		key: 'permission',
		route: '/permission',
		component: <Permission />,
		icon: <DeliveryDiningIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '重設密碼',
		key: 'resetPw',
		route: '/resetPw',
		component: <ResetPw />,
		icon: <LockResetIcon />,
		noCollapse: true,
		level: [],
		private: true,
		hide: true,
	},
	{
		type: 'collapse',
		name: '其他工具',
		key: 'others',
		route: '/others',
		component: <Others />,
		icon: <ViewModuleIcon />,
		level: [],
		private: true,
		hide: false,
		collapse: [
			{
				name: '其他工具',
				key: 'others',
				route: '/others',
				component: <Others />,
				icon: <ViewModuleIcon fontSize='small' />,
				level: [],
				private: true,
				hide: false,
			},
			{
				name: '票券兌換統計',
				key: 'coupon',
				route: '/others/coupon',
				component: <Coupon />,
				icon: <ViewModuleIcon fontSize='small' />,
				level: [],
				private: true,
				hide: false,
			},
			{
				name: '建立 Direct 帳號',
				key: 'direct',
				route: '/others/direct',
				component: <Direct />,
				icon: <ViewModuleIcon fontSize='small' />,
				level: [],
				private: true,
				hide: false,
			},
		],
	},
];

export default routes;
