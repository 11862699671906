import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

// eslint-disable-next-line
const getStoreStirng = (id) => (id ? { store_id: { eq: id } } : '');
// eslint-disable-next-line
const getCompleteString = (done) => (done ? { complete: { eq: true } } : { complete: { eq: false } });
// eslint-disable-next-line
const getDeliverString = (deliver) => (deliver ? { deliveryInfo: { attributeExists: true } } : { deliveryInfo: { attributeExists: false } });
// eslint-disable-next-line
const getStoreNameStirng = (name) => (name ? { store_id: { eq: name } } : '');
// eslint-disable-next-line
const getWeatherStoreStirng = (id) => (id ? { sid: { eq: id } } : '');

const today = moment().format('L');

export const getFilterString = (id, done, deliver) => {
	// let storeString = getStoreStirng(id);
	let completeString = getCompleteString(done);
	let deliverString = getDeliverString(deliver);
	return { ...completeString, ...deliverString, mace_status: { eq: '已接單' }, status: { ne: 'hide' }, or: { status: { ne: 'merged' } }, _deleted: { ne: true } }; //created: {eq: "${from}"},
};

export const getQueryString = () => {
	return { mace_status: { eq: '已接單' }, status: { ne: 'hide' }, or: { status: { ne: 'merged' } }, _deleted: { ne: true } }; //created: {eq: "${from}"},
};

export const getFilterObj = (id, from, deliver) => {
	let storeObj = id ? { store_id: { eq: id } } : '';
	// let completeObj = (done)? {eq: true} : {eq: false};
	let deliverObj = deliver ? { gt: 0 } : { eq: 0 };
	return { ...storeObj, drivers: deliverObj, created: { eq: from } };
}; //d_id

export const getQueryObj = (id, from) => {
	let storeObj = id ? { store_id: { eq: id } } : '';
	return { ...storeObj, created: { eq: from } };
};

export const getToken = async () => {
	const variables = { limit: 2000 };
	const { data: res } = await API.graphql({ query: queries.listDirectTokens, variables });
	const { items: item } = res.listDirectTokens;
	item.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	return item[0].id;
};

export const checkAuthenticatedUser = async () => {
	const user = await Auth.currentAuthenticatedUser();
	return user.attributes;
};

export const getStore = async (SID) => {
	const variables = {
		limit: 2000,
		filter: {
			sid: { eq: SID },
		},
	};
	const { data: res } = await API.graphql({ query: queries.listMacuStores, variables });
	const { items: item } = res.listMacuStores;
	return item;
};

export const getDeliveryLists = async (id, queryDate, queryFilter) => {
	let allItems = [];
	for (let i in id) {
		let hasNextPage = true;
		let storeString = getStoreStirng(id.length === 1 && id[0] === 'HQ' ? '' : id[i]);
		let variables = {
			created: queryDate,
			...storeString,
			limit: 2000,
			sortDirection: 'DESC',
			filter: { ...queryFilter },
		};
		if (id[i] !== 'HQ' || (id.length === 1 && id[0] === 'HQ')) {
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: queries.deliveryByCreated, variables });
				const { items: item, nextToken } = res.deliveryByCreated;
				variables.nextToken = nextToken;
				allItems = allItems.concat(item);
				hasNextPage = nextToken ? true : false;
			}
		}
	}
	return [allItems, allItems.length];
};

export const getAnalysisLists = async (id, queryDate) => {
	let allItems = [];
	for (let i in id) {
		let hasNextPage = true;
		let storeString = getStoreStirng(id.length === 1 && id[0] === 'HQ' ? '' : id[i]);
		let variables = {
			created: queryDate,
			...storeString,
			limit: 2000,
			sortDirection: 'DESC',
			filter: { drivers: { gt: 0 }, status: { ne: 'pending' }, _deleted: { ne: true } },
		};
		if (id[i] !== 'HQ' || (id.length === 1 && id[0] === 'HQ')) {
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: queries.deliveryByCreated, variables });
				const { items: item, nextToken } = res.deliveryByCreated;
				variables.nextToken = nextToken;
				allItems = allItems.concat(item);
				hasNextPage = nextToken ? true : false;
			}
		}
	}
	return [allItems, allItems.length];
};

export const getAllCashLists = async (id, date) => {
	let allItems = [];
	for (let i in id) {
		let hasNextPage = true;
		let storeString = getStoreStirng(id.length === 1 && id[0] === 'HQ' ? '' : id[i]);
		let variables = {
			month: date.split('-')[1],
			year: { eq: date.split('-')[0] },
			limit: 2000,
			sortDirection: 'DESC',
			filter: { ...storeString },
		};
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.cashByMonth, variables });
			const { items: item, nextToken } = res.cashByMonth;
			variables.nextToken = nextToken;
			allItems = allItems.concat(item);
			hasNextPage = nextToken ? true : false;
		}
	}
	return [allItems, allItems.length];
};

export const getAllAuditLists = async (store, start, end) => {
	let allItems = [];
	for (let s in store) {
		let hasNextPage = true;
		let storeString = getStoreNameStirng(store.length === 1 && store[0] === '總部' ? '' : store[s]);
		let variables = {
			limit: 2000,
			filter: {
				...storeString,
				Date: { between: [start, end] },
			},
		};
		if (store[s] !== '總部' || (store.length === 1 && store[0] === '總部')) {
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: queries.listAuditForms, variables });
				const { items: item, nextToken } = res.listAuditForms;
				variables.nextToken = nextToken;
				allItems = allItems.concat(item);
				hasNextPage = nextToken ? true : false;
			}
		}
	}
	return [allItems, allItems.length];
};

export const getAuditContent = async () => {
	const variables = { limit: 2000 };
	const { data: res } = await API.graphql({ query: queries.listAuditContents, variables });
	const { items: item } = res.listAuditContents;
	return item;
};

export const Utf8ArrayToStr = (array) => {
	let out, i, len, c;
	let char2, char3;
	out = '';
	len = array.length;
	i = 0;
	while (i < len) {
		c = array[i++];
		switch (c >> 4) {
			case 0:
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
				// 0xxxxxxx
				out += String.fromCharCode(c);
				break;
			case 12:
			case 13:
				// 110x xxxx   10xx xxxx
				char2 = array[i++];
				out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
				break;
			case 14:
				// 1110 xxxx  10xx xxxx  10xx xxxx
				char2 = array[i++];
				char3 = array[i++];
				out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
				break;
			default:
				break;
		}
	}
	return JSON.parse(out);
};

export const allStore = async () => {
	let store = [];
	let sid = [];
	let sname = [];
	const variables = {
		limit: 500,
	};
	let hasNextPage = true;
	while (hasNextPage) {
		const { data: res } = await API.graphql({ query: queries.listMacuStores, variables });
		const { items: item, nextToken } = res.listMacuStores;
		variables.nextToken = nextToken;
		store = store.concat(item);
		hasNextPage = nextToken ? true : false;
	}
	let item = {};
	let list = [];
	store.map((s) => {
		sid.push(s.sid);
		sname.push(s.name);
		item['store_id'] = s.sid;
		item['store_name'] = s.name;
		list.push({ ...item });
		return s;
	});
	return [sid, sname, list, store];
};

export const getAnnouncement = async (hq, level, username) => {
	let list = [];
	const variables = {
		limit: 500,
	};
	let hasNextPage = true;
	while (hasNextPage) {
		const { data: res } = await API.graphql({
			query: queries.listAnnouncements,
			variables,
		});
		const { items: item, nextToken } = res.listAnnouncements;
		variables.nextToken = nextToken;
		list = list.concat(item);
		hasNextPage = nextToken ? true : false;
	}
	list = list.filter((f) => level.includes(f.Section) || f.Section === '全部');
	if (hq) {
		list = list.filter((f) => f.Creator === username || f.StartDate <= today);
	} else {
		list = list.filter((f) => f.StartDate <= today);
	}
	return list;
};

export const createAnnouncement = async (create) => {
	try {
		await API.graphql({
			query: mutations.createAnnouncement,
			variables: { input: create },
		});
	} catch (error) {
		console.log('error', error);
	}
};

export const updateAnnouncement = async (updates) => {
	try {
		await API.graphql({
			query: mutations.updateAnnouncement,
			variables: { input: updates },
		});
	} catch (error) {
		console.log('error', error);
	}
};

export const getCoordinate = async (sid) => {
	try {
		const res = await API.graphql({
			query: queries.storeBySid,
			variables: {
				sid: sid,
			},
		});
		if (res.data.storeBySid.items[0]) {
			let coordinate = res.data.storeBySid.items[0].location;
			return coordinate;
		}
	} catch (error) {
		console.log('error', error);
	}
};

export const getWeather = async (date, store) => {
	let allItems = [];
	for (let s in store) {
		let hasNextPage = true;
		let storeString = getWeatherStoreStirng(store.length === 1 && store[0] === 'HQ' ? '' : store[s]);
		const variables = {
			date: date,
			limit: 1000,
			filter: { ...storeString },
		};
		while (hasNextPage) {
			const { data: res } = await API.graphql({
				query: queries.weatherByDate,
				variables,
			});
			const { items: item, nextToken } = res.weatherByDate;
			variables.nextToken = nextToken;
			allItems = allItems.concat(item);
			hasNextPage = nextToken ? true : false;
		}
	}
	return allItems;
};
