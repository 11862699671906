import React from 'react';
import { userComplete } from '../images/images';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CompleteStep({ finishTitle }) {
	return (
		<Box width='100%' alignSelf='center' pt={3}>
			<Typography pb={1}>{finishTitle}</Typography>
			<img src={userComplete} alt='user created' style={{ width: 300, height: 250 }} />
		</Box>
	);
}
