import { Geo } from 'aws-amplify';

export const geo = async (val, coordinate) => {
	const searchOptionsWithSearchAreaConstraints = {
		countries: ['TWN'],
		maxResults: 5,
		biasPosition: [coordinate[1], coordinate[0]],
	};

	const getPlace = async (val) => {
		let index = val.indexOf('號');
		let extraAddress = '';
		if (index > 0) {
			extraAddress = val.substring(index + 1);
			// let regex = new RegExp("(之\\d+樓之\\d+室$)|(之\\d+樓$)|^\\d+樓之\\d+室$|^\\d+樓$");
			let regex = new RegExp('([-之\\d]+樓[-之\\d]+室$)|([-之\\d]+樓$)|^[-之\\d]+樓[-之\\d]+室$|^[-之\\d]+樓$');
			if (!regex.test(extraAddress)) {
				extraAddress = '';
			}
		}
		let result = [];
		const res = await Geo.searchByText(val, searchOptionsWithSearchAreaConstraints);
		res.map((p) => {
			let number = '';
			let addressNo = p.addressNumber;
			if (p.addressNumber === undefined) {
				addressNo = '';
			}
			if (p.addressNumber && !p.addressNumber.includes('號')) {
				number = '號';
			}
			let valid = p.postalCode + p.municipality + p.neighborhood + p.street + addressNo + number + extraAddress;
			result.push(valid);
			return '';
		});
		return result;
	};
	const res = await getPlace(val);
	return res;
};
